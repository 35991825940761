import { SettingType, SettingValue, UnitConversionAirflow, UnitConversionTemperature, UnitConversionType } from '@api';
import { ConfigService } from '@core';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export type UnitConversionAirflowM3H = 'airflow_m3_h';
export const UnitConversionAirflowM3H: UnitConversionAirflowM3H = 'airflow_m3_h';

export type UnitConversionTemperatureCelsius = 'temperature_celsius';
export const UnitConversionTemperatureCelsius: UnitConversionTemperatureCelsius = 'temperature_celsius';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ConversionService {
  unitConversionSettings$ = new BehaviorSubject<{ [key: string]: SettingValue }>({});

  units: { [key: string]: string } = {};
  decimals: { [key: string]: number } = {};

  constructor(
    public configService: ConfigService,
    public translateService: TranslateService,
  ) {
    this.configService.settingsConfig$.pipe(untilDestroyed(this)).subscribe(async (settings) => {
      const unitConversionSettings: { [key: string]: SettingValue } = {};

      this.units[UnitConversionAirflow.AirflowLS] = await this.translateService
        .get('ui.unit_conversion.airflow_l_s')
        .toPromise();
      this.decimals[UnitConversionAirflow.AirflowLS] = 1;

      this.units[UnitConversionAirflowM3H] = await this.translateService
        .get('ui.unit_conversion.airflow_m3_h')
        .toPromise();
      this.decimals[UnitConversionAirflowM3H] = 0;

      unitConversionSettings[UnitConversionType.Airflow] = settings[SettingType.UnitConversionAirflow];
      switch (unitConversionSettings[UnitConversionType.Airflow]) {
        case UnitConversionAirflow.AirflowLS:
          this.units[UnitConversionType.Airflow] = this.units[UnitConversionAirflow.AirflowLS];
          this.decimals[UnitConversionType.Airflow] = this.decimals[UnitConversionAirflow.AirflowLS];
          break;
        default:
          this.units[UnitConversionType.Airflow] = this.units[UnitConversionAirflowM3H];
          this.decimals[UnitConversionType.Airflow] = this.decimals[UnitConversionAirflowM3H];
          break;
      }

      this.units[UnitConversionTemperature.Kelvin] = await this.translateService
        .get('ui.unit_conversion.temperature_kelvin')
        .toPromise();
      this.decimals[UnitConversionTemperature.Kelvin] = 0;

      this.units[UnitConversionTemperature.Fahrenheit] = await this.translateService
        .get('ui.unit_conversion.temperature_fahrenheit')
        .toPromise();
      this.decimals[UnitConversionTemperature.Fahrenheit] = 0;

      this.units[UnitConversionTemperatureCelsius] = await this.translateService
        .get('ui.unit_conversion.temperature_celsius')
        .toPromise();
      this.decimals[UnitConversionTemperatureCelsius] = 0;

      unitConversionSettings[UnitConversionType.Temperature] = settings[SettingType.UnitConversionTemperature];
      switch (unitConversionSettings[UnitConversionType.Temperature]) {
        case UnitConversionTemperature.Kelvin:
          this.units[UnitConversionType.Temperature] = this.units[UnitConversionTemperature.Kelvin];
          this.decimals[UnitConversionType.Temperature] = this.decimals[UnitConversionTemperature.Kelvin];
          break;
        case UnitConversionTemperature.Fahrenheit:
          this.units[UnitConversionType.Temperature] = this.units[UnitConversionTemperature.Fahrenheit];
          this.decimals[UnitConversionType.Temperature] = this.decimals[UnitConversionTemperature.Fahrenheit];
          break;
        default:
          this.units[UnitConversionType.Temperature] = this.units[UnitConversionTemperatureCelsius];
          this.decimals[UnitConversionType.Temperature] = this.decimals[UnitConversionTemperatureCelsius];
          break;
      }

      this.unitConversionSettings$.next(unitConversionSettings);
    });
  }

  getUnitConversion(
    conversion?:
      | UnitConversionAirflow
      | UnitConversionAirflowM3H
      | UnitConversionTemperature
      | UnitConversionTemperatureCelsius
      | UnitConversionType,
  ) {
    if (
      conversion &&
      [
        UnitConversionAirflow.AirflowLS,
        UnitConversionAirflowM3H,
        UnitConversionTemperature.Kelvin,
        UnitConversionTemperature.Fahrenheit,
        UnitConversionTemperatureCelsius,
      ].indexOf(
        conversion as
          | UnitConversionAirflow
          | UnitConversionAirflowM3H
          | UnitConversionTemperature
          | UnitConversionTemperatureCelsius,
      ) === -1
    ) {
      const unitConversionSetting = this.unitConversionSettings$.getValue()[conversion];

      if (unitConversionSetting) {
        return unitConversionSetting as UnitConversionAirflow | UnitConversionTemperature;
      }
    }

    return conversion;
  }

  getUnit(
    type:
      | UnitConversionAirflow
      | UnitConversionAirflowM3H
      | UnitConversionTemperature
      | UnitConversionTemperatureCelsius
      | UnitConversionType,
  ) {
    if ([null, undefined].indexOf(this.units[type]) === -1) {
      return this.units[type];
    }

    return type;
  }

  getDecimals(
    type:
      | UnitConversionAirflow
      | UnitConversionAirflowM3H
      | UnitConversionTemperature
      | UnitConversionTemperatureCelsius
      | UnitConversionType,
  ) {
    if ([null, undefined].indexOf(this.decimals[type]) === -1) {
      return this.decimals[type];
    }

    return 2;
  }

  convert(
    value: number,
    conversion?:
      | UnitConversionAirflow
      | UnitConversionAirflowM3H
      | UnitConversionTemperature
      | UnitConversionTemperatureCelsius
      | UnitConversionType,
  ) {
    if (!conversion || [null, undefined].indexOf(value) !== -1) {
      return value;
    }

    let found = false;
    let decimals = 0;

    switch (this.getUnitConversion(conversion)) {
      case UnitConversionAirflow.AirflowLS:
        value /= 3.6;
        decimals = 1;
        found = true;
        break;
      case UnitConversionTemperature.Kelvin:
        value += 273.15;
        found = true;
        break;
      case UnitConversionTemperature.Fahrenheit:
        value = (value * 9) / 5 + 32;
        found = true;
        break;
    }

    if (!found) {
      return value;
    }

    if (!value) {
      value = 0;
    }

    const factor = Math.pow(10, decimals);

    return Math.round(value * factor) / factor;
  }

  revert(
    value: number,
    conversion?:
      | UnitConversionAirflow
      | UnitConversionAirflowM3H
      | UnitConversionTemperature
      | UnitConversionTemperatureCelsius
      | UnitConversionType,
  ) {
    if (!conversion || [null, undefined].indexOf(value) !== -1) {
      return value;
    }

    let found = false;
    let decimals = 0;

    switch (this.getUnitConversion(conversion)) {
      case UnitConversionAirflow.AirflowLS:
        value *= 3.6;
        found = true;
        break;
      case UnitConversionTemperature.Kelvin:
        value -= 273.15;
        found = true;
        break;
      case UnitConversionTemperature.Fahrenheit:
        value = (value - 32) / (9 / 5);
        found = true;
        break;
    }

    if (!found) {
      return value;
    }

    if (!value) {
      value = 0;
    }

    const factor = Math.pow(10, decimals);

    return Math.round(value * factor) / factor;
  }
}
