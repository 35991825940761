/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

if (environment.sentryUrl) {
  Sentry.init({
    dsn: environment.sentryUrl,

    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', environment.serverUrl],
        routingInstrumentation: Sentry.instrumentAngularRouting,
      }),
      new Sentry.Replay(),
    ],

    // Performance Monitoring
    tracesSampleRate: environment.sentryTraceRate, // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. We recommend adjusting this value in production.

    // Session Replay
    replaysSessionSampleRate: environment.sentrySessionRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: environment.sentrySessionRate ? 1.0 : environment.sentrySessionRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    environment: environment.sentryEnvironment,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
