import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UnloaderService {
  protected _callbacks: Function[];

  constructor(public translateService: TranslateService) {
    this._callbacks = [];

    window.addEventListener('beforeunload', (event) => {
      if (!this.check()) {
        event.preventDefault();
        return (event.returnValue = this.translateService.instant(
          'ui.app.@core.unloader-service-ts.changes-you-made-may-not-be-saved-are-you-sure-you-want-to-leave',
        ));
      }
    });
  }

  check() {
    if (this._callbacks && this._callbacks.length) {
      for (let callback of this._callbacks) {
        if (callback()) {
          return false;
        }
      }
    }

    return true;
  }

  confirm() {
    const response = confirm(
      this.translateService.instant(
        'ui.app.@core.unloader-service-ts.changes-you-made-may-not-be-saved-are-you-sure-you-want-to-leave',
      ),
    );
    if (response) {
      this._callbacks = [];
    }
    return response;
  }

  register(callback: Function) {
    if (!callback) {
      return;
    }
    if (this._callbacks.indexOf(callback) === -1) {
      this._callbacks.push(callback);
    }
  }

  unregister(callback: Function) {
    if (!callback) {
      return;
    }

    const index = this._callbacks.indexOf(callback);
    if (index !== -1) {
      this._callbacks.splice(index, 1);
    }
  }
}
