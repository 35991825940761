import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnDestroy,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Menubar, MenubarService, MenubarSub } from 'primeng/menubar';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PrimeNGConfig } from 'primeng/api';
import { DOCUMENT } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'c-menubarSub',
  templateUrl: './c-menubar-sub.component.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'p-element',
  },
})
export class CMenubarSubComponent extends MenubarSub {
  constructor(el: ElementRef, renderer: Renderer2, cd: ChangeDetectorRef, menubarService: MenubarService) {
    super(el, renderer, cd, menubarService);
  }
}

@UntilDestroy()
@Component({
  selector: 'c-menubar',
  templateUrl: './c-menubar.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush, // needs to track changes on this.compact
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./c-menubar.component.scss'],
  host: {
    class: 'p-element',
  },
  providers: [MenubarService],
})
export class CMenubarComponent extends Menubar implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('menubar') menubar: ElementRef;
  @ViewChild('menubarStart') menubarStart: ElementRef;
  @ViewChild('menubarEnd') menubarEnd: ElementRef;
  @ViewChild('content') content: ElementRef;

  eventSub: Subscription;
  compact = false;
  contentWidth: number;

  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) platformId: any,
    el: ElementRef,
    renderer: Renderer2,
    cd: ChangeDetectorRef,
    config: PrimeNGConfig,
    menubarService: MenubarService,
  ) {
    super(document, platformId, el, renderer, cd, config, menubarService);
  }

  onResize() {
    if (this.menubar && this.content && this.menubarStart && this.menubarEnd) {
      if (this.content.nativeElement.clientWidth) {
        this.contentWidth = this.content.nativeElement.clientWidth;
      }

      const menubarWidth = this.menubar.nativeElement.clientWidth;
      const menubarStartWidth = this.menubarStart.nativeElement.clientWidth;
      const menubarEndWidth = this.menubarEnd.nativeElement.clientWidth;
      const availableSpace = menubarWidth - menubarStartWidth - menubarEndWidth;

      const compact = this.contentWidth + 15 > availableSpace; // added a threshold to fix a bug where menubarSub was constantly shown and hidden

      if (compact !== this.compact) {
        this.compact = compact;
      }
    }
  }

  ngAfterViewInit() {
    // ERROR Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    // Previous value: 'false'. Current value: 'true'.
    // On this.compact
    setTimeout(() => this.onResize(), 100);
    setInterval(() => this.onResize(), 1000); // just in case we need to wait for some translations to load or if the menu items change
  }

  ngOnChanges() {
    this.onResize();

    if (!this.eventSub) {
      this.eventSub = fromEvent(window, 'resize')
        .pipe(
          debounceTime(100),
          tap(() => this.onResize()),
        )
        .pipe(untilDestroyed(this))
        .subscribe();
    }
  }

  ngOnDestroy() {
    if (this.eventSub) {
      this.eventSub.unsubscribe();
      this.eventSub = null;
    }

    this.unbindOutsideClickListener();
  }
}
