import { Injectable } from '@angular/core';
import { UnloaderService } from '../unloader.service';

@Injectable({
  providedIn: 'root',
})
export class ChildActivationGuard {
  constructor(public unloaderService: UnloaderService) {}

  canActivateChild(): boolean {
    return this.unloaderService.check() || this.unloaderService.confirm();
  }
}
