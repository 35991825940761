<p-dropdown
  [(ngModel)]="locale"
  [filterFields]="['code', 'name']"
  [filter]="true"
  [optionLabel]="descriptionService.name"
  [options]="locales"
  [resetFilterOnHide]="true"
  appendTo="body"
  inputId="locale"
  name="locale"
  optionValue="code"
  placeholder="&nbsp;"
></p-dropdown>
