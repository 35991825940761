import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { LoggerService } from './logger.service';
import { registerLocaleData } from '@angular/common';
import { PrimeNGConfig } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const log = new LoggerService('I18nService');
const localeKey = 'locale';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class I18nService {
  defaultLocale!: string;
  supportedLocales: string[] = [];
  currentLocale$ = new Subject<string>();

  private langChangeSubscription!: Subscription;

  constructor(
    public translateService: TranslateService,
    public primeNgConfig: PrimeNGConfig,
  ) {}

  /**
   * Gets the current locale.
   * @return The current locale code.
   */
  get locale(): string {
    return this.translateService.currentLang;
  }

  /**
   * Sets the current locale.
   * Note: The current locale is saved to the local storage.
   * If no parameter is specified, the locale is loaded from local storage (if present).
   * @param locale The IETF locale code to set.
   */
  set locale(locale: string) {
    locale = this.processLocale(
      locale || localStorage.getItem(localeKey) || this.translateService.getBrowserCultureLang(),
    );

    let isSupportedLocale = this.supportedLocales.includes(locale);

    // If no exact match is found, search without the region
    if (locale && !isSupportedLocale) {
      locale = locale.split('-')[0];
      locale = this.supportedLocales.find((supportedLocale) => supportedLocale.startsWith(locale)) || '';
      isSupportedLocale = Boolean(locale);
    }

    // Fallback if locale is not supported
    if (!isSupportedLocale) {
      locale = this.defaultLocale;
    }

    if (this.translateService.currentLang !== locale) {
      const afterImportLocale = () => {
        log.debug(`Locale set to ${locale}`);
        const handler = () => {
          this.currentLocale$.next(locale);
          this.setPrimeNgTranslations().then();
        };
        this.translateService.use(locale).pipe(untilDestroyed(this)).subscribe(handler, handler);
      };

      this.importLocale(locale).then(afterImportLocale, afterImportLocale);
    }
  }

  importLocale(locale: string, reduce = false): Promise<any> {
    let localeToImport = locale;

    // If the actual locale cannot be found, fallback to it's language part
    if (reduce) {
      localeToImport = locale.split('-').shift();
    }

    // https://indepth.dev/posts/1038/dynamic-import-of-locales-in-angular
    // Changed in angular 13 - adjusted the path - https://github.com/angular/angular-cli/issues/22154
    // Maintain the regex list the same as the one on the API but without the main countries.
    return import(
      /* webpackInclude: /(cs|da|de|de-AT|de-CH|de-LU|en|es|et|fi|fr|fr-BE|fr-CH|fr-LU|hu|it|it-CH|lt|lv|nl|nl-BE|no|pl|ru|sk|sv|tr)\.js$/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "i18n-global" */
      `/node_modules/@angular/common/locales/global/${localeToImport}.js`
    ).then(
      (module) => {
        let result = module.default;
        if (!result || !Object.keys(result).length) {
          result = null;
        }

        // Empty module.default. Also global locales have no TS definitions.
        // https://stackoverflow.com/questions/56691391/dynamic-loading-of-external-modules-in-webpack-fails
        if (
          !result &&
          window['ng'] &&
          window['ng'].common &&
          window['ng'].common.locales &&
          window['ng'].common.locales[localeToImport]
        ) {
          result = window['ng'].common.locales[localeToImport];
        }

        if (result) {
          if (localeToImport !== locale) {
            result[0] = locale;
          }
          registerLocaleData(result);
        }
      },
      (err) => {
        log.error(err);
        if (!reduce) {
          return this.importLocale(locale, true);
        }
      },
    );
  }

  processLocale(locale: string) {
    if (locale) {
      // Convert from API standard to client standard
      locale = locale.replace('_', '-');
    }

    return locale;
  }

  /**
   * Initializes i18n for the application.
   * Loads locale from local storage if present, or sets default locale.
   * @param defaultLocale The default locale to use.
   * @param supportedLocales
   */
  init(defaultLocale: string, supportedLocales: string[] = []) {
    this.defaultLocale = this.processLocale(defaultLocale) || 'en';
    this.locale = '';

    supportedLocales =
      supportedLocales && supportedLocales.length
        ? supportedLocales.map((locale) => this.processLocale(locale))
        : [this.defaultLocale];

    this.supportedLocales = supportedLocales;

    // Warning: this subscription will always be alive for the app's lifetime
    this.langChangeSubscription = this.translateService.onLangChange
      .pipe(untilDestroyed(this))
      .subscribe((event: LangChangeEvent) => {
        localStorage.setItem(localeKey, event.lang);
        this.currentLocale$.next(event.lang);
        this.setPrimeNgTranslations().then();
      });
  }

  async setPrimeNgTranslations() {
    this.primeNgConfig.setTranslation({
      startsWith: await this.translateService.get('ui.primeng.starts-with').toPromise(),
      contains: await this.translateService.get('ui.primeng.contains').toPromise(),
      notContains: await this.translateService.get('ui.primeng.not-contains').toPromise(),
      endsWith: await this.translateService.get('ui.primeng.ends-with').toPromise(),
      equals: await this.translateService.get('ui.primeng.equals').toPromise(),
      notEquals: await this.translateService.get('ui.primeng.not-equals').toPromise(),
      noFilter: await this.translateService.get('ui.primeng.no-filter').toPromise(),
      lt: await this.translateService.get('ui.primeng.less-than').toPromise(),
      lte: await this.translateService.get('ui.primeng.less-than-or-equal-to').toPromise(),
      gt: await this.translateService.get('ui.primeng.greater-than').toPromise(),
      gte: await this.translateService.get('ui.primeng.greater-than-or-equal-to').toPromise(),
      is: await this.translateService.get('ui.primeng.is').toPromise(),
      isNot: await this.translateService.get('ui.primeng.is-not').toPromise(),
      before: await this.translateService.get('ui.primeng.before').toPromise(),
      after: await this.translateService.get('ui.primeng.after').toPromise(),
      dateIs: await this.translateService.get('ui.primeng.date-is').toPromise(),
      dateIsNot: await this.translateService.get('ui.primeng.date-is-not').toPromise(),
      dateBefore: await this.translateService.get('ui.primeng.date-is-before').toPromise(),
      dateAfter: await this.translateService.get('ui.primeng.date-is-after').toPromise(),
      clear: await this.translateService.get('ui.primeng.clear').toPromise(),
      apply: await this.translateService.get('ui.primeng.apply').toPromise(),
      matchAll: await this.translateService.get('ui.primeng.match-all').toPromise(),
      matchAny: await this.translateService.get('ui.primeng.match-any').toPromise(),
      addRule: await this.translateService.get('ui.primeng.add-rule').toPromise(),
      removeRule: await this.translateService.get('ui.primeng.remove-rule').toPromise(),
      accept: await this.translateService.get('ui.primeng.yes').toPromise(),
      reject: await this.translateService.get('ui.primeng.no').toPromise(),
      choose: await this.translateService.get('ui.primeng.choose').toPromise(),
      upload: await this.translateService.get('ui.primeng.upload').toPromise(),
      cancel: await this.translateService.get('ui.primeng.cancel').toPromise(),
      dayNames: [
        await this.translateService.get('ui.primeng.day-names.sunday').toPromise(),
        await this.translateService.get('ui.primeng.day-names.monday').toPromise(),
        await this.translateService.get('ui.primeng.day-names.tuesday').toPromise(),
        await this.translateService.get('ui.primeng.day-names.wednesday').toPromise(),
        await this.translateService.get('ui.primeng.day-names.thursday').toPromise(),
        await this.translateService.get('ui.primeng.day-names.friday').toPromise(),
        await this.translateService.get('ui.primeng.day-names.saturday').toPromise(),
      ],
      dayNamesShort: [
        await this.translateService.get('ui.primeng.day-names-short.sun').toPromise(),
        await this.translateService.get('ui.primeng.day-names-short.mon').toPromise(),
        await this.translateService.get('ui.primeng.day-names-short.tue').toPromise(),
        await this.translateService.get('ui.primeng.day-names-short.wed').toPromise(),
        await this.translateService.get('ui.primeng.day-names-short.thu').toPromise(),
        await this.translateService.get('ui.primeng.day-names-short.fri').toPromise(),
        await this.translateService.get('ui.primeng.day-names-short.sat').toPromise(),
      ],
      dayNamesMin: [
        await this.translateService.get('ui.primeng.day-names-min.su').toPromise(),
        await this.translateService.get('ui.primeng.day-names-min.mo').toPromise(),
        await this.translateService.get('ui.primeng.day-names-min.tu').toPromise(),
        await this.translateService.get('ui.primeng.day-names-min.we').toPromise(),
        await this.translateService.get('ui.primeng.day-names-min.th').toPromise(),
        await this.translateService.get('ui.primeng.day-names-min.fr').toPromise(),
        await this.translateService.get('ui.primeng.day-names-min.sa').toPromise(),
      ],
      monthNames: [
        await this.translateService.get('ui.primeng.month-names.january').toPromise(),
        await this.translateService.get('ui.primeng.month-names.february').toPromise(),
        await this.translateService.get('ui.primeng.month-names.march').toPromise(),
        await this.translateService.get('ui.primeng.month-names.april').toPromise(),
        await this.translateService.get('ui.primeng.month-names.may').toPromise(),
        await this.translateService.get('ui.primeng.month-names.june').toPromise(),
        await this.translateService.get('ui.primeng.month-names.july').toPromise(),
        await this.translateService.get('ui.primeng.month-names.august').toPromise(),
        await this.translateService.get('ui.primeng.month-names.september').toPromise(),
        await this.translateService.get('ui.primeng.month-names.october').toPromise(),
        await this.translateService.get('ui.primeng.month-names.november').toPromise(),
        await this.translateService.get('ui.primeng.month-names.december').toPromise(),
      ],
      monthNamesShort: [
        await this.translateService.get('ui.primeng.month-names-short.jan').toPromise(),
        await this.translateService.get('ui.primeng.month-names-short.feb').toPromise(),
        await this.translateService.get('ui.primeng.month-names-short.mar').toPromise(),
        await this.translateService.get('ui.primeng.month-names-short.apr').toPromise(),
        await this.translateService.get('ui.primeng.month-names-short.may').toPromise(),
        await this.translateService.get('ui.primeng.month-names-short.jun').toPromise(),
        await this.translateService.get('ui.primeng.month-names-short.jul').toPromise(),
        await this.translateService.get('ui.primeng.month-names-short.aug').toPromise(),
        await this.translateService.get('ui.primeng.month-names-short.sep').toPromise(),
        await this.translateService.get('ui.primeng.month-names-short.oct').toPromise(),
        await this.translateService.get('ui.primeng.month-names-short.nov').toPromise(),
        await this.translateService.get('ui.primeng.month-names-short.dec').toPromise(),
      ],
      today: await this.translateService.get('ui.primeng.today').toPromise(),
      weekHeader: await this.translateService.get('ui.primeng.wk').toPromise(),
      weak: await this.translateService.get('ui.primeng.weak').toPromise(),
      medium: await this.translateService.get('ui.primeng.medium').toPromise(),
      strong: await this.translateService.get('ui.primeng.strong').toPromise(),
      passwordPrompt: await this.translateService.get('ui.primeng.enter-a-password').toPromise(),
      emptyMessage: await this.translateService.get('ui.primeng.no-results-found').toPromise(),
      emptyFilterMessage: await this.translateService.get('ui.primeng.no-results-found').toPromise(),
    });
  }

  /**
   * Cleans up locale change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
