import { AfterViewInit, Directive, Input } from '@angular/core';
import { ResizableColumn } from 'primeng/table';

@Directive({
  selector: '[cResizableColumn]',
})
export class CResizableColumnDirective extends ResizableColumn implements AfterViewInit {
  @Input('cResizableColumn') columnKey: string;

  stateInit: { [key: string]: { element: HTMLElement; width: string; minWidth: string } } = {};

  getStateKey() {
    let stateKey = this.dt.stateKey;
    if (!stateKey && this.dt.el && this.dt.el.nativeElement) {
      stateKey = this.dt.el.nativeElement.getAttribute('stateKeyColumnWidths');
    }
    if (stateKey) {
      stateKey += '.column-widths';
    }
    return stateKey;
  }

  getTableElement() {
    let tableElement: HTMLElement;

    if (this.el && this.el.nativeElement) {
      tableElement = this.el.nativeElement.parentElement;

      while (tableElement && tableElement.tagName.toLowerCase() !== 'table') {
        tableElement = tableElement.parentElement;
      }
    }

    return tableElement;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (
      this.el &&
      this.el.nativeElement &&
      this.el.nativeElement.parentElement &&
      this.el.nativeElement.parentElement.children
    ) {
      // Collect all initial widths before applying resizing

      const tableElement = this.getTableElement();
      if (tableElement) {
        this.stateInit[''] = {
          element: tableElement, // collect the element as well in case the innerText changes
          width: tableElement.style.width, // keep the explicit width instead of the rendered one
          minWidth: tableElement.style.minWidth, // keep the explicit width instead of the rendered one
        };
      }

      let child: HTMLElement;
      let index = 0;
      for (child of this.el.nativeElement.parentElement.children) {
        const columnKey = (
          child.getAttribute('cResizableColumn') ||
          child.innerText ||
          // Store even elements without an actual innerText to be checked by element reference
          String(Date.now()) + String(Math.round(Math.random() * 1000)) + String(index)
        ).trim();
        this.stateInit[columnKey] = {
          element: child, // collect the element as well in case the innerText changes
          width: child.style.width, // keep the explicit width instead of the rendered one
          minWidth: child.style.minWidth, // keep the explicit width instead of the rendered one
        };
        index++;
      }

      const stateKey = this.getStateKey();
      if (this.isEnabled() && stateKey) {
        const storage = this.dt.getStorage();
        const state = JSON.parse(storage.getItem(stateKey));

        if (state && state['']) {
          tableElement.style.width = state[''] + 'px';

          this.showResetIcon();
        }

        const columnKey = this.columnKey || (this.el.nativeElement.innerText || '').trim();
        if (columnKey) {
          if (state && state[columnKey]) {
            this.el.nativeElement.style.width = state[columnKey] + 'px';

            this.showResetIcon();
          }
        }
      }
    }
  }

  showResetIcon() {
    if (
      this.el &&
      this.el.nativeElement &&
      this.el.nativeElement.parentElement &&
      this.el.nativeElement.parentElement.children
    ) {
      const tableElement = this.getTableElement();
      if (tableElement) {
        let resetIconElement: HTMLElement = tableElement.querySelector('.c-resizable-column-reset-icon');
        if (!resetIconElement) {
          resetIconElement = document.createElement('i');
          resetIconElement.className = 'pi pi-map cursor-pointer c-resizable-column-reset-icon';
          tableElement.insertAdjacentElement('afterbegin', resetIconElement); // inside the tableElement otherwise the column resizing malfunctions
          resetIconElement.style.position = 'absolute'; // works with StickyTableHeaderDirective
          resetIconElement.style.zIndex = '1'; // loader overlay has 2
          resetIconElement.style.backgroundColor = 'white'; // Set to white to avoid displaying text behind when sticky
          resetIconElement.style.top = '0'; // stays visible while scrolling pushes it to the top; is controlled by StickyTableHeaderDirective
          // leave the resetIconElement.style.left alone otherwise it is partially hidden

          resetIconElement.addEventListener('click', () => {
            if (this.dt) {
              this.dt.destroyStyleElement();
              this.dt.createStyleElement();
            }

            if (this.stateInit['']) {
              tableElement.style.width = this.stateInit[''].width;
              tableElement.style.minWidth = this.stateInit[''].minWidth;
            }

            let child: HTMLElement;
            for (child of this.el.nativeElement.parentElement.children) {
              let width = null;
              let minWidth = null;
              // first track by the actual element
              const state = Object.values(this.stateInit).find((state) => state.element === child);
              if (state) {
                width = state.width;
                minWidth = state.minWidth;
              } else {
                // then track by the columnKey
                const columnKey = (child.getAttribute('cResizableColumn') || child.innerText || '').trim();
                if (columnKey && this.stateInit[columnKey]) {
                  width = this.stateInit[columnKey].width;
                  minWidth = this.stateInit[columnKey].minWidth;
                }
              }
              if (width !== null) {
                child.style.width = width;
              }
              if (minWidth !== null) {
                child.style.minWidth = minWidth;
              }
            }

            const storage = this.dt.getStorage();
            const stateKey = this.getStateKey();
            storage.removeItem(stateKey);

            resetIconElement.remove();
          });
        }
      }
    }
  }

  onDocumentMouseUp(event: MouseEvent) {
    const stateKeyDt = this.dt.stateKey;
    this.dt.stateKey = null; // prevent storing the column widths
    super.onDocumentMouseUp(event);
    this.dt.stateKey = stateKeyDt;

    const stateKey = this.getStateKey();
    if (
      this.isEnabled() &&
      stateKey &&
      this.el &&
      this.el.nativeElement &&
      this.el.nativeElement.parentElement &&
      this.el.nativeElement.parentElement.children
    ) {
      const storage = this.dt.getStorage();
      const state = JSON.parse(storage.getItem(stateKey)) || {};

      const tableElement = this.getTableElement();
      if (tableElement) {
        state[''] = tableElement.offsetWidth;
      }

      let child: HTMLElement;
      // Store all the sizes as siblings might have been affected
      for (child of this.el.nativeElement.parentElement.children) {
        if (child.hasAttribute('cResizableColumn')) {
          const columnKey = (child.getAttribute('cResizableColumn') || child.innerText || '').trim();
          if (columnKey) {
            state[columnKey] = child.offsetWidth;
            storage.setItem(stateKey, JSON.stringify(state));
          }
        }
      }
    }

    this.showResetIcon();
  }
}
