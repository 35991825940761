import { Pipe, PipeTransform } from '@angular/core';
import { CodeNameEntity, DescriptionService } from '@core';
import {
  ArtArticle,
  C4cAddress,
  C4cContact,
  C4cCustomer,
  C4cProject,
  CcfConfiguration,
  Country,
  Currency,
  MtlConfiguration,
  PrjMaterial,
  PrjStakeholder,
  User,
} from '@api';

@Pipe({
  name: 'description',
})
export class DescriptionPipe implements PipeTransform {
  constructor(public descriptionService: DescriptionService) {}

  transform(
    value:
      | Country
      | Currency
      | User
      | ArtArticle
      | PrjStakeholder
      | C4cProject
      | C4cCustomer
      | C4cAddress
      | C4cContact
      | PrjMaterial
      | CodeNameEntity
      | string
      | number,
    type:
      | 'country'
      | 'currency'
      | 'currencySymbol'
      | 'user'
      | 'areaManagerUser'
      | 'stakeholder'
      | 'c4cProject'
      | 'c4cCustomer'
      | 'c4cAddress'
      | 'c4cContact'
      | 'article'
      | 'articleShortText'
      | 'articleInterfaceText'
      | 'materialDescription'
      | 'codeName'
      | 'name'
      | 'floor',
    argument1?: ArtArticle | CodeNameEntity[],
    argument2?: CcfConfiguration,
    argument3?: MtlConfiguration,
  ): string {
    switch (type) {
      case 'country':
        return this.descriptionService.country(value as Country | string);
      // break;
      case 'currency':
        return this.descriptionService.currency(value as Currency | string);
      // break;
      case 'currencySymbol':
        return this.descriptionService.currencySymbol(value as Currency | string);
      // break;
      case 'user':
        return this.descriptionService.user(value as User);
      // break;
      case 'areaManagerUser':
        return this.descriptionService.areaManagerUser(value as User);
      // break;
      case 'stakeholder':
        return this.descriptionService.stakeholder(value as PrjStakeholder);
      // break;
      case 'c4cProject':
        return this.descriptionService.c4cProject(value as C4cProject);
      // break;
      case 'c4cCustomer':
        return this.descriptionService.c4cCustomer(value as C4cCustomer);
      // break;
      case 'c4cAddress':
        return this.descriptionService.c4cAddress(value as C4cAddress);
      // break;
      case 'c4cContact':
        return this.descriptionService.c4cContact(value as C4cContact);
      // break;
      case 'article':
        return this.descriptionService.article(value as ArtArticle);
      // break;
      case 'articleShortText':
        return this.descriptionService.articleShortText(value as ArtArticle);
      // break;
      case 'articleInterfaceText':
        return this.descriptionService.articleInterfaceText(value as ArtArticle);
      // break;
      case 'materialDescription':
        return this.descriptionService.materialDescription(
          value as PrjMaterial,
          argument1 as ArtArticle,
          argument2 as CcfConfiguration,
          argument3 as MtlConfiguration,
        );
      // break;
      case 'codeName':
        return this.descriptionService.codeName(value as CodeNameEntity, argument1 as CodeNameEntity[]);
      // break;
      case 'name':
        return this.descriptionService.name(value as CodeNameEntity, argument1 as CodeNameEntity[]);
      // break;
      case 'floor':
        return this.descriptionService.floor(value as number);
      // break;
      default:
        throw 'Invalid description pipe type';
    }
    // return value as string;
  }
}
