/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserStatus = 'forgotten' | 'blocked' | 'not_registered' | 'unauthorized' | 'rejected' | 'authorized';

export const UserStatus = {
    Forgotten: 'forgotten' as UserStatus,
    Blocked: 'blocked' as UserStatus,
    NotRegistered: 'not_registered' as UserStatus,
    Unauthorized: 'unauthorized' as UserStatus,
    Rejected: 'rejected' as UserStatus,
    Authorized: 'authorized' as UserStatus
};

