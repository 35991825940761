// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  version: env.npm_package_version || 'undefined',
  serverUrl: env.SERVER_URL || 'http://localhost:8000',
  sentryUrl: env.SENTRY_URL || null,
  sentryTraceRate: parseFloat(env.SENTRY_TRACE_RATE) || 0, // no performance monitoring
  sentrySessionRate: parseFloat(env.SENTRY_SESSION_RATE) || 0, // no session replay
  sentryEnvironment: env.SENTRY_ENVIRONMENT || 'local',
  ccfDimensions: {
    adapterLength: 40,
    endModuleLength: 20,
    edgeModuleLength: 110,
    unitLength: 150,
    size2FirstSideConnection: 75,
    size2SecondSideConnection: 225,
    size3FirstSideConnection: 75,
    size3SecondSideConnection: 225,
    size3ThirdSideConnection: 375,
    size2FirstMainConnection: 150,
    size2SecondMainConnection: 150,
    size3FirstMainConnection: 113,
    size3SecondMainConnection: 338,
    frontModuleHeight: 160,
    endModuleHeight: 135,
    mainConnection: 160,
  },
};
