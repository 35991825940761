<div
  [cTooltip]="
    tooltip ||
    ('ui.app.ccf.configurator.blank-module-side.blank-module-side-component-html.blank-module-tooltip' | translate)
  "
  class="module text-white blink"
>
  <div
    (click)="changeModule()"
    *ngIf="!readonly"
    class="cursor-pointer w-full h-full text-center text-small text-truncate rotate"
  >
    <span *ngIf="label">{{ label }}</span>
    <span *ngIf="!label" translate>
      ui.app.ccf.configurator.blank-module-side.blank-module-side-component-html.select
    </span>
  </div>
</div>
