<router-outlet></router-outlet>

<p-toast></p-toast>

<p-confirmDialog></p-confirmDialog>

<p-dialog
  (keyup.enter)="showNewsModal = false"
  (onHide)="onHideNewsDialog()"
  [(visible)]="showNewsModal"
  [closeOnEscape]="true"
  [dismissableMask]="false"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="header">
    <h4 class="text-ellipsis">{{ currentNews.title }}</h4>
  </ng-template>

  <ng-template pTemplate="content">
    <div [innerHTML]="currentNews.text | bypassSecurityTrustHtml" class="news-content"></div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="p-pt-3">
      <p-button
        (onClick)="showNewsModal = false"
        [label]="'ui.app-component-html.ok' | translate"
        icon="pi pi-check"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
