import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, skip, switchMap } from 'rxjs/operators';
import {
  ConfigService,
  CredentialsService,
  DelayedMessagesService,
  I18nService,
  LoggerService,
  UntilDestroy,
  untilDestroyed,
} from '@core';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from './auth/authentication.service';
import { News, NewsService as ApiNewsService } from '@api';

const log = new LoggerService('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
})
export class AppComponent implements OnInit, OnDestroy {
  news: News[] = [];
  currentNews: News;
  showNewsModal: boolean;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public titleService: Title,
    public configService: ConfigService,
    public translateService: TranslateService,
    public i18nService: I18nService,
    public credentialsService: CredentialsService,
    public authenticationService: AuthenticationService,
    public messageService: MessageService,
    public delayedMessagesService: DelayedMessagesService,
    public apiNewsService: ApiNewsService,
    public swUpdate: SwUpdate,
  ) {}

  ngOnInit() {
    log.debug('init');

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.pipe(untilDestroyed(this)).subscribe(async () => {
        if (
          confirm(
            await this.translateService
              .get('ui.app-component-ts.a-new-version-of-the-application-is-available-reload')
              .toPromise(),
          )
        ) {
          window.location.reload();
        }
      });
    }

    // Render delayed messages
    if (this.delayedMessagesService.messages.length) {
      // This needs a bit of a delay, probably to allow the p-toast component to be rendered
      setTimeout(() => {
        this.messageService.addAll(this.delayedMessagesService.messages);
        this.delayedMessagesService.messages = [];
      }, 100);
    }

    // Subscribe for potential new messages
    this.delayedMessagesService.newMessage$
      .pipe(untilDestroyed(this))
      .subscribe((message) => this.messageService.add(message));

    // Reset translations on user locale changes.
    // Skip the first credentials change as that one comes from the local storage and overrides the locale set from the API config
    this.credentialsService.credentials$.pipe(untilDestroyed(this), skip(1)).subscribe((credentials) => {
      if (credentials && credentials.user && credentials.user.locale) {
        this.i18nService.locale = credentials.user.locale;
      }
    });

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or locale change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        untilDestroyed(this),
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    let loggedIn = false;
    // Refresh user information and double check on token validity
    this.authenticationService.refreshProfile().then(() => {
      this.credentialsService.credentials$.pipe(untilDestroyed(this)).subscribe((credentials) => {
        if (!loggedIn && credentials && credentials.user) {
          loggedIn = true;
          setTimeout(() => this.checkNews(), 3000); // initial check; delay it a bit
        }
        if (loggedIn && (!credentials || !credentials.user)) {
          loggedIn = false;
        }
      });
    }); // initial refresh

    setInterval(() => this.authenticationService.refreshProfile().then(), 60 * 1000); // once every minute

    // offset
    setTimeout(() => {
      setInterval(() => {
        this.configService.load();
      }, 60 * 1000); // check once every minute if no current news
    }, 15 * 1000);

    // offset
    setTimeout(() => {
      if (loggedIn) {
        setInterval(() => {
          this.checkNews();
        }, 60 * 1000); // check once every minute if no current news
      }
    }, 30 * 1000);
  }

  checkNews() {
    // We also want the text field included
    this.apiNewsService
      .newsList(
        null,
        JSON.stringify([
          {
            field: 'required',
            value: 1,
          },
          {
            field: 'reads',
            value: 0,
          },
        ]),
        0,
        0, // bring all
        'sort_order',
        'asc',
      )
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        const freshNews = res.data || [];
        if (freshNews.length) {
          // eliminate current
          if (this.currentNews) {
            const index = this.currentNews
              ? freshNews.findIndex((potential) => potential.id === this.currentNews.id)
              : -1;
            if (index !== -1) {
              freshNews.splice(index, 1);
            }
          }
        }
        this.news = freshNews;

        this.nextNews();
      });

    // Hovering titles for ellipsis elements
    setInterval(() => {
      document
        .querySelectorAll(
          [
            // Cannot use :not([title]) as the content might change
            '.text-ellipsis',

            '.p-autocomplete-token-label',
            '.p-autocomplete-item span',

            '.p-dropdown-label',
            '.p-dropdown-item span',

            '.p-multiselect-token-label',
            '.p-multiselect-item span',

            '.p-chips-token',
          ].join(','),
        )
        .forEach((el) => {
          if (el.getAttribute('title') !== el.textContent) {
            el.setAttribute('title', el.textContent);
          }
        });

      document.querySelectorAll(['.p-autocomplete-input'].join(',')).forEach((el: HTMLInputElement) => {
        if (el.getAttribute('title') !== el.value) {
          el.setAttribute('title', el.value);
        }
      });
    }, 300); // because all elements are grabbed, trying to reduce the CPU load
  }

  nextNews() {
    if (!this.currentNews && this.news.length) {
      this.currentNews = this.news.shift();
    }

    if (this.currentNews && !this.showNewsModal) {
      this.showNewsModal = true;
    }
  }

  onHideNewsDialog() {
    if (this.currentNews) {
      this.apiNewsService
        .newsRead(this.currentNews.id, null, null, null, true)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.authenticationService.refreshProfile().then();
        });

      this.currentNews = null;
    }

    this.nextNews();
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
