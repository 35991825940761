import { Component } from '@angular/core';
import { FrontModuleTopComponent } from '../front-module-top/front-module-top.component';
import { CcfPartEndModuleAdapter, CcfPartEndModuleType } from '@api';

@Component({
  selector: 'app-ccf-configurator-end-module-top',
  templateUrl: './end-module-top.component.html',
  styleUrls: ['./end-module-top.component.scss'],
})
export class EndModuleTopComponent extends FrontModuleTopComponent {
  get CcfPartEndModuleType() {
    return CcfPartEndModuleType;
  }

  get CcfPartEndModuleAdapter() {
    return CcfPartEndModuleAdapter;
  }
}
