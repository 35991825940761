import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NumberLocalePipe } from './number-locale.pipe';
import { DateLocalePipe } from './date-locale.pipe';
import { LongDateLocalePipe } from './long-date-locale.pipe';
import { DescriptionPipe } from './description.pipe';
import { Nl2pipePipe } from './nl2pipe.pipe';
import { Nl2brPipe } from './nl2br.pipe';
import { BypassSecurityTrustHtmlPipe } from './bypass-security-trust-html.pipe';

import { AutofocusDirective } from './autofocus.directive';
import { ResponsiveImageSizesDirective } from './responsive-image-sizes.directive';
import { DndFileDirective } from './dnd-file.directive';
import { NgVarDirective } from './ng-var.directive';

import { LoaderComponent } from './loader/loader.component';
import { RedirectComponent } from './redirect/redirect.component';
import { NameComponent } from './name/name.component';
import { NavigationComponent } from './navigation/navigation.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';

import { CTooltipModule } from '@components';

import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    SidebarModule,
    DropdownModule,
    CTooltipModule,
  ],
  declarations: [
    NumberLocalePipe,
    DateLocalePipe,
    LongDateLocalePipe,
    DescriptionPipe,
    Nl2pipePipe,
    Nl2brPipe,
    BypassSecurityTrustHtmlPipe,
    AutofocusDirective,
    ResponsiveImageSizesDirective,
    DndFileDirective,
    NgVarDirective,
    LoaderComponent,
    RedirectComponent,
    NameComponent,
    NavigationComponent,
    LanguageSelectorComponent,
  ],
  exports: [
    NumberLocalePipe,
    DateLocalePipe,
    LongDateLocalePipe,
    DescriptionPipe,
    Nl2pipePipe,
    Nl2brPipe,
    BypassSecurityTrustHtmlPipe,
    AutofocusDirective,
    ResponsiveImageSizesDirective,
    DndFileDirective,
    NgVarDirective,
    LoaderComponent,
    RedirectComponent,
    NameComponent,
    NavigationComponent,
    LanguageSelectorComponent,
  ],
})
export class SharedModule {}
