import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipModule } from 'primeng/tooltip';

import { CTooltipDirective } from './c-tooltip.directive';

@NgModule({
  imports: [CommonModule, TooltipModule],
  declarations: [CTooltipDirective],
  exports: [CTooltipDirective],
})
export class CTooltipModule {}
