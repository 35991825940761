<header *ngIf="!isLoadingLogout">
  <c-menubar [autoDisplay]="false" [model]="appMenuItems" styleClass="p-px-3 bg-white">
    <ng-template pTemplate="start">
      <div class="logo-wrapper p-mr-3">
        <a class="navbar-brand p-shadow-1 p-py-0" href="/">
          <img
            [attr.srcset]="headerLogo?.srcset"
            [src]="headerLogo?.url || '/assets/zehnder-comfoplanplus-logo.jpg?v=' + environment.version"
            [title]="(appName || 'ComfoPlan+') + ' ' + environment.version + ' ' + appVersion"
            alt="logo"
          />
        </a>
      </div>
    </ng-template>
    <ng-template pTemplate="end">
      <div class="p-d-flex p-flex-nowrap p-ai-center" style="flex: 1 1 100%; width: fit-content; max-height: 40px">
        <app-language-selector *ngIf="!credentials?.user"></app-language-selector>

        <ng-container *ngIf="credentials?.user">
          <div
            (click)="userMenu.toggle($event)"
            class="p-d-inline-block avatar cursor-pointer"
            style="width: 40px; height: 40px"
          >
            <img
              *ngIf="credentials?.user?.profile_picture_url || credentials?.user?.profile_picture_srcset"
              [attr.srcset]="credentials?.user?.profile_picture_srcset"
              [src]="credentials?.user?.profile_picture_url"
              responsiveImageSizes
            />
          </div>

          <p-badge
            *ngIf="unreadNewsCount"
            [value]="unreadNewsCount | numberLocale"
            class="avatar-badge"
            styleClass="p-mr-1"
          ></p-badge>

          <p-tieredMenu #userMenu [model]="userMenuItems" [popup]="true"></p-tieredMenu>

          <div class="p-d-inline-block">
            <app-navigation [header]="true" [sidebar]="true"></app-navigation>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </c-menubar>
</header>

<p-sidebar
  [(visible)]="showProfileModal"
  [blockScroll]="true"
  [closeOnEscape]="false"
  [dismissible]="false"
  position="right"
  styleClass="p-sidebar-lg sidebar-hide-header-and-footer sidebar-content-h-100"
>
  <app-user-form
    (cancelled)="showProfileModal = false"
    (saved)="showProfileModal = false"
    [forProfile]="showProfileModal"
  ></app-user-form>
</p-sidebar>

<p-sidebar
  [(visible)]="showNewsModal"
  [blockScroll]="true"
  [closeOnEscape]="false"
  [dismissible]="false"
  position="right"
  styleClass="p-sidebar-md sidebar-hide-header-and-footer sidebar-content-h-100"
>
  <app-shell-news
    (cancelled)="showNewsModal = false"
    [disabled]="!showNewsModal"
    [reloadTimestamp]="newsListReloadTimestamp"
  ></app-shell-news>
</p-sidebar>
