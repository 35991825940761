import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { InputNumberModule } from 'primeng/inputnumber';

import { CInputNumberComponent } from './c-input-number.component';
import { ConversionPipe } from './conversion.pipe';

@NgModule({
  imports: [CommonModule, FormsModule, InputNumberModule],
  declarations: [CInputNumberComponent, ConversionPipe],
  exports: [CInputNumberComponent, ConversionPipe],
})
export class CInputNumberModule {}
