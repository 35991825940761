import { Component, EventEmitter, Output } from '@angular/core';
import { CcfPart } from '@api';
import { BlankModuleSideComponent } from '../blank-module-side/blank-module-side.component';

@Component({
  selector: 'app-ccf-configurator-size-2-module-top',
  templateUrl: './size-2-module-top.component.html',
  styleUrls: ['./size-2-module-top.component.scss'],
})
export class Size2ModuleTopComponent extends BlankModuleSideComponent {
  @Output() deletedModule: EventEmitter<CcfPart> = new EventEmitter<CcfPart>();

  deleteModule() {
    if (this.readonly) {
      return;
    }

    this.deletedModule.emit(this.module);
  }
}
