import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

// TODO: Use an array instead of a string to keep track of the parts.
export const titleSeparator = ' | ';

@Injectable({ providedIn: 'root' })
export class TitleService {
  constructor(public titleService: Title) {}

  // Call this in ngOnInit to make sure the marker title is loaded
  push(part: string = null) {
    let title = this.titleService.getTitle();
    if (title.length) {
      title += titleSeparator;
    }
    this.titleService.setTitle(title + (typeof part === 'string' ? part : '...'));

    return this;
  }

  pop() {
    let title = this.titleService.getTitle();
    if (title.length) {
      const parts = title.split(titleSeparator);
      parts.pop();
      title = parts.join(titleSeparator);
      this.titleService.setTitle(title);
    }

    return this;
  }

  replace(part: string = null) {
    this.pop().push(part);

    return this;
  }
}
