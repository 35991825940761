import { Injectable } from '@angular/core';
import {
  AuthConfig,
  Config,
  ConfigService as ApiConfigService,
  DebugConfig,
  MbuMedia,
  SettingValue,
  TranslationsConfig,
} from '@api';
import { BehaviorSubject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public debugResponse: HttpResponse<{ data?: Config }>;
  public debugConfig$ = new BehaviorSubject<DebugConfig>(null);
  public translationsConfig$ = new BehaviorSubject<TranslationsConfig>(null);
  public authConfig$ = new BehaviorSubject<AuthConfig>(null);
  public settingsConfig$ = new BehaviorSubject<{ [key: string]: SettingValue }>(null);
  public mediasConfig$ = new BehaviorSubject<{ [key: string]: MbuMedia }>(null);

  constructor(public apiConfigService: ApiConfigService) {
    this.load();
  }

  load() {
    // Observe response, get phpdebugbar-id header and keep it for the debug service to fetch it
    this.apiConfigService
      .config(null, null, null, 'response')
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: HttpResponse<{ data?: Config }>) => {
          this.debugResponse = res;

          // Avoid re-setting the observable values if not needed

          const debugConfig = res.body && res.body.data ? res.body.data.debug : {};
          if (JSON.stringify(debugConfig) !== JSON.stringify(this.debugConfig$.getValue())) {
            this.debugConfig$.next(debugConfig);
          }

          const translationsConfig = res.body && res.body.data ? res.body.data.translations : {};
          if (JSON.stringify(translationsConfig) !== JSON.stringify(this.translationsConfig$.getValue())) {
            this.translationsConfig$.next(translationsConfig);
          }

          const authConfig = res.body && res.body.data ? res.body.data.auth : {};
          if (JSON.stringify(authConfig) !== JSON.stringify(this.authConfig$.getValue())) {
            this.authConfig$.next(authConfig);
          }

          const settingsConfig = res.body && res.body.data ? res.body.data.settings : {};
          if (JSON.stringify(settingsConfig) !== JSON.stringify(this.settingsConfig$.getValue())) {
            this.settingsConfig$.next(settingsConfig);
          }

          const mediasConfig = res.body && res.body.data ? res.body.data.medias : {};
          if (JSON.stringify(mediasConfig) !== JSON.stringify(this.mediasConfig$.getValue())) {
            this.mediasConfig$.next(mediasConfig);
          }
        },
        () => {
          this.debugConfig$.next({});
          this.translationsConfig$.next({});
          this.authConfig$.next({});
          this.settingsConfig$.next({});
          this.mediasConfig$.next({});
        },
      );
  }
}
