import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CCarouselComponent } from './c-carousel.component';

import { CarouselModule } from 'primeng/carousel';

import { SharedModule } from 'primeng/api'; // for pTemplate in parent components
import { RippleModule } from 'primeng/ripple';
import { ChevronRightIcon } from 'primeng/icons/chevronright';
import { ChevronLeftIcon } from 'primeng/icons/chevronleft';
import { ChevronDownIcon } from 'primeng/icons/chevrondown';
import { ChevronUpIcon } from 'primeng/icons/chevronup';

@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    RippleModule,
    ChevronRightIcon,
    ChevronLeftIcon,
    ChevronDownIcon,
    ChevronUpIcon,
  ],
  declarations: [CCarouselComponent],
  exports: [CCarouselComponent, SharedModule], // SharedModule for pTemplate in parent components
})
export class CCarouselModule {}
