import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  @Input() url: string | string[];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
  ) {
    const handler = (url: string | string[]) => {
      url = typeof url === 'string' ? url : url.join('/');
      if (url.indexOf('://')) {
        window.location.replace(url);
      } else {
        this.router.navigate(typeof this.url === 'string' ? [this.url] : this.url, { replaceUrl: true }).then();
      }
    };

    if (this.url) {
      handler(this.url);
    } else {
      this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
        if (params.url) {
          handler(params.url);
        }
      });
    }
  }

  ngOnInit() {}
}
