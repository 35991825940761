import { Component, Input, OnChanges } from '@angular/core';
import { CcfConfiguration, CcfPart, CcfPartMedia, Media } from '@api';
import { DescriptionService } from '@core';

@Component({
  selector: 'app-ccf-configurator-module-tile',
  templateUrl: './module-tile.component.html',
  styleUrls: ['./module-tile.component.scss'],
})
export class ModuleTileComponent implements OnChanges {
  @Input() module: CcfPart | CcfConfiguration;
  @Input() impossible: false;

  public media: CcfPartMedia | Media;

  constructor() {}

  static getMedia(module: CcfPart | CcfConfiguration) {
    if (!module) {
      return;
    }

    let media: CcfPartMedia = null;

    const article = (module as CcfConfiguration).article;
    if (article) {
      if (!media && article.tagged_picture_sources && article.tagged_picture_sources.length) {
        media = article.tagged_picture_sources[0];
      }

      if (!media && article.tagged_pictures && article.tagged_pictures.length) {
        media = article.tagged_pictures[0];
      }

      if (!media && article.media && article.media.length) {
        media = article.media[0];
      }
    }

    if (!media && module.media) {
      if (Array.isArray(module.media) && module.media.length) {
        media = module.media[0];
      } else {
        media = module.media as CcfPartMedia;
      }
    }

    if (!media && (module as CcfConfiguration).side_picture_url) {
      media = {
        url: (module as CcfConfiguration).side_picture_url,
        srcset: (module as CcfConfiguration).side_picture_srcset,
      };
    }

    if (!media && (module as CcfConfiguration).top_picture_url) {
      media = {
        url: (module as CcfConfiguration).top_picture_url,
        srcset: (module as CcfConfiguration).top_picture_srcset,
      };
    }

    return media;
  }

  static getName(module: CcfPart | CcfConfiguration, descriptionService: DescriptionService) {
    if (!module) {
      return;
    }

    let name: string = null;

    const article = (module as CcfConfiguration).article;
    if (article && article.translated_texts) {
      name = descriptionService.article(article);
    }

    if (!name) {
      name = module.name;
    }

    return name;
  }

  ngOnChanges() {
    this.media = ModuleTileComponent.getMedia(this.module);
  }
}
