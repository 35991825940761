import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '@core';
import { environment } from '@env/environment';
import { MbuMedia, MbuMediaTag } from '@api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss'],
})
export class NameComponent implements OnInit {
  @Input() styleClass: string | undefined;

  environment = environment;

  appName: string;
  appVersion: string;
  nameLogo: MbuMedia;

  constructor(public configService: ConfigService) {
    this.configService.settingsConfig$.pipe(untilDestroyed(this)).subscribe((settingsConfig) => {
      this.appName = settingsConfig ? (settingsConfig['APP_NAME'] as string) : null;
      this.appVersion = settingsConfig ? (settingsConfig['APP_VERSION'] as string) : null;
    });

    this.configService.mediasConfig$
      .pipe(untilDestroyed(this))
      .subscribe((mediasConfig) => (this.nameLogo = mediasConfig ? mediasConfig[MbuMediaTag.NameLogo] : null));
  }

  ngOnInit() {}
}
