<p-inputNumber
  #inputNumber
  (ngModelChange)="onChildModelChange($event)"
  [(ngModel)]="value"
  [disabled]="disabled"
  [inputId]="inputId"
  [inputStyleClass]="inputStyleClass"
  [locale]="locale"
  [maxFractionDigits]="maxFractionDigits"
  [max]="maxValue"
  [minFractionDigits]="minFractionDigits"
  [min]="minValue"
  [name]="name"
  [ngClass]="ngClass"
  [showButtons]="false"
  [step]="stepValue"
  [styleClass]="styleClass"
  [style]="style"
></p-inputNumber>
