/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjRoomComfortClass = '1' | '2' | '3' | '4';

export const PrjRoomComfortClass = {
    _1: '1' as PrjRoomComfortClass,
    _2: '2' as PrjRoomComfortClass,
    _3: '3' as PrjRoomComfortClass,
    _4: '4' as PrjRoomComfortClass
};

