import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { ConfigService, CredentialsService, I18nService, LoggerService } from '@core';
import { AuthenticationService } from './authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const log = new LoggerService('Login');

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: UntypedFormGroup;
  sso = false;
  isLoadingConfig = true;
  isLoadingSso = false;
  isLoadingCredentialsAuth = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    public credentialsService: CredentialsService,
    public authenticationService: AuthenticationService,
    public i18nService: I18nService,
    public configService: ConfigService,
  ) {
    configService.authConfig$.pipe(untilDestroyed(this)).subscribe((config) => {
      if (!config) {
        return;
      }

      this.isLoadingConfig = false;

      // TODO: Perhaps implement provider + code/ic and redirect to redirect to /oauth/callback/{provider}?code={code}&ic={code}
      // TODO: Perhaps check if authenticated and redirect to redirect_uri or home

      if (
        config.sso &&
        this.route.snapshot &&
        this.route.snapshot.queryParams &&
        this.route.snapshot.queryParams.token
      ) {
        this.isLoadingSso = true;

        this.credentialsService.setCredentials({
          ...this.credentialsService.credentials$.getValue(),
          token: this.route.snapshot.queryParams.token,
        });

        this.authenticationService
          .loginWithToken()
          .pipe(
            untilDestroyed(this),
            finalize(() => {
              this.isLoadingSso = false;
            }),
          )
          .subscribe(
            (credentials) => {
              if (credentials && credentials.user) {
                log.debug(`${credentials.user.inverted_name} successfully logged in`);
              }
              this.navigateAfterLogin();
            },
            (error) => {
              log.debug(`Login error: ${error}`);
              this.error = error;
            },
          );
      } else if (config.sso && !config.credentials) {
        this.loginWithSso();
      } else {
        this.sso = config.sso;
        if (config.credentials) {
          this.createForm();
        }
      }
    });
  }

  ngOnInit() {}

  login() {
    this.isLoadingCredentialsAuth = true;
    const login$ = this.authenticationService.login(this.loginForm.value);
    login$
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoadingCredentialsAuth = false;
        }),
      )
      .subscribe(
        (credentials) => {
          if (credentials && credentials.user) {
            log.debug(`${credentials.user.inverted_name} successfully logged in`);
          }
          this.navigateAfterLogin();
        },
        (error) => {
          log.debug(`Login error: ${error}`);
          this.error = error;
        },
      );
  }

  loginWithSso() {
    this.isLoadingSso = true;

    window.location.href =
      environment.serverUrl +
      '/sso/login?locale=' +
      encodeURIComponent(this.i18nService.locale) +
      '&redirect_uri=' +
      encodeURIComponent(window.location.href);
  }

  private navigateAfterLogin() {
    let route: string =
      this.route.snapshot && this.route.snapshot.queryParams ? this.route.snapshot.queryParams.redirect_uri : null;

    if (route) {
      if (route.indexOf('?') !== -1) {
        const parts = this.route.snapshot.queryParams.redirect_uri.split('?');
        const queryParams = {};
        new URLSearchParams(parts.pop()).forEach((value, key) => {
          queryParams[key] = value;
        });

        if (parts.length) {
          this.router.navigate([parts[0]], { queryParams, replaceUrl: true }).then();
          return;
        }
      }
    }

    this.router.navigate([route || '/'], { replaceUrl: true }).then();
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }
}
