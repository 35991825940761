import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { I18nService } from '../i18n.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class I18nHeaderInterceptor implements HttpInterceptor {
  constructor(public i18nService: I18nService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const locale = this.i18nService.locale;
    if (locale) {
      let headers = request.headers;
      const headerName = 'Accept-Language';
      const headerValue = headers.get(headerName);

      headers = headers.set(headerName, locale + (headerValue ? ',' + headerValue : ''));

      request = request.clone({ headers });
    }

    return next.handle(request);
  }
}
