import { Component, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CcfConfiguration, PermissionName } from '@api';
import { ConfiguratorService } from '../configurator/configurator.service';
import { CredentialsService } from '@core';

@UntilDestroy()
@Component({
  selector: 'app-ccf-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements OnInit {
  configuration: CcfConfiguration;

  canViewSapNumbers: boolean;
  showSapInfoModal: boolean;

  constructor(
    public configuratorService: ConfiguratorService,
    public credentialsService: CredentialsService,
  ) {
    this.configuratorService.reset(); // all configurator components on the screen share the same parts and configuration presets

    this.credentialsService.credentials$.pipe(untilDestroyed(this)).subscribe(async () => {
      this.canViewSapNumbers = this.credentialsService.can(PermissionName.ViewSapNumbers);
    });
  }

  ngOnInit() {}

  print() {
    window.print();
  }
}
