import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AuthService, AuthToken, User } from '@api';
import { finalize, map, switchMap } from 'rxjs/operators';
import { ConfigService, Credentials, CredentialsService, I18nService } from '@core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

export interface LoginContext {
  username: string;
  password: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    public credentialsService: CredentialsService,
    public apiAuthService: AuthService,
    public configService: ConfigService,
    public i18nService: I18nService,
    public router: Router,
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<Credentials> {
    return this.handleLogin(this.apiAuthService.authLogin(context));
  }

  /**
   * Refreshes the token.
   * @return The user credentials.
   */
  loginWithToken(): Observable<Credentials> {
    return this.handleLogin(this.apiAuthService.authRefresh());
  }

  /**
   * Get a profile redirect URI
   */
  profileRedirect() {
    return this.apiAuthService.authProfileRedirect();
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(forgotten = false): Observable<boolean> {
    return this.configService.authConfig$.pipe(
      switchMap((config) => {
        const handler = () => {
          if (config.sso) {
            // Allow the normal API logout request just in case; it cannot hurt.
            window.location.href =
              environment.serverUrl +
              '/sso/logout?locale=' +
              encodeURIComponent(this.i18nService.locale) +
              '&redirect_uri=' +
              encodeURIComponent(window.location.href);
          } else {
            this.router.navigate(['/login'], { replaceUrl: true }).then();
          }
        };

        if (forgotten) {
          this.credentialsService.setCredentials();
          handler();
          return of(true);
        }

        return this.apiAuthService.authLogout().pipe(
          map(() => {
            return true;
          }),
          finalize(() => {
            this.credentialsService.setCredentials();
            handler();
          }),
        );
      }),
    );
  }

  refreshProfile(profile: User = null): Promise<User> {
    if (profile) {
      this.credentialsService.setCredentials({
        ...this.credentialsService.credentials$.getValue(),
        user: profile,
      });

      return Promise.resolve(profile);
    }

    if (this.credentialsService.isAuthenticated()) {
      return this.apiAuthService
        .authGetMe()
        .toPromise()
        .then((res) => {
          this.credentialsService.setCredentials({
            ...this.credentialsService.credentials$.getValue(),
            user: res.data,
          });

          return res.data;
        });
    }

    return Promise.resolve(null);
  }

  private handleLogin(obs: Observable<{ data?: AuthToken }>): Observable<Credentials> {
    let authResponse: AuthToken;

    return obs.pipe(
      switchMap((res) => {
        authResponse = res.data; // save it to return it after the identity request
        // set the token
        this.credentialsService.setCredentials({
          ...this.credentialsService.credentials$.getValue(),
          token: res.data ? res.data.access_token : null,
        });
        return this.apiAuthService.authGetMe();
      }),
      switchMap((res) => {
        // set the username as well
        this.credentialsService.setCredentials({
          ...this.credentialsService.credentials$.getValue(),
          user: res.data,
        });
        return of({
          ...this.credentialsService.credentials$.getValue(),
        });
      }),
    );
  }
}
