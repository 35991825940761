/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArtInstallationDefaultItemType = 'D' | 'G' | 'M' | 'A' | 'F' | 'T' | 'C' | 'L' | 'R';

export const ArtInstallationDefaultItemType = {
    D: 'D' as ArtInstallationDefaultItemType,
    G: 'G' as ArtInstallationDefaultItemType,
    M: 'M' as ArtInstallationDefaultItemType,
    A: 'A' as ArtInstallationDefaultItemType,
    F: 'F' as ArtInstallationDefaultItemType,
    T: 'T' as ArtInstallationDefaultItemType,
    C: 'C' as ArtInstallationDefaultItemType,
    L: 'L' as ArtInstallationDefaultItemType,
    R: 'R' as ArtInstallationDefaultItemType
};

