/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArtTextType = 'interface' | 'short' | 'long' | 'extended' | 'tooltip' | 'montage';

export const ArtTextType = {
    Interface: 'interface' as ArtTextType,
    Short: 'short' as ArtTextType,
    Long: 'long' as ArtTextType,
    Extended: 'extended' as ArtTextType,
    Tooltip: 'tooltip' as ArtTextType,
    Montage: 'montage' as ArtTextType
};

