/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjProjectStep = 'details' | 'planning' | 'device' | 'accessories' | 'outlets' | 'manifolds' | 'cpd' | 'inside_installations' | 'building_installations' | 'outside_installations' | 'complete_apartment' | 'complete_building' | 'materials' | 'documents';

export const PrjProjectStep = {
    Details: 'details' as PrjProjectStep,
    Planning: 'planning' as PrjProjectStep,
    Device: 'device' as PrjProjectStep,
    Accessories: 'accessories' as PrjProjectStep,
    Outlets: 'outlets' as PrjProjectStep,
    Manifolds: 'manifolds' as PrjProjectStep,
    Cpd: 'cpd' as PrjProjectStep,
    InsideInstallations: 'inside_installations' as PrjProjectStep,
    BuildingInstallations: 'building_installations' as PrjProjectStep,
    OutsideInstallations: 'outside_installations' as PrjProjectStep,
    CompleteApartment: 'complete_apartment' as PrjProjectStep,
    CompleteBuilding: 'complete_building' as PrjProjectStep,
    Materials: 'materials' as PrjProjectStep,
    Documents: 'documents' as PrjProjectStep
};

