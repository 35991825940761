import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { DateFormat, ErrorValidationResponse, LongDateFormat, NumberFormat, SettingType } from '@api';
import { I18nService } from './i18n.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FormattingService {
  numberLocale: string;
  numberLocaleType: string;
  dateFormat: string;
  dateFormatCalendar: string;
  longDateFormat: string;
  yearRange = '1900:2100';

  constructor(
    public configService: ConfigService,
    public i18nService: I18nService,
    public translateService: TranslateService,
    public confirmationService: ConfirmationService,
  ) {
    this.configService.settingsConfig$.pipe(untilDestroyed(this)).subscribe((settings) => {
      switch (settings[SettingType.NumberFormat]) {
        case NumberFormat.CommaDecimals:
          this.numberLocale = 'de-DE';
          break;
        case NumberFormat.DotDecimals:
          this.numberLocale = 'en-US';
          break;
        default:
          this.numberLocale = 'de-DE';
      }

      switch (settings[SettingType.DateFormat]) {
        case DateFormat.Dmy:
          this.dateFormat = 'dd.MM.yyyy';
          this.dateFormatCalendar = 'dd.mm.yy';
          break;
        case DateFormat.Mdy:
          this.dateFormat = 'MM/dd/yyyy';
          this.dateFormatCalendar = 'mm/dd/yy';
          break;
        default:
          this.dateFormat = 'dd.MM.yyyy';
          this.dateFormatCalendar = 'dd.mm.yy';
      }

      switch (settings[SettingType.LongDateFormat]) {
        case LongDateFormat.Dmy:
          this.longDateFormat = 'dd.MM.yyyy HH:mm:ss';
          break;
        case LongDateFormat.Mdy:
          this.longDateFormat = 'MM/dd/yyyy HH:mm:ss';
          break;
        default:
          this.longDateFormat = 'dd.MM.yyyy HH:mm:ss';
      }
    });
  }

  // To avoid JS numeric precision quirks we round to given number of decimals before applying ceil; see Math.ceil((0.1 + 0.2) * 10) vs Math.ceil(Math.round((0.1 + 0.2) * 10 * 100) / 100)
  static ceil(value: number, decimals = 2) {
    if (!value || typeof value !== 'number') {
      return value;
    }

    const factor = Math.pow(10, decimals);

    return Math.ceil(Math.round(value * factor) / factor);
  }

  // To avoid JS numeric precision quirks we round to given number of decimals before applying floor; see Math.floor((0.1 + 0.7) * 10) vs Math.floor(Math.round((0.1 + 0.7) * 10 * 100) / 100)
  static floor(value: number, decimals = 2) {
    if (!value || typeof value !== 'number') {
      return value;
    }

    const factor = Math.pow(10, decimals);

    return Math.floor(Math.round(value * factor) / factor);
  }

  formatNumber(
    value: number | string | null | undefined,
    minIntegerDigits?: number | null | undefined,
    minFractionDigits?: number | null | undefined,
    maxFractionDigits?: number | null | undefined,
    locale?: string | null | undefined,
  ) {
    if ([null, undefined].indexOf(value) !== -1) {
      return null;
    }

    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    return new Intl.NumberFormat(locale || this.numberLocale || this.i18nService.locale, {
      minimumIntegerDigits: minIntegerDigits,
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits,
    }).format(value);
  }

  parseFormattedNumberString(
    value: string | null | undefined,
    numberLocaleType?: NumberFormat | null | undefined,
  ): number | null {
    if ([null, undefined].indexOf(value) !== -1) {
      return null;
    }

    let decimalSeparator;
    let integerSeparator;
    switch (numberLocaleType || this.numberLocaleType) {
      case NumberFormat.CommaDecimals:
        decimalSeparator = ',';
        integerSeparator = '.';
        break;
      case NumberFormat.DotDecimals:
        decimalSeparator = '.';
        integerSeparator = ',';
        break;
      default:
        decimalSeparator = ',';
        integerSeparator = '.';
    }

    const splitValue = value.split(decimalSeparator);

    const integerValue = splitValue[0].split(integerSeparator).join('');
    const decimalValue = splitValue[1];

    if (integerValue === '') {
      return null;
    }

    const parsedNumberString = integerValue + (decimalValue ? '.' + decimalValue : '');

    return parseFloat(parsedNumberString);
  }

  explodeValidationMessages(value?: ErrorValidationResponse) {
    if (!value) {
      return;
    }

    [value.errors, value.warnings].forEach((issues) => {
      if (issues) {
        Object.keys(issues).forEach((key) => {
          const messages = issues[key];

          if (key.indexOf('.') === -1 || !messages || !messages.length) {
            return;
          }

          const parts = key.split('.');
          for (let index = 0; index < parts.length; index++) {
            key = parts.slice(0, index + 1).join('.');

            if (!issues[key]) {
              issues[key] = [];
            }

            messages.forEach((message) => {
              if (issues[key].indexOf(message) === -1) {
                issues[key].push(message);
              }
            });
          }
        });
      }
    });

    return value;
  }

  // null is not properly handled with form params (https://github.com/OpenAPITools/openapi-generator/blob/v5.2.0/modules/openapi-generator/src/main/resources/typescript-angular/api.service.mustache#L334)
  convertNullToUndefined(value: any) {
    return value === null ? undefined : value;
  }

  parseIntWithNullAndUndefined(value: string) {
    if (value === null) {
      return null;
    }
    if (value === undefined) {
      return undefined;
    }
    return parseInt(value);
  }

  async confirmForceDuplicate(accept = () => {}, message = '') {
    this.confirmationService.confirm({
      header: await this.translateService.get('ui.app.@core.formatting-service-ts.duplicate-conflict').toPromise(),
      message:
        (message
          ? message
          : await this.translateService.get('ui.app.@core.formatting-service-ts.duplicate-conflict').toPromise()) +
        '<br />' +
        (await this.translateService
          .get('ui.app.@core.formatting-service-ts.are-you-sure-you-want-to-override-it')
          .toPromise()),
      acceptLabel: await this.translateService.get('ui.app.@core.formatting-service-ts.ok').toPromise(),
      rejectLabel: await this.translateService.get('ui.app.@core.formatting-service-ts.cancel').toPromise(),
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept,
    });
  }
}
