/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjInsideInstallationSection = 'manifold' | 'filter' | 'attenuator' | 'comfotherm' | 'splitter' | 'duct_regulator';

export const PrjInsideInstallationSection = {
    Manifold: 'manifold' as PrjInsideInstallationSection,
    Filter: 'filter' as PrjInsideInstallationSection,
    Attenuator: 'attenuator' as PrjInsideInstallationSection,
    Comfotherm: 'comfotherm' as PrjInsideInstallationSection,
    Splitter: 'splitter' as PrjInsideInstallationSection,
    DuctRegulator: 'duct_regulator' as PrjInsideInstallationSection
};

