import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class DelayedMessagesService {
  newMessage$ = new Subject<Message>();
  messages: Message[] = []; // This will collect all the messages until the app is booted and ready to render them

  constructor() {
    this.newMessage$.pipe(untilDestroyed(this)).subscribe((message) => {
      if (this.newMessage$.observers.length > 1) {
        // The app is booted and subscribed
        return;
      }

      this.messages.push(message);
    });
  }
}
