import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[stickyTableHeader]',
})
export class StickyTableHeaderDirective implements AfterViewInit, OnDestroy {
  private scrollableElement: HTMLElement;
  private scrollableElementHandler: EventListenerOrEventListenerObject;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this.el && this.el.nativeElement && !this.scrollableElement) {
      const parentElement: HTMLElement = this.el.nativeElement; // this should be a p-table

      if (parentElement) {
        const isElementInsideSidebar = parentElement.closest('.p-sidebar-content');
        this.scrollableElement = isElementInsideSidebar
          ? isElementInsideSidebar.querySelector('.h-100.overflow-auto')
          : parentElement.closest('.cpp-content');

        if (this.scrollableElement) {
          this.scrollableElement.addEventListener(
            'scroll',
            (this.scrollableElementHandler = () => {
              const theadElement: HTMLElement = parentElement.querySelector('thead');

              if (theadElement) {
                const resetIconElement: HTMLElement = parentElement.querySelector('.c-resizable-column-reset-icon'); // from CResizableColumnDirective

                if (this.scrollableElement.scrollTop > parentElement.offsetTop - this.scrollableElement.offsetTop) {
                  const top = Math.min(
                    this.scrollableElement.scrollTop - parentElement.offsetTop + this.scrollableElement.offsetTop, // the top of the viewport
                    parentElement.offsetHeight - theadElement.offsetHeight - 1, // prevent header from going below the bottom edge
                  );

                  const topRem = top / 16 + 'rem';

                  theadElement.style.transform = 'translate(0, ' + topRem + ')';

                  if (resetIconElement) {
                    resetIconElement.style.top = topRem;
                  }
                } else {
                  theadElement.style.transform = '';

                  if (resetIconElement) {
                    resetIconElement.style.top = '0';
                  }
                }
              }
            }),
          );
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.scrollableElement && this.scrollableElementHandler) {
      this.scrollableElement.removeEventListener('scroll', this.scrollableElementHandler);
    }
  }
}
