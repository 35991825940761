import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormattingService, I18nService } from '@core';

@Pipe({
  name: 'numberLocale',
})
export class NumberLocalePipe extends DecimalPipe implements PipeTransform {
  constructor(
    public i18nService: I18nService,
    public formattingService: FormattingService,
  ) {
    super(i18nService.locale);
  }

  // @ts-ignore
  transform(
    value: number | string | null | undefined,
    minIntegerDigits?: number | null | undefined,
    minFractionDigits?: number | null | undefined,
    maxFractionDigits?: number | null | undefined,
    locale?: string,
  ): string | null {
    return this.formattingService.formatNumber(value, minIntegerDigits, minFractionDigits, maxFractionDigits, locale);
  }
}
