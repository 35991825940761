/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArtInstallationDefaultBoxConnectionType = 'N' | 'O' | 'C';

export const ArtInstallationDefaultBoxConnectionType = {
    N: 'N' as ArtInstallationDefaultBoxConnectionType,
    O: 'O' as ArtInstallationDefaultBoxConnectionType,
    C: 'C' as ArtInstallationDefaultBoxConnectionType
};

