import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CredentialsService } from '../credentials.service';
import { LoggerService } from '../logger.service';

const log = new LoggerService('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(
    public router: Router,
    public credentialsService: CredentialsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.credentialsService.isAuthenticated()) {
      if (route.data && route.data.permission) {
        if (this.credentialsService.can(route.data.permission)) {
          return true;
        }

        log.debug('Not allowed, redirecting');
        this.router.navigate(['/'], { replaceUrl: true }).then();
        return false;
      }
      return true;
    }

    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/login'], { queryParams: { redirect_uri: state.url }, replaceUrl: true }).then();
    return false;
  }
}
