/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjMaterialType = 'article' | 'blank' | 'break' | 'title';

export const PrjMaterialType = {
    Article: 'article' as PrjMaterialType,
    Blank: 'blank' as PrjMaterialType,
    Break: 'break' as PrjMaterialType,
    Title: 'title' as PrjMaterialType
};

