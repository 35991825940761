/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjBuildingEnergyStandard = 'level_1' | 'level_2' | 'level_3' | 'unknown';

export const PrjBuildingEnergyStandard = {
    Level1: 'level_1' as PrjBuildingEnergyStandard,
    Level2: 'level_2' as PrjBuildingEnergyStandard,
    Level3: 'level_3' as PrjBuildingEnergyStandard,
    Unknown: 'unknown' as PrjBuildingEnergyStandard
};

