/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjBuildingMediaTag = 'UNIT_DISTRIBUTION_INDIVIDUAL' | 'UNIT_DISTRIBUTION_CENTRALIZED' | 'WIND_AREA_WEAK' | 'WIND_AREA_STRONG' | 'HEAT_INSULATION_HIGH' | 'HEAT_INSULATION_LOW' | 'CATEGORY_NEW' | 'CATEGORY_RENOVATION' | 'ENERGY_STANDARD_LEVEL_1' | 'ENERGY_STANDARD_LEVEL_2' | 'ENERGY_STANDARD_LEVEL_3' | 'ENERGY_STANDARD_UNKNOWN' | 'CONSTRUCTION_METHOD_TIMBER_FRAME' | 'CONSTRUCTION_METHOD_SOLID' | 'CONSTRUCTION_METHOD_SUSPENDED_CEILING' | 'CALCULATION_METHOD_DIN' | 'CALCULATION_METHOD_NL' | 'CALCULATION_METHOD_BE' | 'CALCULATION_METHOD_FR' | 'CALCULATION_METHOD_EE' | 'CALCULATION_METHOD_NRB' | 'CALCULATION_METHOD_LCB' | 'CALCULATION_METHOD_CH_COMP' | 'CALCULATION_METHOD_CH_CASC' | 'CALCULATION_METHOD_NO' | 'POLLUTION_CLASS_VERY_LOW' | 'POLLUTION_CLASS_LOW' | 'POLLUTION_CLASS_NOT_LOW' | 'COMPOSITE_VARIANT_INDIVIDUAL' | 'COMPOSITE_VARIANT_CENTRALIZED' | 'AIR_QUALITY_STANDARD' | 'AIR_QUALITY_HIGH' | 'DEVICE_TYPE_COMFOBOX' | 'DEVICE_TYPE_VENTILATION';

export const PrjBuildingMediaTag = {
    UnitDistributionIndividual: 'UNIT_DISTRIBUTION_INDIVIDUAL' as PrjBuildingMediaTag,
    UnitDistributionCentralized: 'UNIT_DISTRIBUTION_CENTRALIZED' as PrjBuildingMediaTag,
    WindAreaWeak: 'WIND_AREA_WEAK' as PrjBuildingMediaTag,
    WindAreaStrong: 'WIND_AREA_STRONG' as PrjBuildingMediaTag,
    HeatInsulationHigh: 'HEAT_INSULATION_HIGH' as PrjBuildingMediaTag,
    HeatInsulationLow: 'HEAT_INSULATION_LOW' as PrjBuildingMediaTag,
    CategoryNew: 'CATEGORY_NEW' as PrjBuildingMediaTag,
    CategoryRenovation: 'CATEGORY_RENOVATION' as PrjBuildingMediaTag,
    EnergyStandardLevel1: 'ENERGY_STANDARD_LEVEL_1' as PrjBuildingMediaTag,
    EnergyStandardLevel2: 'ENERGY_STANDARD_LEVEL_2' as PrjBuildingMediaTag,
    EnergyStandardLevel3: 'ENERGY_STANDARD_LEVEL_3' as PrjBuildingMediaTag,
    EnergyStandardUnknown: 'ENERGY_STANDARD_UNKNOWN' as PrjBuildingMediaTag,
    ConstructionMethodTimberFrame: 'CONSTRUCTION_METHOD_TIMBER_FRAME' as PrjBuildingMediaTag,
    ConstructionMethodSolid: 'CONSTRUCTION_METHOD_SOLID' as PrjBuildingMediaTag,
    ConstructionMethodSuspendedCeiling: 'CONSTRUCTION_METHOD_SUSPENDED_CEILING' as PrjBuildingMediaTag,
    CalculationMethodDin: 'CALCULATION_METHOD_DIN' as PrjBuildingMediaTag,
    CalculationMethodNl: 'CALCULATION_METHOD_NL' as PrjBuildingMediaTag,
    CalculationMethodBe: 'CALCULATION_METHOD_BE' as PrjBuildingMediaTag,
    CalculationMethodFr: 'CALCULATION_METHOD_FR' as PrjBuildingMediaTag,
    CalculationMethodEe: 'CALCULATION_METHOD_EE' as PrjBuildingMediaTag,
    CalculationMethodNrb: 'CALCULATION_METHOD_NRB' as PrjBuildingMediaTag,
    CalculationMethodLcb: 'CALCULATION_METHOD_LCB' as PrjBuildingMediaTag,
    CalculationMethodChComp: 'CALCULATION_METHOD_CH_COMP' as PrjBuildingMediaTag,
    CalculationMethodChCasc: 'CALCULATION_METHOD_CH_CASC' as PrjBuildingMediaTag,
    CalculationMethodNo: 'CALCULATION_METHOD_NO' as PrjBuildingMediaTag,
    PollutionClassVeryLow: 'POLLUTION_CLASS_VERY_LOW' as PrjBuildingMediaTag,
    PollutionClassLow: 'POLLUTION_CLASS_LOW' as PrjBuildingMediaTag,
    PollutionClassNotLow: 'POLLUTION_CLASS_NOT_LOW' as PrjBuildingMediaTag,
    CompositeVariantIndividual: 'COMPOSITE_VARIANT_INDIVIDUAL' as PrjBuildingMediaTag,
    CompositeVariantCentralized: 'COMPOSITE_VARIANT_CENTRALIZED' as PrjBuildingMediaTag,
    AirQualityStandard: 'AIR_QUALITY_STANDARD' as PrjBuildingMediaTag,
    AirQualityHigh: 'AIR_QUALITY_HIGH' as PrjBuildingMediaTag,
    DeviceTypeComfobox: 'DEVICE_TYPE_COMFOBOX' as PrjBuildingMediaTag,
    DeviceTypeVentilation: 'DEVICE_TYPE_VENTILATION' as PrjBuildingMediaTag
};

