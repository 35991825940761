import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { CChipsComponent } from './c-chips.component';
import { ChipsModule } from 'primeng/chips';

@NgModule({
  imports: [CommonModule, FormsModule, ChipsModule],
  declarations: [CChipsComponent],
  exports: [CChipsComponent],
})
export class CChipsModule {}
