import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { ConfigService } from '@core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[autofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  appEnv: string;

  constructor(
    public host: ElementRef,
    public configService: ConfigService,
  ) {
    this.configService.debugConfig$.pipe(untilDestroyed(this)).subscribe((config) => (this.appEnv = config.app_env));
  }

  ngAfterViewInit() {
    if ((this.appEnv && this.appEnv.toLowerCase() === 'test') || !this.host || !this.host.nativeElement) {
      return;
    }

    // using setTimeout because of primeng-dropdown Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value for 'p-inputwrapper-focus': 'undefined'. Current value: 'true'
    setTimeout(() => {
      if (!this.host || !this.host.nativeElement) {
        return;
      }

      const children = this.host.nativeElement.querySelectorAll('[autofocus]');
      if (children.length) {
        children[0].focus();
      } else {
        this.host.nativeElement.focus();
      }
    }, 100);
  }
}
