import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Router } from '@angular/router';

import { CredentialsService } from '../credentials.service';
import { DelayedMessagesService } from '../delayed-messages.service';

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  unavailableCounter = 0;
  unavailableStatuses = [503, 504];

  constructor(
    public credentialsService: CredentialsService,
    public delayedMessagesService: DelayedMessagesService,
    public router: Router,
  ) {}

  handleUnavailable() {
    this.unavailableCounter++;
    if (this.unavailableCounter >= 3) {
      if (window.location.pathname !== '/unavailable') {
        window.location.href = '/unavailable';
      }
    }
  }

  cancelUnavailable() {
    this.unavailableCounter = 0;
    if (window.location.pathname === '/unavailable') {
      window.location.href = '/';
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => this.errorHandler(error)),
      map((response) => {
        if (response instanceof HttpResponseBase && this.unavailableStatuses.indexOf(response.status) === -1) {
          this.cancelUnavailable();
        }

        return response;
      }),
    );
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (response instanceof HttpResponseBase) {
      let message: string = null;

      if (!message && response instanceof HttpResponse) {
        if (response.body && response.body.message) {
          message = response.body.message;
        }
      }

      if (!message && response instanceof HttpErrorResponse) {
        if (response.error && response.error.message) {
          message = response.error.message;
        } else if (response.message) {
          message = response.message;
        }
      }

      if (!message) {
        message = response.statusText;
      }

      this.delayedMessagesService.newMessage$.next({ severity: 'error', summary: 'Error', detail: message });

      if (response.status === 401 && this.credentialsService.credentials$.getValue()) {
        // Forcing logout and reload when access is denied
        this.credentialsService.setCredentials();
        window.location.reload();
      }

      if (response.status === 403 || response.status === 404) {
        this.router.navigate(['/']).then();
      }

      if (this.unavailableStatuses.indexOf(response.status) !== -1) {
        this.handleUnavailable();
      } else {
        this.cancelUnavailable();
      }
    }

    throw response;
  }
}
