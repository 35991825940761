import { Component, OnInit, ViewChild } from '@angular/core';
import { RecordsService } from './records.service';
import {
  FilterField,
  FilterOperator,
  PermissionName,
  PrjProject,
  PrjProjectsListCategory,
  PrjProjectsService as ApiPrjProjectsService,
  PrjProjectType,
  SettingType,
} from '@api';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import {
  CodeNameEntity,
  ConfigService,
  CredentialsService,
  DateFilterService,
  DescriptionService,
  FormattingService,
} from '@core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-projects-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [RecordsService],
})
export class ListComponent implements OnInit {
  currentRecord: PrjProject;
  duplicateRecord: boolean;

  showModal: boolean;

  variant: boolean;
  opportunity: string;
  type: PrjProjectType;

  isLoading = 0;

  projectLockColumn: boolean;

  canUseC4c: boolean;
  canSelectProjectAreaManager: boolean;
  canLockProjects: boolean;

  filters = {};

  headerMenuItems: MenuItem[];
  rowMenuItems: MenuItem[];

  categories: CodeNameEntity[];
  category: PrjProjectsListCategory;
  types: CodeNameEntity[];

  stakeholders: { [key: number]: string } = {};

  variants: { [key: number]: PrjProject[] } = {}; // undefined (collapsed), null (loading), array (expanded)
  variantsExist = false;

  @ViewChild('recordsTable') recordsTable: Table;

  constructor(
    public recordsService: RecordsService,
    public apiPrjProjectsService: ApiPrjProjectsService,
    public translateService: TranslateService,
    public confirmationService: ConfirmationService,
    public credentialsService: CredentialsService,
    public route: ActivatedRoute,
    public router: Router,
    public configService: ConfigService,
    public descriptionService: DescriptionService,
    public formattingService: FormattingService,
  ) {
    this.initFilters();
    recordsService.state$.pipe(untilDestroyed(this)).subscribe((state) => {
      if (this.recordsTable && this.recordsTable.sortField && !state.sortColumn) {
        setTimeout(() => this.clearSort(), 100); // delaying otherwise this.recordsTable.reset() will change the page
      }
    });
    recordsService.relations = JSON.stringify([
      'area_manager_user',
      'created_by_user',
      'stakeholders',
      'last_final_pdf_document',
    ]);
    recordsService.excludeText = true;

    recordsService.records$.pipe(untilDestroyed(this)).subscribe((records) => {
      this.stakeholders = {};
      this.variants = {};
      this.variantsExist = false;
      records.forEach((record) => {
        this.stakeholders[record.id] = (record.stakeholders || [])
          .map((stakeholder) => this.descriptionService.stakeholder(stakeholder))
          .join('\n');
        if (record['variant_project_ids']) {
          record['variant_project_ids'] = String(record['variant_project_ids'])
            .split(',')
            .filter((id) => id !== String(record.id));
          if (record['variant_project_ids'].length) {
            this.variantsExist = true;
          }
        }
      });
    });

    combineLatest([this.credentialsService.credentials$, this.configService.settingsConfig$])
      .pipe(untilDestroyed(this))
      .subscribe(async ([credentials, settings]) => {
        this.projectLockColumn = settings[SettingType.ProjectLockColumn] as boolean;

        this.canUseC4c = this.credentialsService.can(PermissionName.UseC4c);
        this.canSelectProjectAreaManager = this.credentialsService.can(PermissionName.SelectProjectAreaManager);
        this.canLockProjects = this.credentialsService.can(PermissionName.LockProjects);

        if (!this.recordsService.category && (settings[SettingType.OwnProjects] as boolean)) {
          this.recordsService.category = this.recordsService.categoryBase = PrjProjectsListCategory.Own;
        }

        this.types = [
          { code: PrjProjectType.Plan, name: await this.translateService.get('ui.project_type.plan').toPromise() },
          { code: PrjProjectType.Quote, name: await this.translateService.get('ui.project_type.quote').toPromise() },
          {
            code: PrjProjectType.QuotePlus,
            name: await this.translateService.get('ui.project_type.quote_plus').toPromise(),
          },
        ];
        // this.types.sort((opt1, opt2) => opt1.code.localeCompare(opt2.code)); // this is pre-sorted

        if (credentials && credentials.user && credentials.user.blocked_project_types) {
          const blockedTypes = credentials.user.blocked_project_types.split(',') as PrjProjectType[];
          this.types = this.types.filter((type) => blockedTypes.indexOf(type.code as PrjProjectType) === -1);
        }

        // Empty value allows everything
        const availableTypes = settings[SettingType.AvailableProjectTypes] as PrjProjectType[];
        if (availableTypes) {
          this.types = this.types.filter((type) => availableTypes.indexOf(type.code as PrjProjectType) !== -1);
        }

        this.categories = this.credentialsService.can(PermissionName.AccessAllProjects)
          ? [
              {
                code: null,
                name: await this.translateService.get('ui.app.projects.list.list-component-ts.all').toPromise(),
              },
              {
                code: PrjProjectsListCategory.Own,
                name: await this.translateService.get('ui.projects_list_category.own').toPromise(),
              },
              {
                code: PrjProjectsListCategory.Internal,
                name: await this.translateService.get('ui.projects_list_category.internal').toPromise(),
              },
              {
                code: PrjProjectsListCategory.External,
                name: await this.translateService.get('ui.projects_list_category.external').toPromise(),
              },
            ]
          : [
              {
                code: null,
                name: await this.translateService.get('ui.app.projects.list.list-component-ts.all').toPromise(),
              },
              {
                code: PrjProjectsListCategory.Own,
                name: await this.translateService.get('ui.projects_list_category.own').toPromise(),
              },
            ];
        // this.categories.sort((opt1, opt2) => opt1.code.localeCompare(opt2.code)); // this is pre-sorted
      });
  }

  async ngOnInit() {
    // This is not in the constructor because it needs to be async or use subscriber
    this.headerMenuItems = [
      {
        label: await this.translateService.get('ui.app.projects.list.list-component-ts.clear-filters').toPromise(),
        icon: 'pi pi-filter-slash',
        command: () => this.clearFilters(),
      },
      {
        label: await this.translateService.get('ui.app.projects.list.list-component-ts.clear-sort-order').toPromise(),
        icon: 'pi pi-sort-alt',
        command: () => this.clearSort(),
      },
    ];

    if (this.route.snapshot && this.route.snapshot.queryParams && this.route.snapshot.queryParams.opportunity) {
      const queryParams: Params = { ...this.route.snapshot.queryParams };

      delete queryParams.opportunity;
      delete queryParams.type;
      this.router
        .navigate([], {
          relativeTo: this.route,
          queryParams,
          replaceUrl: true,
        })
        .then();

      this.opportunity = this.route.snapshot.queryParams.opportunity;
      this.type = this.route.snapshot.queryParams.type;
      this.currentRecord = { id: 0 };
      this.showModal = !!this.currentRecord;
    }
  }

  initFilters() {
    this.recordsService.queryFilters$.pipe(untilDestroyed(this)).subscribe((queryFilters) => {
      const filters: FilterField[] = queryFilters ? JSON.parse(queryFilters) || [] : [];
      const fields: string[] = [];
      filters.forEach((filter) => {
        fields.push(filter.field as string);

        if ([null, undefined, ''].indexOf(filter.value as string) === -1) {
          switch (filter.field) {
            case 'locked':
              this.filters[filter.field as string] = !!filter.value;
              break;
            case 'created_at':
            case 'updated_at':
              const value = DateFilterService.parseFilter(filter.value as string);
              this.filters[filter.field] = (this.filters[filter.field] || []).concat(
                !Array.isArray(value) ? [value] : value,
              );
              break;
            default:
              this.filters[filter.field as string] = filter.value;
          }
        }
      });

      Object.keys(this.filters).forEach((field) => {
        if (fields.indexOf(field) === -1) {
          delete this.filters[field];
        }
      });

      this.applyFilters();
    });
  }

  applyFilters(field?: string, value?: any) {
    if (field) {
      // This is needed to react in real time
      this.filters[field] = value;
    }

    const filters: FilterField[] = [];
    Object.keys(this.filters).forEach((field) => {
      let value = this.filters[field];
      if ([null, undefined, ''].indexOf(value) === -1) {
        switch (field) {
          case 'locked':
            filters.push({ field, value: value ? 1 : 0 });
            break;
          case 'type':
            filters.push({ field, value });
            break;
          case 'created_at':
          case 'updated_at':
            DateFilterService.processFilter(field, value).forEach((filter) => filters.push(filter));
            break;
          default:
            filters.push({ field, operator: FilterOperator.Contains, value });
        }
      }
    });

    this.recordsService.filters = filters.length ? JSON.stringify(filters) : null;
    // Reset page when filtering
    if (this.recordsTable) {
      this.recordsTable.first = 0;
    }
  }

  clearFilters() {
    this.filters = {};
    this.applyFilters();
  }

  clearSort() {
    if (this.recordsTable) {
      this.recordsTable.sortField = null;
      this.recordsTable.sortOrder = null;
      this.recordsTable.reset();
    }
  }

  dblClickRecord(record: PrjProject) {
    if (!record.deleted_at) {
      this.router.navigate(['/projects/' + record.id + '/details']).then();
    }
  }

  async prepareRowMenuItems(record: PrjProject) {
    this.rowMenuItems = [];

    if (!record) {
      return;
    }

    if (record.deleted_at) {
      this.rowMenuItems.push({
        label: await this.translateService.get('ui.app.projects.list.list-component-ts.restore').toPromise(),
        icon: 'pi pi-undo',
        command: () => this.restoreRecord(record),
      });

      return;
    }

    this.rowMenuItems.push({
      label: await this.translateService.get('ui.app.projects.list.list-component-ts.view').toPromise(),
      icon: 'pi pi-eye',
      routerLink: ['/projects/' + record.id + '/details'],
    });

    if (record.last_final_pdf_document && record.last_final_pdf_document.url) {
      this.rowMenuItems.push({
        label: await this.translateService.get('ui.app.projects.list.list-component-ts.last-final-pdf').toPromise(),
        icon: 'pi pi-file-pdf',
        url: record.last_final_pdf_document.url,
        target: '_blank',
      });
    }

    this.rowMenuItems.push({
      label: await this.translateService.get('ui.app.projects.list.list-component-ts.duplicate').toPromise(),
      icon: 'pi pi-clone',
      command: () => {
        this.currentRecord = record;
        this.duplicateRecord = true;
        this.showModal = !!this.currentRecord;
        this.variant = false;
      },
    });

    this.rowMenuItems.push({
      label: await this.translateService.get('ui.app.projects.list.list-component-ts.create-variant').toPromise(),
      icon: 'pi pi-copy',
      command: () => {
        this.currentRecord = record;
        this.duplicateRecord = true;
        this.showModal = !!this.currentRecord;
        this.variant = true;
      },
    });

    if (this.variantsExist && record['variant_project_ids']?.length && this.variants[record.id] !== null) {
      if (!this.variants[record.id]) {
        this.rowMenuItems.push({
          label: await this.translateService.get('ui.app.projects.list.list-component-ts.load-variants').toPromise(),
          icon: 'pi pi-plus',
          command: () => {
            this.loadVariants(record);
          },
        });
      } else {
        this.rowMenuItems.push({
          label: await this.translateService.get('ui.app.projects.list.list-component-ts.hide-variants').toPromise(),
          icon: 'pi pi-minus',
          command: () => {
            delete this.variants[record.id]; // set it to undefined
          },
        });
      }
    }

    if (this.canLockProjects) {
      if (!record.locked) {
        this.rowMenuItems.push({
          label: await this.translateService.get('ui.app.projects.list.list-component-ts.lock').toPromise(),
          icon: 'pi pi-lock',
          command: () => this.lockRecord(record),
        });
      } else {
        this.rowMenuItems.push({
          label: await this.translateService.get('ui.app.projects.list.list-component-ts.unlock').toPromise(),
          icon: 'pi pi-lock-open',
          command: () => this.unlockRecord(record),
        });
      }
    }

    this.rowMenuItems.push({
      label: await this.translateService.get('ui.app.projects.list.list-component-ts.delete').toPromise(),
      icon: 'pi pi-trash',
      command: () => this.deleteRecord(record),
    });
  }

  saveRecord(project: PrjProject) {
    this.currentRecord = null;
    this.duplicateRecord = false;
    this.showModal = !!this.currentRecord;
    this.variant = false;
    // this.recordsService.reload();
    this.router.navigate(['/projects/' + project.id + '/details'], { state: { fresh: true } }).then();
  }

  async lockRecord(record: PrjProject) {
    this.confirmationService.confirm({
      header: await this.translateService.get('ui.app.projects.list.list-component-ts.lock-project').toPromise(),
      message: await this.translateService
        .get('ui.app.projects.list.list-component-ts.are-you-sure-you-want-to-lock-project-computed-name', {
          ...record,
          computed_name: [record.project_number, record.name].filter((part) => part).join(' '),
        })
        .toPromise(),
      acceptLabel: await this.translateService.get('ui.app.projects.list.list-component-ts.ok').toPromise(),
      rejectLabel: await this.translateService.get('ui.app.projects.list.list-component-ts.cancel').toPromise(),
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-secondary',
      icon: 'pi pi-lock',
      accept: () => {
        this.isLoading++;

        this.apiPrjProjectsService
          .prjProjectLock(record.id)
          .pipe(untilDestroyed(this))
          .subscribe(
            () => {
              this.recordsService.reload();

              this.isLoading--;
            },
            () => this.isLoading--,
          );
      },
    });
  }

  async unlockRecord(record: PrjProject) {
    this.confirmationService.confirm({
      header: await this.translateService.get('ui.app.projects.list.list-component-ts.unlock-project').toPromise(),
      message: await this.translateService
        .get('ui.app.projects.list.list-component-ts.are-you-sure-you-want-to-unlock-project-computed-name', {
          ...record,
          computed_name: [record.project_number, record.name].filter((part) => part).join(' '),
        })
        .toPromise(),
      acceptLabel: await this.translateService.get('ui.app.projects.list.list-component-ts.ok').toPromise(),
      rejectLabel: await this.translateService.get('ui.app.projects.list.list-component-ts.cancel').toPromise(),
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-secondary',
      icon: 'pi pi-lock-open',
      accept: () => {
        this.isLoading++;

        this.apiPrjProjectsService
          .prjProjectUnlock(record.id)
          .pipe(untilDestroyed(this))
          .subscribe(
            () => {
              this.recordsService.reload();

              this.isLoading--;
            },
            () => this.isLoading--,
          );
      },
    });
  }

  async deleteRecord(record: PrjProject) {
    this.confirmationService.confirm({
      header: await this.translateService.get('ui.app.projects.list.list-component-ts.delete-project').toPromise(),
      message: await this.translateService
        .get('ui.app.projects.list.list-component-ts.are-you-sure-you-want-to-delete-project-project-computed-name', {
          ...record,
          computed_name: [record.project_number, record.name].filter((part) => part).join(' '),
        })
        .toPromise(),
      acceptLabel: await this.translateService.get('ui.app.projects.list.list-component-ts.ok').toPromise(),
      rejectLabel: await this.translateService.get('ui.app.projects.list.list-component-ts.cancel').toPromise(),
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isLoading++;

        this.apiPrjProjectsService
          .prjProjectDelete(record.id)
          .pipe(untilDestroyed(this))
          .subscribe(
            () => {
              this.recordsService.reload();

              this.isLoading--;
            },
            () => this.isLoading--,
          );
      },
    });
  }

  restoreRecord(record: PrjProject) {
    this.isLoading++;

    this.apiPrjProjectsService
      .prjProjectRestore(record.id)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.recordsService.reload();

          this.isLoading--;
        },
        () => this.isLoading--,
      );
  }

  loadVariants(record: PrjProject) {
    this.variants[record.id] = null;

    this.isLoading++;

    this.apiPrjProjectsService
      .prjProjectsList(
        this.recordsService.searchTerm || null,
        this.recordsService.filters,
        this.recordsService.page || null,
        0,
        this.recordsService.sortColumn || null,
        this.recordsService.sortDirection || null,
        this.recordsService.fields,
        this.recordsService.relations,
        this.recordsService.withTrashed || null,
        this.recordsService.category || null,
        this.recordsService.excludeText || null,
        true,
        record.id,
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.variants[record.id] = res.data || [];

          this.isLoading--;
        },
        () => this.isLoading--,
      );
  }

  toggleVariants(record: PrjProject) {
    if (record['variant_project_ids']?.length && this.variants[record.id] !== null) {
      if (!this.variants[record.id]) {
        this.loadVariants(record);
      } else {
        delete this.variants[record.id];
      }
    }
  }
}
