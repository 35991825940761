import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared/shared.module';

import { DemoRoutingModule } from './demo-routing.module';

import { DemoComponent } from './demo.component';

import { ConfiguratorModule } from '../configurator/configurator.module';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    DemoRoutingModule,
    ConfiguratorModule,
    ButtonModule,
    CardModule,
    SharedModule,
    DialogModule,
  ],
  declarations: [DemoComponent],
})
export class DemoModule {}
