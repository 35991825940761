<span *ngIf="!module">
  <span *ngIf="!impossible" translate>
    ui.app.ccf.configurator.module-selection.module-tile.module-tile-component-html.module-not-available
  </span>
  <span *ngIf="impossible" translate>
    ui.app.ccf.configurator.module-selection.module-tile.module-tile-component-html.module-not-possible
  </span>
</span>
<ng-container *ngIf="module">
  <img
    [attr.srcset]="media?.srcset"
    [src]="media ? media.url : 'assets/image-placeholder.png'"
    class="module-image cursor-pointer mb-3"
  />
</ng-container>
