import { FilterField, FilterOperator } from '@api';

export class DateFilterService {
  static processFilter(field: string, value: Date | Date[] | string, operator: FilterOperator = null) {
    const filters: FilterField[] = [];

    if (value instanceof Date) {
      filters.push({
        field,
        value: `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`,
        operator,
      });
    } else if (Array.isArray(value)) {
      // date range
      const valueFrom =
        value[0] instanceof Date
          ? `${value[0].getFullYear()}-${value[0].getMonth() + 1}-${value[0].getDate()}`
          : value[0];
      if (valueFrom) {
        filters.push({
          field,
          value: valueFrom,
          operator: FilterOperator.Gte,
        });
      }
      const valueTo =
        value.length > 1
          ? value[1] instanceof Date
            ? `${value[1].getFullYear()}-${value[1].getMonth() + 1}-${value[1].getDate()}`
            : value[1]
          : null;
      if (valueTo) {
        filters.push({
          field,
          value: valueTo,
          operator: FilterOperator.Lte,
        });
      }
    } else {
      filters.push({
        field,
        value,
        operator,
      });
    }

    return filters;
  }

  static parseFilter(value: string) {
    if (!value) {
      return null;
    }

    const parts = value.split('-');
    const date = new Date();
    date.setFullYear(parseInt(parts[0]));
    date.setMonth((parts.length > 1 ? parseInt(parts[1]) : 1) - 1);
    date.setDate(parts.length > 2 ? parseInt(parts[2]) : 1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }
}
