import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { RecordsList, RecordsService as BaseRecordsService } from '@core';

import { News, NewsService as ApiNewsService } from '@api';

@Injectable({ providedIn: 'root' })
export class NewsService extends BaseRecordsService<News> {
  public excludeText: boolean;
  public disabled: boolean;
  protected _queryParams = false; // this should not update the query params

  constructor(public apiNewsService: ApiNewsService) {
    super('app.shell.news.news-service-ts');
  }

  protected _search(): Observable<RecordsList<News>> {
    if (this.disabled) {
      return of({ data: [] });
    }

    const { searchTerm, filters, pageSize, page, sortColumn, sortDirection, withTrashed, fields, relations } =
      this._state;

    return this.apiNewsService.newsList(
      searchTerm || null,
      filters,
      page || null,
      pageSize,
      sortColumn || 'sort_order',
      sortDirection || null,
      fields,
      relations,
      null,
      withTrashed || null,
      null,
      this.excludeText || null,
    );
  }
}
