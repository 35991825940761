<div [hidden]="!isLoadingConfig && !isLoadingSso && !isLoadingCredentialsAuth" class="svg-loader">
  <svg class="svg-container" height="100" viewBox="0 0 100 100" width="100">
    <circle class="loader-svg bg" cx="50" cy="50" r="45"></circle>
    <circle class="loader-svg animate" cx="50" cy="50" r="45"></circle>
  </svg>
</div>

<div [hidden]="isLoadingConfig || isLoadingSso || isLoadingCredentialsAuth" class="login-container bg-light">
  <div class="login-box">
    <h1 translate>ui.app.auth.login-component-html.app-name</h1>
    <div>
      <h6 class="d-inline-block">v{{ version }}</h6>
      <div class="d-inline-block ml-3">
        <app-language-selector
          *ngIf="!isLoadingConfig && !isLoadingSso && !isLoadingCredentialsAuth"
        ></app-language-selector>
      </div>
    </div>
    <div class="container">
      <div class="card col-md-6 mt-3 mx-auto">
        <div class="card-body">
          <h4 class="card-title text-center">
            <i [hidden]="!isLoadingConfig && !isLoadingSso && !isLoadingCredentialsAuth" class="fas fa-cog fa-spin"></i>
            <i
              [hidden]="isLoadingConfig || isLoadingSso || isLoadingCredentialsAuth"
              class="far fa-3x fa-user-circle text-muted"
            ></i>
          </h4>

          <form
            (ngSubmit)="login()"
            *ngIf="loginForm && !isLoadingConfig && !isLoadingSso"
            [formGroup]="loginForm"
            novalidate
          >
            <div [hidden]="!error || isLoadingCredentialsAuth" class="alert alert-danger" translate>
              ui.app.auth.login-component-html.username-or-password-incorrect
            </div>
            <div class="form-group">
              <label class="d-block">
                <input
                  [placeholder]="'ui.app.auth.login-component-html.username' | translate"
                  autocomplete="username"
                  class="form-control"
                  formControlName="username"
                  type="text"
                />
                <span hidden translate>ui.app.auth.login-component-html.username</span>
                <small
                  [hidden]="loginForm.controls.username.valid || loginForm.controls.username.untouched"
                  class="text-danger"
                  translate
                >
                  ui.app.auth.login-component-html.username-is-required
                </small>
              </label>
              <label class="d-block">
                <input
                  [placeholder]="'ui.app.auth.login-component-html.password' | translate"
                  autocomplete="current-password"
                  class="form-control"
                  formControlName="password"
                  required
                  type="password"
                />
                <span hidden translate>ui.app.auth.login-component-html.password</span>
                <small
                  [hidden]="loginForm.controls.password.valid || loginForm.controls.password.untouched"
                  class="text-danger"
                  translate
                >
                  ui.app.auth.login-component-html.password-is-required
                </small>
              </label>
            </div>
            <button
              [disabled]="loginForm.invalid || isLoadingCredentialsAuth"
              class="btn btn-primary w-100"
              type="submit"
            >
              <i [hidden]="!isLoadingCredentialsAuth" class="fas fa-cog fa-spin"></i>
              <span translate>ui.app.auth.login-component-html.login</span>
            </button>
          </form>

          <hr *ngIf="!isLoadingConfig && loginForm && !isLoadingSso && sso && !isLoadingCredentialsAuth" />

          <button
            (click)="loginWithSso()"
            *ngIf="sso && !isLoadingConfig && !isLoadingCredentialsAuth"
            [disabled]="isLoadingSso"
            class="btn btn-primary w-100"
          >
            <i [hidden]="!isLoadingSso" class="fas fa-cog fa-spin"></i>
            <span translate>ui.app.auth.login-component-html.login-with-sso</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
