<div [ngClass]="styleClass" class="name-wrapper">
  <a href="/">
    <img
      [attr.srcset]="nameLogo?.srcset"
      [src]="nameLogo?.url || '/assets/zehnder-comfoplanplus-name.png?v=' + environment.version"
      [title]="(appName || 'ComfoPlan+') + ' ' + environment.version + ' ' + appVersion"
      alt="name"
    />
  </a>
</div>
