import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ListRoutingModule } from './list-routing.module';
import { ListComponent } from './list.component';
import { StarterModule } from '../starter/starter.module';

import { CInputNumberModule, CTableModule, CTooltipModule } from '@components';

import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { MenuModule } from 'primeng/menu';
import { ContextMenuModule } from 'primeng/contextmenu';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    ListRoutingModule,
    StarterModule,
    CardModule,
    CTableModule,
    CTooltipModule,
    ButtonModule,
    InputTextModule,
    CalendarModule,
    MenuModule,
    ContextMenuModule,
    SidebarModule,
    CheckboxModule,
    DropdownModule,
    AutoCompleteModule,
    AvatarModule,
    CInputNumberModule,
    TriStateCheckboxModule,
  ],
  declarations: [ListComponent],
})
export class ListModule {}
