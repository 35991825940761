<div
  [cTooltip]="
    'ui.app.ccf.configurator.size-2-module-top.size-2-module-top-component-html.change-module-tooltip' | translate
  "
  class="module"
>
  <div *ngIf="!startLength" class="measure-module-start">
    <svg
      class="measure-module-start-icon-left"
      id="Layer_1"
      style="enable-background: new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <g>
        <g>
          <path
            d="M508.875,248.458l-160-160c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l141.792,141.792
            H10.667C4.771,245.333,0,250.104,0,256s4.771,10.667,10.667,10.667h464.917L333.792,408.458c-4.167,4.167-4.167,10.917,0,15.083
            c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125l160-160C513.042,259.375,513.042,252.625,508.875,248.458z
            "
          />
        </g>
      </g>
    </svg>

    <p>{{ startLength }}</p>
    <svg
      class="measure-module-start-icon-right"
      id="Layer_1"
      style="enable-background: new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <g>
        <g>
          <path
            d="M508.875,248.458l-160-160c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l141.792,141.792
            H10.667C4.771,245.333,0,250.104,0,256s4.771,10.667,10.667,10.667h464.917L333.792,408.458c-4.167,4.167-4.167,10.917,0,15.083
            c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125l160-160C513.042,259.375,513.042,252.625,508.875,248.458z
            "
          />
        </g>
      </g>
    </svg>
  </div>

  <div (click)="changeModule()" [ngClass]="{ 'cursor-pointer': !readonly }" class="module-main"></div>

  <div
    (click)="deleteModule()"
    *ngIf="!readonly"
    [cTooltip]="
      'ui.app.ccf.configurator.size-2-module-top.size-2-module-top-component-html.delete-module-tooltip' | translate
    "
    class="module-delete cursor-pointer"
  >
    <svg
      class="d-print-none"
      enable-background="new 0 0 512 512"
      height="512"
      id="Layer_1"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m424 64h-88v-16c0-26.51-21.49-48-48-48h-64c-26.51 0-48 21.49-48 48v16h-88c-22.091 0-40 17.909-40 40v32c0 8.837 7.163 16 16 16h384c8.837 0 16-7.163 16-16v-32c0-22.091-17.909-40-40-40zm-216-16c0-8.82 7.18-16 16-16h64c8.82 0 16 7.18 16 16v16h-96z"
        />
        <path
          d="m78.364 184c-2.855 0-5.13 2.386-4.994 5.238l13.2 277.042c1.22 25.64 22.28 45.72 47.94 45.72h242.98c25.66 0 46.72-20.08 47.94-45.72l13.2-277.042c.136-2.852-2.139-5.238-4.994-5.238zm241.636 40c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16z"
        />
      </g>
    </svg>
  </div>

  <div *ngIf="module?.left_connections" class="connection-left-1">
    <p class="dimensions-connection-left-1">
      {{ startLength + dimensions.size2FirstSideConnection }}
    </p>
  </div>
  <div *ngIf="module?.left_connections" class="connection-left-2">
    <p class="dimensions-connection-left-2">
      {{ startLength + dimensions.size2SecondSideConnection }}
    </p>
  </div>

  <div *ngIf="module?.right_connections" class="connection-right-1">
    <p class="dimensions-connection-right-1">
      {{ startLength + dimensions.size2FirstSideConnection }}
    </p>
  </div>
  <div *ngIf="module?.right_connections" class="connection-right-2">
    <p class="dimensions-connection-right-2">
      {{ startLength + dimensions.size2SecondSideConnection }}
    </p>
  </div>

  <div class="measure-module-end">
    <p>{{ endLength }}</p>
  </div>
</div>
