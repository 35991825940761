<div class="cpp-wrapper">
  <div class="cpp-content">
    <h3 class="p-m-0" translate>ui.app.projects.list.list-component-html.projects-dashboard</h3>
    <hr />

    <div class="p-formgroup-inline">
      <div class="p-field">
        <p-dropdown
          (ngModelChange)="recordsTable.first = 0"
          [(ngModel)]="recordsService.category"
          [filter]="true"
          [optionLabel]="descriptionService.name"
          [options]="categories"
          [placeholder]="'ui.app.projects.list.list-component-html.all' | translate"
          [resetFilterOnHide]="true"
          appendTo="body"
          class="w-100"
          optionValue="code"
        ></p-dropdown>
      </div>
      <div class="p-field">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            (ngModelChange)="recordsTable.first = 0"
            [(ngModel)]="recordsService.searchTerm"
            [placeholder]="'ui.app.projects.list.list-component-html.search' | translate"
            pInputText
            type="search"
          />
        </span>
      </div>
      <div class="p-field-checkbox p-pt-2">
        <p-checkbox
          (ngModelChange)="recordsTable.first = 0"
          [(ngModel)]="recordsService.withTrashed"
          [binary]="true"
          inputId="with_trashed"
        ></p-checkbox>
        <label for="with_trashed" translate>ui.app.projects.list.list-component-html.with-trashed</label>
      </div>
      <p-button
        (onClick)="recordsService.reload()"
        [label]="'ui.app.projects.list.list-component-html.reload' | translate"
        icon="pi pi-refresh"
      ></p-button>
      <div style="flex: 1"></div>
      <div class="p-field-checkbox p-ml-3 p-pt-2">
        <!-- Clear filters and sorting as the visible columns change -->
        <p-checkbox
          (ngModelChange)="recordsTable.first = 0"
          [(ngModel)]="recordsService.includeVariants"
          [binary]="true"
          inputId="include_variants"
        ></p-checkbox>
        <label for="include_variants" translate>ui.app.projects.list.list-component-html.include-variants</label>
      </div>
    </div>

    <p-card>
      <p-table
        #recordsTable
        (contextMenuSelectionChange)="prepareRowMenuItems($event).then()"
        (onLazyLoad)="recordsService.lazyLoadEvent = $event"
        [contextMenu]="rowContextMenu"
        [currentPageReportTemplate]="
          'ui.app.projects.list.list-component-html.showing-first-to-last-of-total-records-entries' | translate
        "
        [filterDelay]="0"
        [first]="recordsService.first"
        [lazy]="true"
        [loading]="isLoading || (recordsService.loading$ | async)"
        [paginator]="true"
        [resizableColumns]="true"
        [rowHover]="true"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        [rows]="recordsService.pageSize"
        [showCurrentPageReport]="true"
        [sortField]="recordsService.sortColumn"
        [sortOrder]="recordsService.sortOrder"
        [totalRecords]="recordsService.total$ | async"
        [value]="recordsService.records$ | async"
        columnResizeMode="fit"
        dataKey="id"
        stateKeyColumnWidths="app.projects.list.list-component-html"
        stateStorage="local"
        stickyTableHeader
        styleClass="p-datatable-striped p-datatable-gridlines p-datatable-sm"
      >
        <ng-template pTemplate="header">
          <tr>
            <th *ngIf="variantsExist" cResizableColumn style="width: 3rem">
              <!-- For variants expansion/collapsing -->
              &nbsp;
            </th>
            <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="project_number_with_ext">
              <span translate>ui.app.projects.list.list-component-html.project-number</span>
              <p-sortIcon field="project_number_with_ext"></p-sortIcon>
            </th>
            <ng-container *ngIf="canUseC4c">
              <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="opportunity_id">
                <span translate>ui.app.projects.list.list-component-html.opportunity</span>
                <p-sortIcon field="opportunity_id"></p-sortIcon>
              </th>
            </ng-container>
            <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="name">
              <span translate>ui.app.projects.list.list-component-html.name</span>
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="street">
              <span translate>ui.app.projects.list.list-component-html.street</span>
              <p-sortIcon field="street"></p-sortIcon>
            </th>
            <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="zip_code">
              <span translate>ui.app.projects.list.list-component-html.zip-code</span>
              <p-sortIcon field="zip_code"></p-sortIcon>
            </th>
            <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="city">
              <span translate>ui.app.projects.list.list-component-html.city</span>
              <p-sortIcon field="city"></p-sortIcon>
            </th>
            <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="stakeholders">
              <span translate>ui.app.projects.list.list-component-html.stakeholders</span>
              <p-sortIcon field="stakeholders"></p-sortIcon>
            </th>
            <ng-container *ngIf="canSelectProjectAreaManager">
              <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="area_manager_user">
                <span translate>ui.app.projects.list.list-component-html.area-manager</span>
                <p-sortIcon field="area_manager_user"></p-sortIcon>
              </th>
            </ng-container>
            <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="created_by_user">
              <span translate>ui.app.projects.list.list-component-html.created-by</span>
              <p-sortIcon field="created_by_user"></p-sortIcon>
            </th>
            <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="created_at">
              <span translate>ui.app.projects.list.list-component-html.created-on</span>
              <p-sortIcon field="created_at"></p-sortIcon>
            </th>
            <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="updated_at">
              <span translate>ui.app.projects.list.list-component-html.updated-on</span>
              <p-sortIcon field="updated_at"></p-sortIcon>
            </th>
            <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="type">
              <span translate>ui.app.projects.list.list-component-html.type</span>
              <p-sortIcon field="type"></p-sortIcon>
            </th>
            <th *ngIf="projectLockColumn" cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="locked">
              <span translate>ui.app.projects.list.list-component-html.locked</span>
              <p-sortIcon field="locked"></p-sortIcon>
            </th>
            <th cResizableColumn class="p-text-right text-ellipsis" style="width: 3rem" translate>
              ui.app.projects.list.list-component-html.actions
            </th>
          </tr>

          <tr>
            <th *ngIf="variantsExist">
              <!-- For variants expansion/collapsing -->
              &nbsp;
            </th>
            <th>
              <div class="w-100 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  (ngModelChange)="applyFilters('project_number_with_ext', $event)"
                  [(ngModel)]="filters['project_number_with_ext']"
                  class="w-100"
                  pInputText
                  type="search"
                />
              </div>
            </th>
            <ng-container *ngIf="canUseC4c">
              <th>
                <div class="w-100 p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input
                    (ngModelChange)="applyFilters('opportunity', $event)"
                    [(ngModel)]="filters['opportunity']"
                    class="w-100"
                    pInputText
                    type="search"
                  />
                </div>
              </th>
            </ng-container>
            <th>
              <div class="w-100 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  (ngModelChange)="applyFilters('name', $event)"
                  [(ngModel)]="filters['name']"
                  class="w-100"
                  pInputText
                  type="search"
                />
              </div>
            </th>
            <th>
              <div class="w-100 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  (ngModelChange)="applyFilters('street', $event)"
                  [(ngModel)]="filters['street']"
                  class="w-100"
                  pInputText
                  type="search"
                />
              </div>
            </th>
            <th>
              <div class="w-100 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  (ngModelChange)="applyFilters('zip_code', $event)"
                  [(ngModel)]="filters['zip_code']"
                  class="w-100"
                  pInputText
                  type="search"
                />
              </div>
            </th>
            <th>
              <div class="w-100 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  (ngModelChange)="applyFilters('city', $event)"
                  [(ngModel)]="filters['city']"
                  class="w-100"
                  pInputText
                  type="search"
                />
              </div>
            </th>
            <th>
              <div class="w-100 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  (ngModelChange)="applyFilters('stakeholders.inverted_name', $event)"
                  [(ngModel)]="filters['stakeholders.inverted_name']"
                  class="w-100"
                  pInputText
                  type="search"
                />
              </div>
            </th>
            <ng-container *ngIf="canSelectProjectAreaManager">
              <th>
                <div class="w-100 p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input
                    (ngModelChange)="applyFilters('area_manager_user.inverted_name', $event)"
                    [(ngModel)]="filters['area_manager_user.inverted_name']"
                    class="w-100"
                    pInputText
                    type="search"
                  />
                </div>
              </th>
            </ng-container>
            <th>
              <div class="w-100 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  (ngModelChange)="applyFilters('created_by_user.inverted_name', $event)"
                  [(ngModel)]="filters['created_by_user.inverted_name']"
                  class="w-100"
                  pInputText
                  type="search"
                />
              </div>
            </th>
            <th>
              <p-calendar
                (ngModelChange)="applyFilters('created_at', $event)"
                [(ngModel)]="filters['created_at']"
                [dateFormat]="formattingService.dateFormatCalendar"
                [monthNavigator]="true"
                [showButtonBar]="true"
                [showIcon]="true"
                [yearNavigator]="true"
                [yearRange]="formattingService.yearRange"
                appendTo="body"
                selectionMode="range"
                styleClass="w-100"
              ></p-calendar>
            </th>
            <th>
              <p-calendar
                (ngModelChange)="applyFilters('updated_at', $event)"
                [(ngModel)]="filters['updated_at']"
                [dateFormat]="formattingService.dateFormatCalendar"
                [monthNavigator]="true"
                [showButtonBar]="true"
                [showIcon]="true"
                [yearNavigator]="true"
                [yearRange]="formattingService.yearRange"
                appendTo="body"
                selectionMode="range"
                styleClass="w-100"
              ></p-calendar>
            </th>
            <th>
              <p-dropdown
                (ngModelChange)="applyFilters('type', $event)"
                [(ngModel)]="filters['type']"
                [filter]="true"
                [optionLabel]="descriptionService.name"
                [options]="types"
                [resetFilterOnHide]="true"
                [showClear]="true"
                appendTo="body"
                class="w-100"
                optionValue="code"
                placeholder="&nbsp;"
              ></p-dropdown>
            </th>
            <th *ngIf="projectLockColumn" class="p-text-center">
              <p-triStateCheckbox
                (ngModelChange)="applyFilters('locked', $event)"
                [(ngModel)]="filters['locked']"
              ></p-triStateCheckbox>
            </th>
            <th class="p-text-right text-ellipsis">
              <i (click)="headerMenu.toggle($event)" class="pi pi-ellipsis-v w-100 cursor-pointer"></i>
            </th>
          </tr>
        </ng-template>

        <ng-template let-record pTemplate="body">
          <ng-template #recordsTableRow let-record let-indent="indent">
            <tr
              (dblclick)="dblClickRecord(record)"
              [ngClass]="{ trashed: record.deleted_at }"
              [pContextMenuRow]="record"
              class="cursor-pointer"
            >
              <td (click)="toggleVariants(record)" *ngIf="variantsExist" class="p-text-center">
                <!-- For variants expansion/collapsing -->
                <ng-container *ngIf="record['variant_project_ids']?.length">
                  <ng-container *ngIf="variants[record.id] !== null">
                    <i *ngIf="!variants[record.id]" class="pi pi-plus cursor-pointer"></i>
                    <i *ngIf="variants[record.id]" class="pi pi-minus cursor-pointer"></i>
                  </ng-container>
                  <i *ngIf="variants[record.id] === null" class="pi pi-spin pi-spinner"></i>
                </ng-container>
              </td>
              <td class="p-text-left text-ellipsis">
                <ng-container *ngIf="indent">&nbsp;&nbsp;&nbsp;&nbsp;</ng-container>

                <ng-container *ngIf="!projectLockColumn && record.locked">
                  <i
                    [cTooltip]="'ui.app.projects.list.list-component-html.this-project-is-currently-locked' | translate"
                    class="pi pi-lock"
                  ></i>
                </ng-container>
                <ng-container *ngIf="record.last_final_pdf_document?.url">
                  <a
                    [cTooltip]="record.last_final_pdf_document?.name"
                    [cTooltipBlockClick]="false"
                    [href]="record.last_final_pdf_document?.url"
                    target="_blank"
                  >
                    <i class="pi pi-file-pdf"></i>
                  </a>
                </ng-container>
                {{ record.project_number }}
                <ng-container *ngIf="record.project_number_ext">- {{ record.project_number_ext }}</ng-container>
              </td>
              <ng-container *ngIf="canUseC4c">
                <td class="p-text-left text-ellipsis">
                  {{ record.opportunity_id }}
                  <ng-container *ngIf="record.opportunity_id && record.opportunity_name">-</ng-container>
                  {{ record.opportunity_name }}
                </td>
              </ng-container>
              <td class="p-text-left text-ellipsis">{{ record.name }}</td>
              <td class="p-text-left text-ellipsis">{{ record.street }}</td>
              <td class="p-text-left text-ellipsis">{{ record.zip_code }}</td>
              <td class="p-text-left text-ellipsis">{{ record.city }}</td>
              <td
                [ngClass]="{
                  'text-ellipsis': record.stakeholders?.length <= 1,
                  'text-ellipsis-no-title': record.stakeholders?.length > 1
                }"
                class="p-text-left"
              >
                <ng-container *ngIf="record.stakeholders?.length > 1">
                  <span [attr.title]="record.stakeholders?.length > 1 ? stakeholders[record.id] : ''" translate>
                    ui.app.projects.list.list-component-html.several-stakeholders
                  </span>
                  <i [cTooltip]="stakeholders[record.id]" class="pi pi-info-circle"></i>
                </ng-container>
                <ng-container *ngIf="record.stakeholders?.length === 1">
                  {{ record.stakeholders[0] | description: 'stakeholder' }}
                </ng-container>
              </td>
              <ng-container *ngIf="canSelectProjectAreaManager">
                <td class="p-text-left text-ellipsis">
                  <div class="p-d-flex p-ai-center">
                    <div class="p-mr-1">
                      <p-avatar
                        *ngIf="record.area_manager_user?.profile_picture_url"
                        [image]="record.area_manager_user?.profile_picture_url"
                        class="float-left"
                        shape="circle"
                      ></p-avatar>
                    </div>
                    <div class="p-pb-2 text-ellipsis">
                      {{ record.area_manager_user | description: 'areaManagerUser' }}
                    </div>
                  </div>
                </td>
              </ng-container>
              <td class="p-text-left text-ellipsis">
                <div class="p-d-flex p-ai-center">
                  <div class="p-mr-1">
                    <p-avatar
                      *ngIf="record.created_by_user?.profile_picture_url"
                      [image]="record.created_by_user?.profile_picture_url"
                      class="float-left"
                      shape="circle"
                    ></p-avatar>
                  </div>
                  <div class="p-pb-2 text-ellipsis">
                    {{ record.created_by_user | description: 'user' }}
                  </div>
                </div>
              </td>
              <td class="p-text-left text-ellipsis">{{ record.created_at | longDateLocale }}</td>
              <td class="p-text-left text-ellipsis">{{ record.updated_at | longDateLocale }}</td>
              <td class="p-text-left text-ellipsis">
                {{ record.type | description: 'name' : types }}
                <ng-container *ngIf="record.project_number_cpd">
                  {{ 'ui.app.projects.list.list-component-html.cpd' | translate }}
                </ng-container>
              </td>
              <td *ngIf="projectLockColumn" class="p-text-center text-ellipsis">
                <i *ngIf="record.locked" class="pi pi-lock"></i>
              </td>
              <td class="p-text-right text-ellipsis">
                <i
                  (click)="prepareRowMenuItems(record).then(); rowMenu.toggle($event)"
                  class="pi pi-ellipsis-v w-100 cursor-pointer"
                ></i>
              </td>
            </tr>
          </ng-template>

          <ng-container *ngTemplateOutlet="recordsTableRow; context: { $implicit: record }"></ng-container>

          <ng-container *ngFor="let variant of variants[record.id]">
            <ng-container
              *ngTemplateOutlet="recordsTableRow; context: { $implicit: variant, indent: true }"
            ></ng-container>
          </ng-container>
        </ng-template>
      </p-table>

      <p-menu #headerMenu [model]="headerMenuItems" [popup]="true" appendTo="body"></p-menu>

      <p-menu #rowMenu [model]="rowMenuItems" [popup]="true" appendTo="body"></p-menu>

      <p-contextMenu #rowContextMenu [model]="rowMenuItems" appendTo="body"></p-contextMenu>
    </p-card>
  </div>

  <div class="p-mt-2 p-text-right cpp-footer">
    <app-name class="p-mr-auto" styleClass="p-mr-3"></app-name>

    <p-button
      (onClick)="currentRecord = { id: 0 }; duplicateRecord = false; showModal = !!currentRecord; variant = false"
      [label]="'ui.app.projects.list.list-component-html.new-project' | translate"
      icon="pi pi-plus"
      styleClass="p-ml-1"
    ></p-button>
  </div>
</div>

<p-sidebar
  [(visible)]="showModal"
  [blockScroll]="true"
  [closeOnEscape]="false"
  [dismissible]="false"
  [showCloseIcon]="false"
  position="right"
  styleClass="p-sidebar-md sidebar-hide-header-and-footer sidebar-content-h-100"
>
  <app-projects-starter
    (cancelled)="currentRecord = null; duplicateRecord = false; showModal = !!currentRecord; variant = false"
    (saved)="saveRecord($event)"
    [duplicateRecordId]="duplicateRecord ? currentRecord?.id : null"
    [opportunity]="opportunity"
    [recordId]="!duplicateRecord ? currentRecord?.id : null"
    [type]="type"
    [variant]="variant"
  ></app-projects-starter>
</p-sidebar>
