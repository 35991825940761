/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PdfAdditionalPdfType = 'after_cover' | 'after_letter' | 'after_materials' | 'after_uploads' | 'after_closing';

export const PdfAdditionalPdfType = {
    Cover: 'after_cover' as PdfAdditionalPdfType,
    Letter: 'after_letter' as PdfAdditionalPdfType,
    Materials: 'after_materials' as PdfAdditionalPdfType,
    Uploads: 'after_uploads' as PdfAdditionalPdfType,
    Closing: 'after_closing' as PdfAdditionalPdfType
};

