import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  header = true;

  constructor(public route: ActivatedRoute) {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data && data.header !== undefined) {
        this.header = data.header as boolean;
      }
    });
  }
  ngOnInit() {}
}
