import { Component, EventEmitter, Output } from '@angular/core';
import { CcfConfiguration, CcfPartEndModuleAdapter, CcfPartEndModuleType, SettingType } from '@api';
import { BlankModuleSideComponent } from '../blank-module-side/blank-module-side.component';
import { ConfigService } from '@core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-ccf-configurator-front-module-side',
  templateUrl: './front-module-side.component.html',
  styleUrls: ['./front-module-side.component.scss'],
})
export class FrontModuleSideComponent extends BlankModuleSideComponent {
  @Output() changedAdapter: EventEmitter<CcfConfiguration> = new EventEmitter<CcfConfiguration>();

  ccf125: boolean;

  constructor(public configService: ConfigService) {
    super();

    this.configService.settingsConfig$.pipe(untilDestroyed(this)).subscribe(async (settings) => {
      this.ccf125 = !!settings[SettingType.Ccf125];
    });
  }

  get CcfPartEndModuleType() {
    return CcfPartEndModuleType;
  }

  get CcfPartEndModuleAdapter() {
    return CcfPartEndModuleAdapter;
  }

  changeAdapter() {
    if (this.readonly) {
      return;
    }

    if (this.ccf125) {
      this.changeModule();
      return;
    }

    this.changedAdapter.emit(this.configuration);
  }
}
