<div [ngClass]="{ compact: compact }">
  <div
    #menubar
    [attr.data-pc-name]="'menubar'"
    [attr.data-pc-section]="'root'"
    [class]="styleClass"
    [ngClass]="{ 'p-menubar p-component': true, 'p-menubar-mobile-active': mobileActive }"
    [ngStyle]="style"
  >
    <div #menubarStart *ngIf="startTemplate" class="p-menubar-start">
      <ng-container *ngTemplateOutlet="startTemplate"></ng-container>
    </div>
    <a
      #menubutton
      (click)="menuButtonClick($event)"
      (keydown)="menuButtonKeydown($event)"
      *ngIf="model && model.length > 0"
      [attr.aria-controls]="id"
      [attr.aria-expanded]="mobileActive"
      [attr.aria-haspopup]="model.length && model.length > 0 ? true : false"
      [attr.aria-label]="config.translation.aria.navigation"
      [attr.data-pc-section]="'button'"
      class="p-menubar-button"
      role="button"
      tabindex="0"
    >
      <BarsIcon *ngIf="!menuIconTemplate" />
      <ng-template *ngTemplateOutlet="menuIconTemplate"></ng-template>
    </a>
    <div #content>
      <c-menubarSub
        #rootmenu
        (itemClick)="onItemClick($event)"
        (itemMouseEnter)="onItemMouseEnter($event)"
        (menuBlur)="onMenuBlur($event)"
        (menuFocus)="onMenuFocus($event)"
        (menuKeydown)="onKeyDown($event)"
        [activeItemPath]="activeItemPath()"
        [ariaLabel]="ariaLabel"
        [ariaLabelledBy]="ariaLabelledBy"
        [autoDisplay]="autoDisplay"
        [autoZIndex]="autoZIndex"
        [baseZIndex]="baseZIndex"
        [focusedItemId]="focused ? focusedItemId : undefined"
        [itemTemplate]="itemTemplate"
        [items]="processedItems"
        [menuId]="id"
        [mobileActive]="mobileActive"
        [root]="true"
      ></c-menubarSub>
    </div>
    <div #menubarEnd *ngIf="endTemplate; else legacy" class="p-menubar-end">
      <ng-container *ngTemplateOutlet="endTemplate"></ng-container>
    </div>
    <ng-template #legacy>
      <div class="p-menubar-end">
        <ng-content></ng-content>
      </div>
    </ng-template>
  </div>
</div>
