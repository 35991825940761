import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableModule } from 'primeng/table';

import { CCellEditor, CEditableColumnDirective } from './c-editable-column.directive';
import { CResizableColumnDirective } from './c-resizable-column.directive';
import { StateKeyColumnWidthsDirective } from './state-key-column-widths.directive';
import { StickyTableHeaderDirective } from './sticky-table-header.directive';
import { CRowWithEditableColumnsDirective } from './c-row-with-editable-columns.directive';

@NgModule({
  imports: [CommonModule, TableModule],
  declarations: [
    CEditableColumnDirective,
    CRowWithEditableColumnsDirective,
    CResizableColumnDirective,
    StateKeyColumnWidthsDirective,
    StickyTableHeaderDirective,
    CCellEditor,
  ],
  exports: [
    CEditableColumnDirective,
    CRowWithEditableColumnsDirective,
    CResizableColumnDirective,
    StateKeyColumnWidthsDirective,
    StickyTableHeaderDirective,
    CCellEditor,
    TableModule,
  ],
})
export class CTableModule {}
