import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  C4cOpportunity,
  C4cProject,
  C4cService,
  ErrorValidationResponse,
  PermissionName,
  PrjProject,
  PrjProjectInput,
  PrjProjectsService,
  PrjProjectType,
  SettingType,
  User,
  UserMbu,
  UsersService,
} from '@api';
import {
  CodeNameEntity,
  ConfigService,
  CredentialsService,
  DescriptionService,
  FormattingService,
  TitleService,
  UnloaderService,
} from '@core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { combineLatest, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NavigationService } from '@shared/navigation/navigation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-projects-starter',
  templateUrl: './starter.component.html',
  styleUrls: ['./starter.component.scss'],
})
export class StarterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() recordId: number;
  @Input() duplicateRecordId: number;
  @Input() variant: boolean;
  @Input() opportunity: string;
  @Input() type: PrjProjectType;

  @Output() saved: EventEmitter<PrjProject> = new EventEmitter<PrjProject>();
  @Output() cancelled: EventEmitter<any> = new EventEmitter<any>();

  projectAccessedAt = new Date();

  isLoading: number;
  isSaving: number;
  recordInput: PrjProjectInput;
  originalRecordInput: string;
  validation: ErrorValidationResponse;

  canUseC4c: boolean;
  canSelectProjectAreaManager: boolean;

  c4cProjectsQuery$: Subject<string>;
  c4cProjects: C4cProject[];
  c4cProjectSelection: C4cProject;

  defaultAreaManagerUserSelection: User;

  areaManagerSuggestions: User[];
  areaManagerUserSelection: User;
  isLoadingAreaManager: number;

  checkChanges: Function;

  defaultType: CodeNameEntity;
  types: CodeNameEntity[];
  isLoadingOpportunities: number;
  c4cOpportunities: C4cOpportunity[];

  buildingsCount: number;

  constructor(
    public apiPrjProjectsService: PrjProjectsService,
    public apiUsersService: UsersService,
    public apiC4cService: C4cService,
    public unloaderService: UnloaderService,
    public translateService: TranslateService,
    public confirmationService: ConfirmationService,
    public titleService: TitleService,
    public credentialsService: CredentialsService,
    public configService: ConfigService,
    public descriptionService: DescriptionService,
    public formattingService: FormattingService,
    public navigationService: NavigationService,
  ) {
    this.reset();

    this.checkChanges = () => {
      return this.originalRecordInput !== JSON.stringify(this.recordInput);
    };
    this.unloaderService.register(this.checkChanges);

    let defaultAreaManagerUserId: number = null; // this prevents reloading on the second triggering of the credentials

    combineLatest([this.credentialsService.credentials$, this.configService.settingsConfig$])
      .pipe(untilDestroyed(this))
      .subscribe(([credentials, settings]) => {
        this.canUseC4c = this.credentialsService.can(PermissionName.UseC4c);
        this.canSelectProjectAreaManager = this.credentialsService.can(PermissionName.SelectProjectAreaManager);

        (async () => {
          let types = [
            { code: PrjProjectType.Plan, name: await this.translateService.get('ui.project_type.plan').toPromise() },
            { code: PrjProjectType.Quote, name: await this.translateService.get('ui.project_type.quote').toPromise() },
            {
              code: PrjProjectType.QuotePlus,
              name: await this.translateService.get('ui.project_type.quote_plus').toPromise(),
            },
          ];
          // types.sort((opt1, opt2) => opt1.code.localeCompare(opt2.code)); // this is pre-sorted

          if (credentials && credentials.user && credentials.user.blocked_project_types) {
            const blockedTypes = credentials.user.blocked_project_types.split(',') as PrjProjectType[];
            types = types.filter((type) => blockedTypes.indexOf(type.code) === -1);
          }

          // Empty value allows everything
          const availableTypes = settings[SettingType.AvailableProjectTypes] as PrjProjectType[];
          if (availableTypes) {
            types = types.filter((type) => availableTypes.indexOf(type.code) !== -1);
          }

          const defaultType = settings[SettingType.DefaultProjectType] as PrjProjectType;
          if (defaultType) {
            this.defaultType = types.find((type) => type.code === defaultType);
          }

          this.types = types;
        })().then();

        if (!this.recordId && !defaultAreaManagerUserId) {
          const mbu =
            credentials && credentials.user && credentials.user.mbus
              ? credentials.user.mbus.find((mbu) => mbu.is_current)
              : null;
          if (mbu) {
            const pivot: UserMbu = mbu.pivot;
            if (pivot) {
              defaultAreaManagerUserId = pivot.area_manager_user_id;

              this.isLoadingAreaManager++;
              this.apiUsersService
                .usersList(
                  null,
                  JSON.stringify([{ field: 'id', value: defaultAreaManagerUserId }]),
                  0,
                  1,
                  'area_number',
                  'asc',
                  JSON.stringify([
                    'id',
                    'inverted_name',
                    'profile_picture_url',
                    'profile_picture_srcset',
                    'area_number',
                  ]),
                  null,
                  null,
                  null,
                  null,
                  true,
                  true,
                  true, // exclude_text
                )
                .pipe(untilDestroyed(this))
                .subscribe(
                  (res) => {
                    this.defaultAreaManagerUserSelection = res.data ? res.data[0] : null;

                    this.isLoadingAreaManager--;
                  },
                  () => this.isLoadingAreaManager--,
                );
            }
          }
        }
      });
  }

  get PrjProjectType() {
    return PrjProjectType;
  }

  reset() {
    this.projectAccessedAt = new Date();
    this.isLoading = 0;
    this.isSaving = 0;
    this.recordInput = {
      variant: this.variant,
      type: this.defaultType ? (this.defaultType.code as PrjProjectType) : null,
      original_project_id: this.duplicateRecordId,
      area_manager_user_id: null, // forces server side validation
    };
    this.originalRecordInput = JSON.stringify(this.recordInput);
    this.validation = {};
    this.c4cProjectsQuery$ = new Subject<string>();
    this.c4cProjects = [];
    this.c4cProjectSelection = null;
    this.areaManagerUserSelection = null;
    this.isLoadingAreaManager = 0;
    this.isLoadingOpportunities = 0;
    this.c4cOpportunities = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.recordId || changes.duplicateRecordId) {
      this.reset();
      this.ngOnInit().then();
    }
  }

  async ngOnInit() {
    // this.recordId = 0 will pass for project creation
    if ([null, undefined].indexOf(this.recordId) !== -1 && [null, undefined].indexOf(this.duplicateRecordId) !== -1) {
      // Component is hidden in stand by
      return;
    }

    this.titleService.push();

    this.c4cProjectsQuery$
      .pipe(
        untilDestroyed(this),
        switchMap((query) => this.apiC4cService.c4cProjectsList(query, 10)),
      )
      .subscribe((res) => (this.c4cProjects = res.data));

    if (!this.areaManagerSuggestions) {
      // Only load once
      this.areaManagerSuggestions = [];
      this.isLoading++;
      this.apiUsersService
        .usersList(
          null,
          null,
          null,
          0,
          'area_number',
          'asc',
          JSON.stringify(['id', 'inverted_name', 'profile_picture_url', 'profile_picture_srcset', 'area_number']),
          null,
          null,
          null,
          null,
          true,
          true,
          true, // exclude_text
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          (res) => {
            this.areaManagerSuggestions = res.data;

            this.isLoading--;
          },
          () => this.isLoading--,
        );
    }

    if (this.recordId || this.duplicateRecordId) {
      this.isLoading++;
      this.apiPrjProjectsService
        .prjProjectDetails(
          this.recordId || this.duplicateRecordId,
          null,
          JSON.stringify(['area_manager_user', 'accessed_by_user']),
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          true,
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          async (res) => {
            if (!this.recordId) {
              this.titleService.replace(
                await this.translateService.get('ui.app.projects.starter.starter-component-ts.new-project').toPromise(),
              );

              res.data.id = null;
            } else {
              this.titleService.replace(
                await this.translateService
                  .get('ui.app.projects.starter.starter-component-ts.edit-starter')
                  .toPromise(),
              );
            }

            this.processRecord(res.data);

            this.isLoading--;
          },
          () => this.isLoading--,
        );
    } else {
      this.titleService.replace(
        await this.translateService.get('ui.app.projects.starter.starter-component-ts.new-project').toPromise(),
      );

      if (this.defaultAreaManagerUserSelection) {
        this.areaManagerUserSelection = this.defaultAreaManagerUserSelection;
        this.areaManagerUserSelectionChanged(this.areaManagerUserSelection);
      }

      // This stays in ngOnInit to run after reset
      if (this.canUseC4c && this.opportunity) {
        this.isLoading++;
        this.apiC4cService
          .c4cOpportunityDetails(this.opportunity)
          .pipe(untilDestroyed(this))
          .subscribe(
            (res) => {
              if (res.data) {
                if (res.data.project) {
                  this.c4cProjectSelection = res.data.project;
                  this.c4cProjectSelectionChanged(this.c4cProjectSelection, true);
                }

                this.c4cOpportunities = res.data.opportunities || [];

                this.c4cOpportunitySelectionChanged(res.data.id);
              }
              this.isLoading--;
            },
            () => this.isLoading--,
          );
        this.opportunity = null; // prevent this from running again
      }

      if (this.type) {
        this.recordInput.type = this.type;
        this.type = null; // prevent this from running again
      }
    }
  }

  processRecord(record?: PrjProject) {
    if (record) {
      if (record.customer_number) {
        this.c4cProjectSelection = {
          number: record.customer_number,
          name: record.customer_name,
          vtweg: record.vtweg,
          spart: record.spart,
        };
      }

      if (record.opportunity_object_id) {
        this.isLoading++;
        this.apiC4cService
          .c4cOpportunityDetails(record.opportunity_object_id)
          .pipe(untilDestroyed(this))
          .subscribe(
            (res) => {
              if (res.data) {
                if (res.data.project) {
                  this.c4cProjectSelection = res.data.project;
                  this.c4cProjectSelectionChanged(this.c4cProjectSelection, true, true);
                }

                this.c4cOpportunities = res.data.opportunities || [];

                if (record.opportunity_id) {
                  this.c4cOpportunitySelectionChanged(record.opportunity_id);
                }

                this.originalRecordInput = JSON.stringify(this.recordInput);
              } else {
                // trigger the search of opportunities
                this.c4cProjectSelectionChanged(this.c4cProjectSelection, false, true);
              }

              this.isLoading--;
            },
            () => this.isLoading--,
          );
      } else {
        // trigger the search of opportunities
        this.c4cProjectSelectionChanged(this.c4cProjectSelection, false, true);
      }

      this.recordInput.type = record.type;

      this.projectAccessedAt = new Date(record.updated_at || record.created_at || new Date());

      if (record.area_manager_user) {
        this.areaManagerUserSelection = record.area_manager_user;
        this.areaManagerUserSelectionChanged(this.areaManagerUserSelection);
      }

      this.buildingsCount = record.buildings_count;
    }

    this.originalRecordInput = JSON.stringify(this.recordInput);
  }

  c4cProjectSelectionChanged(c4cProject: C4cProject, skipOpportunities = false, skipAreaManager = false) {
    this.resetErrorsAndWarnings('c4c_project');

    this.recordInput.customer_number = c4cProject && typeof c4cProject === 'object' ? c4cProject.number : null;
    this.recordInput.customer_name = c4cProject && typeof c4cProject === 'object' ? c4cProject.name : null;
    this.recordInput.vtweg = c4cProject && typeof c4cProject === 'object' ? c4cProject.vtweg : null;
    this.recordInput.spart = c4cProject && typeof c4cProject === 'object' ? c4cProject.spart : null;

    if (!skipOpportunities) {
      this.c4cOpportunities = [];
    }

    if (c4cProject && typeof c4cProject === 'object') {
      if (!skipAreaManager && c4cProject.area) {
        this.isLoadingAreaManager++;
        this.apiUsersService
          .usersList(
            null,
            null,
            0,
            1,
            'area_number',
            'asc',
            JSON.stringify(['id', 'inverted_name', 'profile_picture_url', 'profile_picture_srcset', 'area_number']),
            null,
            null,
            null,
            null,
            true,
            true,
            true, // exclude_text
          )
          .pipe(untilDestroyed(this))
          .subscribe(
            (res) => {
              this.areaManagerUserSelection = res.data ? res.data[0] : null;
              this.areaManagerUserSelectionChanged(this.areaManagerUserSelection);

              this.isLoadingAreaManager--;
            },
            () => this.isLoadingAreaManager--,
          );
      }

      if (c4cProject && typeof c4cProject === 'object' && !skipOpportunities) {
        this.isLoadingOpportunities++;
        this.apiC4cService
          .c4cOpportunitiesList(c4cProject.number, c4cProject.ktokd)
          .pipe(untilDestroyed(this))
          .subscribe(
            (res) => {
              this.c4cOpportunities = res.data;

              this.isLoadingOpportunities--;
            },
            () => this.isLoadingOpportunities--,
          );
      }
    } else {
      this.c4cOpportunitySelectionChanged();
    }
  }

  c4cOpportunitySelectionChanged(event?: string) {
    this.resetErrorsAndWarnings('opportunity');

    const c4cOpportunity = this.c4cOpportunities.find((c4cOpportunity) => c4cOpportunity.id === event);

    this.recordInput.opportunity_id = c4cOpportunity ? c4cOpportunity.id : null;
    this.recordInput.opportunity_name = c4cOpportunity ? c4cOpportunity.name : null;
    this.recordInput.opportunity_object_id = c4cOpportunity ? c4cOpportunity.object_id : null;
  }

  areaManagerUserSelectionChanged(user: User) {
    this.resetErrorsAndWarnings('area_manager_user_id');

    this.recordInput.area_manager_user_id = user ? user.id : null;
  }

  c4cOpportunityRenderer(c4cOpportunity: C4cOpportunity) {
    const parts = [];
    if (c4cOpportunity.id) {
      parts.push(c4cOpportunity.id);
    }
    if (c4cOpportunity.name) {
      parts.push(c4cOpportunity.name);
    }
    return parts.join(' - ');
  }

  resetErrorsAndWarnings(key: string, deep = false) {
    if (this.validation) {
      if (this.validation.errors) {
        delete this.validation.errors[key];

        if (deep) {
          Object.keys(this.validation.errors).forEach((keyPotential) => {
            if (keyPotential.indexOf(key) === 0) {
              delete this.validation.errors[keyPotential];
            }
          });
        }
      }

      if (this.validation.warnings) {
        delete this.validation.warnings[key];

        if (deep) {
          Object.keys(this.validation.warnings).forEach((keyPotential) => {
            if (keyPotential.indexOf(key) === 0) {
              delete this.validation.warnings[keyPotential];
            }
          });
        }
      }

      if (!this.validation.errors || !Object.keys(this.validation.errors).length) {
        this.validation.message = '';
      }
    }
  }

  save() {
    if (this.isSaving) {
      return;
    }

    const relations = JSON.stringify(['area_manager_user', 'created_by_user', 'updated_by_user', 'accessed_by_user']);
    this.isSaving++;
    const saver = this.recordId
      ? this.apiPrjProjectsService.prjProjectUpdate(
          this.recordId,
          {
            ...this.recordInput,
            project_accessed_at: this.projectAccessedAt ? this.projectAccessedAt.toISOString() : null,
          },
          null,
          relations,
        )
      : this.apiPrjProjectsService.prjProjectCreate(this.recordInput, null, relations);
    saver.pipe(untilDestroyed(this)).subscribe(
      (res) => {
        this.projectAccessedAt = new Date((res.data ? res.data.updated_at || res.data.created_at : null) || new Date());

        this.validation = {};

        this.isSaving--;

        this.originalRecordInput = JSON.stringify(this.recordInput);

        this.navigationService.projectChanged$.next(this.recordId);

        this.saved.emit(res.data);
        this.titleService.pop();

        // If something prevented the process from completing, convert this to an edit, if started with created
        /*
        if (!this.recordId && res.data && res.data.id) {
          this.recordId = res.data.id;
        }
        */
      },
      (res) => {
        this.validation = this.formattingService.explodeValidationMessages(res && res.error ? res.error : {});

        // Collapse all errors for C4C project data in a virtual c4c_project field
        ['customer_number', 'customer_name', 'vtweg', 'spart'].forEach((key) => {
          if (this.validation) {
            if (this.validation.errors && this.validation.errors[key]) {
              if (!this.validation.errors['c4c_project']) {
                this.validation.errors['c4c_project'] = [];
              }
              this.validation.errors['c4c_project'] = this.validation.errors['c4c_project'].concat(
                this.validation.errors[key],
              );
              delete this.validation.errors[key];
            }
            if (this.validation.warnings && this.validation.warnings[key]) {
              if (!this.validation.warnings['c4c_project']) {
                this.validation.warnings['c4c_project'] = [];
              }
              this.validation.warnings['c4c_project'] = this.validation.warnings['c4c_project'].concat(
                this.validation.warnings[key],
              );
              delete this.validation.warnings[key];
            }
          }
        });

        // Collapse all errors for C4C opportunity data in opportunity field
        ['opportunity_id', 'opportunity_name', 'opportunity_object_id'].forEach((key) => {
          if (this.validation) {
            if (this.validation.errors && this.validation.errors[key]) {
              if (!this.validation.errors['opportunity']) {
                this.validation.errors['opportunity'] = [];
              }
              this.validation.errors['opportunity'] = this.validation.errors['opportunity'].concat(
                this.validation.errors[key],
              );
              delete this.validation.errors[key];
            }
            if (this.validation.warnings && this.validation.warnings[key]) {
              if (!this.validation.warnings['opportunity']) {
                this.validation.warnings['opportunity'] = [];
              }
              this.validation.warnings['opportunity'] = this.validation.warnings['opportunity'].concat(
                this.validation.warnings[key],
              );
              delete this.validation.warnings[key];
            }
          }
        });

        this.isSaving--;
      },
    );
  }

  async cancel() {
    if (this.checkChanges && this.checkChanges()) {
      this.confirmationService.confirm({
        header: await this.translateService.get('ui.app.projects.starter.starter-component-ts.cancel').toPromise(),
        message: await this.translateService
          .get('ui.app.projects.starter.starter-component-ts.are-you-sure-you-want-to-cancel-you-have-unsaved-changes')
          .toPromise(),
        acceptLabel: await this.translateService.get('ui.app.projects.starter.starter-component-ts.ok').toPromise(),
        rejectLabel: await this.translateService.get('ui.app.projects.starter.starter-component-ts.cancel').toPromise(),
        acceptButtonStyleClass: 'p-button-warning',
        rejectButtonStyleClass: 'p-button-secondary',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.cancelled.emit();
          this.titleService.pop();
        },
      });

      return;
    }

    this.cancelled.emit();
    this.titleService.pop();
  }

  ngOnDestroy(): void {
    this.unloaderService.unregister(this.checkChanges);
  }
}
