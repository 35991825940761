<ng-container>
  <div class="cpp-wrapper">
    <div class="cpp-content">
      <h3 class="p-m-0" translate>ui.app.ccf.demo.demo-component-html.ccf-demo</h3>
      <hr />

      <p-card>
        <app-ccf-configurator (computed)="configuration = $event"></app-ccf-configurator>
      </p-card>
    </div>

    <div class="p-mt-2 p-text-right cpp-footer">
      <app-name class="p-mr-auto" styleClass="p-mr-3"></app-name>

      <p-button
        (onClick)="showSapInfoModal = true"
        *ngIf="canViewSapNumbers && (configuration?.article_number || configuration?.code)"
        [label]="'ui.app.ccf.demo.demo-component-html.show-sap-info' | translate"
        icon="pi pi-list"
        iconPos="left"
        styleClass="p-button-secondary p-ml-1"
      ></p-button>

      <p-button
        (onClick)="print()"
        [label]="'ui.app.ccf.demo.demo-component-html.print' | translate"
        icon="pi pi-print"
        iconPos="left"
        styleClass="p-button-secondary p-ml-1"
      ></p-button>
    </div>
  </div>
</ng-container>

<p-dialog
  [(visible)]="showSapInfoModal"
  [closeOnEscape]="true"
  [dismissableMask]="false"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="header">
    <h4 class="text-ellipsis" translate>ui.app.ccf.demo.demo-component-html.sap-info</h4>
  </ng-template>

  <ng-template pTemplate="content">
    <h5 class="border-0">
      <span>{{ configuration.article_number }}</span>
    </h5>
    <h5 class="border-0">
      <span>{{ configuration.code }}</span>
    </h5>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button
      (onClick)="showSapInfoModal = null"
      [label]="'ui.app.ccf.demo.demo-component-html.ok' | translate"
      icon="pi pi-check"
    ></p-button>
  </ng-template>
</p-dialog>
