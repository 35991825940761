/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArtAccessoryGroupType = 'qa' | 'implicit' | 'alternative' | 'option';

export const ArtAccessoryGroupType = {
    Qa: 'qa' as ArtAccessoryGroupType,
    Implicit: 'implicit' as ArtAccessoryGroupType,
    Alternative: 'alternative' as ArtAccessoryGroupType,
    Option: 'option' as ArtAccessoryGroupType
};

