/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NumberFormat = 'comma_decimals' | 'dot_decimals';

export const NumberFormat = {
    CommaDecimals: 'comma_decimals' as NumberFormat,
    DotDecimals: 'dot_decimals' as NumberFormat
};

