import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CMenubarComponent, CMenubarSubComponent } from './c-menubar.component';

import { MenubarModule } from 'primeng/menubar';
import { BarsIcon } from 'primeng/icons/bars';
import { AngleDownIcon } from 'primeng/icons/angledown';
import { AngleRightIcon } from 'primeng/icons/angleright';

@NgModule({
  imports: [CommonModule, MenubarModule, BarsIcon, AngleDownIcon, AngleRightIcon],
  declarations: [CMenubarComponent, CMenubarSubComponent],
  exports: [CMenubarComponent],
})
export class CMenubarModule {}
