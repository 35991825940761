/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjDocumentStatus = 'pending' | 'printing' | 'ready' | 'failed';

export const PrjDocumentStatus = {
    Pending: 'pending' as PrjDocumentStatus,
    Printing: 'printing' as PrjDocumentStatus,
    Ready: 'ready' as PrjDocumentStatus,
    Failed: 'failed' as PrjDocumentStatus
};

