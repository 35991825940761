/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ArtOutletArticleType } from '../model/models';
import { ArtOutletRuleInstallationType } from '../model/models';
import { ErrorForbidden } from '../model/models';
import { ErrorNotFound } from '../model/models';
import { ErrorUnauthorized } from '../model/models';
import { ErrorValidationResponse } from '../model/models';
import { InlineResponse200103 } from '../model/models';
import { InlineResponse200104 } from '../model/models';
import { InlineResponse20095 } from '../model/models';
import { PrjBuildingCalculationMethod } from '../model/models';
import { PrjBuildingConstructionMethod } from '../model/models';
import { PrjOutletsInput } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PrjOutletsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Outlet details
     * Outlet details
     * @param id ID of outlet
     * @param apartmentId ID of apartment
     * @param zone Zone
     * @param fields Request only a subset of the fields
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjOutletDetails(id?: number, apartmentId?: number, zone?: number, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20095>;
    public prjOutletDetails(id?: number, apartmentId?: number, zone?: number, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20095>>;
    public prjOutletDetails(id?: number, apartmentId?: number, zone?: number, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20095>>;
    public prjOutletDetails(id?: number, apartmentId?: number, zone?: number, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (apartmentId !== undefined && apartmentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>apartmentId, 'apartment_id');
        }
        if (zone !== undefined && zone !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>zone, 'zone');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20095>(`${this.configuration.basePath}/api/prj/outlets`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Outlet save
     * Outlet save
     * @param prjOutletsInput Pass outlet details
     * @param forceReset Force reset
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjOutletSave(prjOutletsInput: PrjOutletsInput, forceReset?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20095>;
    public prjOutletSave(prjOutletsInput: PrjOutletsInput, forceReset?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20095>>;
    public prjOutletSave(prjOutletsInput: PrjOutletsInput, forceReset?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20095>>;
    public prjOutletSave(prjOutletsInput: PrjOutletsInput, forceReset?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (prjOutletsInput === null || prjOutletsInput === undefined) {
            throw new Error('Required parameter prjOutletsInput was null or undefined when calling prjOutletSave.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (forceReset !== undefined && forceReset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>forceReset, 'force_reset');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20095>(`${this.configuration.basePath}/api/prj/outlets`,
            prjOutletsInput,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Outlets basics
     * Outlets basics
     * @param priceDate 
     * @param buildingCalculationMethod 
     * @param buildingConstructionMethod 
     * @param deviceGroupCode 
     * @param fields Request only a subset of the fields
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjOutletsBasics(priceDate?: string, buildingCalculationMethod?: PrjBuildingCalculationMethod, buildingConstructionMethod?: PrjBuildingConstructionMethod, deviceGroupCode?: string, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse200103>;
    public prjOutletsBasics(priceDate?: string, buildingCalculationMethod?: PrjBuildingCalculationMethod, buildingConstructionMethod?: PrjBuildingConstructionMethod, deviceGroupCode?: string, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse200103>>;
    public prjOutletsBasics(priceDate?: string, buildingCalculationMethod?: PrjBuildingCalculationMethod, buildingConstructionMethod?: PrjBuildingConstructionMethod, deviceGroupCode?: string, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse200103>>;
    public prjOutletsBasics(priceDate?: string, buildingCalculationMethod?: PrjBuildingCalculationMethod, buildingConstructionMethod?: PrjBuildingConstructionMethod, deviceGroupCode?: string, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (priceDate !== undefined && priceDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>priceDate, 'price_date');
        }
        if (buildingCalculationMethod !== undefined && buildingCalculationMethod !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>buildingCalculationMethod, 'building_calculation_method');
        }
        if (buildingConstructionMethod !== undefined && buildingConstructionMethod !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>buildingConstructionMethod, 'building_construction_method');
        }
        if (deviceGroupCode !== undefined && deviceGroupCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deviceGroupCode, 'device_group_code');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse200103>(`${this.configuration.basePath}/api/prj/outlets/basics`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Outlet rules
     * Outlet rules
     * @param priceDate 
     * @param buildingCalculationMethod 
     * @param buildingConstructionMethod 
     * @param distributionCode 
     * @param tubeTypeCode 
     * @param outletType 
     * @param installationType 
     * @param articleNumber 
     * @param fields Request only a subset of the fields
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjOutletsRules(priceDate?: string, buildingCalculationMethod?: PrjBuildingCalculationMethod, buildingConstructionMethod?: PrjBuildingConstructionMethod, distributionCode?: string, tubeTypeCode?: string, outletType?: ArtOutletArticleType, installationType?: ArtOutletRuleInstallationType, articleNumber?: string, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse200104>;
    public prjOutletsRules(priceDate?: string, buildingCalculationMethod?: PrjBuildingCalculationMethod, buildingConstructionMethod?: PrjBuildingConstructionMethod, distributionCode?: string, tubeTypeCode?: string, outletType?: ArtOutletArticleType, installationType?: ArtOutletRuleInstallationType, articleNumber?: string, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse200104>>;
    public prjOutletsRules(priceDate?: string, buildingCalculationMethod?: PrjBuildingCalculationMethod, buildingConstructionMethod?: PrjBuildingConstructionMethod, distributionCode?: string, tubeTypeCode?: string, outletType?: ArtOutletArticleType, installationType?: ArtOutletRuleInstallationType, articleNumber?: string, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse200104>>;
    public prjOutletsRules(priceDate?: string, buildingCalculationMethod?: PrjBuildingCalculationMethod, buildingConstructionMethod?: PrjBuildingConstructionMethod, distributionCode?: string, tubeTypeCode?: string, outletType?: ArtOutletArticleType, installationType?: ArtOutletRuleInstallationType, articleNumber?: string, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (priceDate !== undefined && priceDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>priceDate, 'price_date');
        }
        if (buildingCalculationMethod !== undefined && buildingCalculationMethod !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>buildingCalculationMethod, 'building_calculation_method');
        }
        if (buildingConstructionMethod !== undefined && buildingConstructionMethod !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>buildingConstructionMethod, 'building_construction_method');
        }
        if (distributionCode !== undefined && distributionCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>distributionCode, 'distribution_code');
        }
        if (tubeTypeCode !== undefined && tubeTypeCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tubeTypeCode, 'tube_type_code');
        }
        if (outletType !== undefined && outletType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>outletType, 'outlet_type');
        }
        if (installationType !== undefined && installationType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>installationType, 'installation_type');
        }
        if (articleNumber !== undefined && articleNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>articleNumber, 'article_number');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse200104>(`${this.configuration.basePath}/api/prj/outlets/rules`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
