/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjStakeholderType = 'end_customer' | 'wholesaler' | 'installer' | 'architect' | 'engineer' | 'contractor' | 'inspector' | 'prefab' | 'other';

export const PrjStakeholderType = {
    EndCustomer: 'end_customer' as PrjStakeholderType,
    Wholesaler: 'wholesaler' as PrjStakeholderType,
    Installer: 'installer' as PrjStakeholderType,
    Architect: 'architect' as PrjStakeholderType,
    Engineer: 'engineer' as PrjStakeholderType,
    Contractor: 'contractor' as PrjStakeholderType,
    Inspector: 'inspector' as PrjStakeholderType,
    Prefab: 'prefab' as PrjStakeholderType,
    Other: 'other' as PrjStakeholderType
};

