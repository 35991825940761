/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArtInstallationDefaultLocation = 'manifold' | 'apartment' | 'building' | 'outside';

export const ArtInstallationDefaultLocation = {
    Manifold: 'manifold' as ArtInstallationDefaultLocation,
    Apartment: 'apartment' as ArtInstallationDefaultLocation,
    Building: 'building' as ArtInstallationDefaultLocation,
    Outside: 'outside' as ArtInstallationDefaultLocation
};

