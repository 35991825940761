import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CcfConfiguration, CcfPart } from '@api';
import { environment } from '@env/environment';

@Component({
  selector: 'app-ccf-configurator-blank-module-side',
  templateUrl: './blank-module-side.component.html',
  styleUrls: ['./blank-module-side.component.scss'],
})
export class BlankModuleSideComponent implements OnInit {
  @Input() readonly: boolean;

  @Input() configuration: CcfConfiguration;
  @Input() module: CcfPart;
  @Input() label: string;
  @Input() tooltip: string;

  @Output() changedModule: EventEmitter<CcfPart> = new EventEmitter<CcfPart>();

  public dimensions = environment.ccfDimensions;

  public startLength = 0;
  public endLength = 0;

  ngOnInit(): void {
    if (this.module && this.configuration && this.configuration.modules) {
      let index = this.configuration.modules.indexOf(this.module);
      for (let i = 0; i < index; i++) {
        const module = this.configuration.modules[i];
        if (module) {
          this.startLength += module.size ? module.size * this.dimensions.unitLength : 0;
        }
      }
      this.endLength = this.startLength + (this.module.size ? this.module.size * this.dimensions.unitLength : 0);
    }
  }

  changeModule() {
    if (this.readonly) {
      return;
    }

    this.changedModule.emit(this.module);
  }
}
