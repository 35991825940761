import { Injectable } from '@angular/core';
import { CcfConfiguration, CcfPart, CcfService as ApiCcfService } from '@api';

@Injectable({ providedIn: 'root' })
export class ConfiguratorService {
  // all configurator components on the screen share the same parts and configuration presets

  parts: CcfPart[];
  partsPromise: Promise<CcfPart[]>;
  configurationPresets: CcfConfiguration[];
  configurationPresetsPromise: Promise<CcfConfiguration[]>;

  constructor(public apiCcfService: ApiCcfService) {}

  async loadParts(): Promise<CcfPart[]> {
    if (!this.partsPromise) {
      this.partsPromise = this.apiCcfService
        .ccfPartsList()
        .toPromise()
        .then((res) => res.data);
    }
    return this.partsPromise;
  }

  async loadConfigurationPresets(
    priceDate?: string,
    withoutSideConnectionAdapters?: boolean,
  ): Promise<CcfConfiguration[]> {
    if (!this.configurationPresetsPromise) {
      this.configurationPresetsPromise = this.apiCcfService
        .ccfConfigurationPresetsPublicList(
          priceDate,
          withoutSideConnectionAdapters ? true : null, // using null to avoid "false" positives in query string parameter
        )
        .toPromise()
        .then((res) => res.data);
    }
    return this.configurationPresetsPromise;
  }

  reset() {
    this.parts = null;
    this.partsPromise = null;
    this.configurationPresets = null;
    this.configurationPresetsPromise = null;
  }
}
