/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjDocumentType = 'final_pdf' | 'c4c_pdf' | 'preview_pdf' | 'gaeb' | 'prices' | 'purchase_order';

export const PrjDocumentType = {
    FinalPdf: 'final_pdf' as PrjDocumentType,
    C4cPdf: 'c4c_pdf' as PrjDocumentType,
    PreviewPdf: 'preview_pdf' as PrjDocumentType,
    Gaeb: 'gaeb' as PrjDocumentType,
    Prices: 'prices' as PrjDocumentType,
    PurchaseOrder: 'purchase_order' as PrjDocumentType
};

