/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjProjectUploadsMediaCollection = 'project_upload_pdfs' | 'project_upload_attachments';

export const PrjProjectUploadsMediaCollection = {
    Pdfs: 'project_upload_pdfs' as PrjProjectUploadsMediaCollection,
    Attachments: 'project_upload_attachments' as PrjProjectUploadsMediaCollection
};

