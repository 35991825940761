import { Component } from '@angular/core';
import { CcfPartEndModuleAdapter } from '@api';
import { FrontModuleSideComponent } from '../front-module-side/front-module-side.component';

@Component({
  selector: 'app-ccf-configurator-front-module-top',
  templateUrl: './front-module-top.component.html',
  styleUrls: ['./front-module-top.component.scss'],
})
export class FrontModuleTopComponent extends FrontModuleSideComponent {
  get CcfPartEndModuleAdapter() {
    return CcfPartEndModuleAdapter;
  }
}
