import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { DebugService as ApiDebugService } from '@api';
import { LoggerService } from './logger.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const log = new LoggerService('DebugService');

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DebugService {
  constructor(
    public configService: ConfigService,
    public apiDebugService: ApiDebugService,
  ) {}

  loadDebugbar() {
    return new Promise<boolean>((resolve, reject) => {
      // BehaviorSubject does not resolve toPromise() https://github.com/Reactive-Extensions/RxJS/issues/1088
      this.configService.debugConfig$.pipe(untilDestroyed(this)).subscribe(
        (config) => {
          if (config) {
            if (config.debugbar) {
              this.apiDebugService
                .debugDebugbar()
                .pipe(untilDestroyed(this))
                .subscribe(
                  (res) => {
                    if (!res.data || !res.data.head || !res.data.widget) {
                      return;
                    }

                    let frag = document.createRange().createContextualFragment(res.data.head);
                    document.head.appendChild(frag);

                    let waitCounts = 100;

                    const waitForHeadBeforeRunningWidget = setInterval(() => {
                      waitCounts--;
                      if (waitCounts <= 0) {
                        clearInterval(waitForHeadBeforeRunningWidget);
                        reject('Timeout');
                        return;
                      }

                      if (window['PhpDebugBar']) {
                        clearInterval(waitForHeadBeforeRunningWidget);
                        frag = document.createRange().createContextualFragment(res.data.widget);
                        document.body.appendChild(frag);

                        // Load the config request that was not intercepted
                        if (
                          window['phpdebugbar'] &&
                          window['phpdebugbar']['loadDataSet'] &&
                          window['phpdebugbar']['ajaxHandler'] &&
                          window['phpdebugbar']['ajaxHandler']['headerName']
                        ) {
                          const id = this.configService.debugResponse.headers.get(
                            window['phpdebugbar']['ajaxHandler']['headerName'] + '-id',
                          );
                          if (id) {
                            window['phpdebugbar']['loadDataSet'](
                              id,
                              '(ajax)',
                              undefined,
                              window['phpdebugbar']['ajaxHandler']['autoShow'],
                            );
                          }
                        }
                        resolve(true);
                      }

                      log.debug('Waiting for PhpDebugBar');
                    }, 100);
                  },
                  (err) => reject(err),
                );
            } else {
              resolve(false);
            }
          }
        },
        (err) => reject(err),
      );
    });
  }
}
