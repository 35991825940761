/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ArtInstallationDefaultInput } from '../model/models';
import { ArtInstallationDefaultsList } from '../model/models';
import { ErrorConflict } from '../model/models';
import { ErrorForbidden } from '../model/models';
import { ErrorNotFound } from '../model/models';
import { ErrorUnauthorized } from '../model/models';
import { ErrorValidationResponse } from '../model/models';
import { InlineResponse2001 } from '../model/models';
import { InlineResponse20022 } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ArtInstallationDefaultsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Installation default create
     * Installation default create
     * @param artInstallationDefaultInput Pass installation default details
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param translations Request translations
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public artInstallationDefaultCreate(artInstallationDefaultInput: ArtInstallationDefaultInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20022>;
    public artInstallationDefaultCreate(artInstallationDefaultInput: ArtInstallationDefaultInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20022>>;
    public artInstallationDefaultCreate(artInstallationDefaultInput: ArtInstallationDefaultInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20022>>;
    public artInstallationDefaultCreate(artInstallationDefaultInput: ArtInstallationDefaultInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (artInstallationDefaultInput === null || artInstallationDefaultInput === undefined) {
            throw new Error('Required parameter artInstallationDefaultInput was null or undefined when calling artInstallationDefaultCreate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (translations !== undefined && translations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>translations, 'translations');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20022>(`${this.configuration.basePath}/api/art/installation_defaults`,
            artInstallationDefaultInput,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Installation default delete
     * Installation default delete
     * @param installationDefaultId ID of installation default
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public artInstallationDefaultDelete(installationDefaultId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public artInstallationDefaultDelete(installationDefaultId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public artInstallationDefaultDelete(installationDefaultId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public artInstallationDefaultDelete(installationDefaultId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (installationDefaultId === null || installationDefaultId === undefined) {
            throw new Error('Required parameter installationDefaultId was null or undefined when calling artInstallationDefaultDelete.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/art/installation_defaults/${encodeURIComponent(String(installationDefaultId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Installation default dependencies
     * Installation default dependencies
     * @param installationDefaultId ID of installation default
     * @param recursive Recursive
     * @param flat Flat
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public artInstallationDefaultDependencies(installationDefaultId: number, recursive?: boolean, flat?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse2001>;
    public artInstallationDefaultDependencies(installationDefaultId: number, recursive?: boolean, flat?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse2001>>;
    public artInstallationDefaultDependencies(installationDefaultId: number, recursive?: boolean, flat?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse2001>>;
    public artInstallationDefaultDependencies(installationDefaultId: number, recursive?: boolean, flat?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (installationDefaultId === null || installationDefaultId === undefined) {
            throw new Error('Required parameter installationDefaultId was null or undefined when calling artInstallationDefaultDependencies.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (recursive !== undefined && recursive !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>recursive, 'recursive');
        }
        if (flat !== undefined && flat !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>flat, 'flat');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse2001>(`${this.configuration.basePath}/api/art/installation_defaults/${encodeURIComponent(String(installationDefaultId))}/dependencies`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Installation default details
     * Installation default details
     * @param installationDefaultId ID of installation default
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param translations Request translations
     * @param withTrashed With trashed
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public artInstallationDefaultDetails(installationDefaultId: number, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20022>;
    public artInstallationDefaultDetails(installationDefaultId: number, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20022>>;
    public artInstallationDefaultDetails(installationDefaultId: number, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20022>>;
    public artInstallationDefaultDetails(installationDefaultId: number, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (installationDefaultId === null || installationDefaultId === undefined) {
            throw new Error('Required parameter installationDefaultId was null or undefined when calling artInstallationDefaultDetails.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (translations !== undefined && translations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>translations, 'translations');
        }
        if (withTrashed !== undefined && withTrashed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>withTrashed, 'with_trashed');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20022>(`${this.configuration.basePath}/api/art/installation_defaults/${encodeURIComponent(String(installationDefaultId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Installation default restore
     * Installation default restore
     * @param installationDefaultId ID of installation default
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param translations Request translations
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public artInstallationDefaultRestore(installationDefaultId: number, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20022>;
    public artInstallationDefaultRestore(installationDefaultId: number, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20022>>;
    public artInstallationDefaultRestore(installationDefaultId: number, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20022>>;
    public artInstallationDefaultRestore(installationDefaultId: number, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (installationDefaultId === null || installationDefaultId === undefined) {
            throw new Error('Required parameter installationDefaultId was null or undefined when calling artInstallationDefaultRestore.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (translations !== undefined && translations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>translations, 'translations');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<InlineResponse20022>(`${this.configuration.basePath}/api/art/installation_defaults/${encodeURIComponent(String(installationDefaultId))}/restore`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Installation default update
     * Installation default update
     * @param installationDefaultId ID of installation default
     * @param artInstallationDefaultInput Pass installation default details
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param translations Request translations
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public artInstallationDefaultUpdate(installationDefaultId: number, artInstallationDefaultInput: ArtInstallationDefaultInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20022>;
    public artInstallationDefaultUpdate(installationDefaultId: number, artInstallationDefaultInput: ArtInstallationDefaultInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20022>>;
    public artInstallationDefaultUpdate(installationDefaultId: number, artInstallationDefaultInput: ArtInstallationDefaultInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20022>>;
    public artInstallationDefaultUpdate(installationDefaultId: number, artInstallationDefaultInput: ArtInstallationDefaultInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (installationDefaultId === null || installationDefaultId === undefined) {
            throw new Error('Required parameter installationDefaultId was null or undefined when calling artInstallationDefaultUpdate.');
        }
        if (artInstallationDefaultInput === null || artInstallationDefaultInput === undefined) {
            throw new Error('Required parameter artInstallationDefaultInput was null or undefined when calling artInstallationDefaultUpdate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (translations !== undefined && translations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>translations, 'translations');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<InlineResponse20022>(`${this.configuration.basePath}/api/art/installation_defaults/${encodeURIComponent(String(installationDefaultId))}`,
            artInstallationDefaultInput,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Installation defaults list
     * Installation defaults list
     * @param search Search string
     * @param filters Filters
     * @param page Page number; use 0 to skip page counting
     * @param perPage Rows per page; use 0 for no pagination
     * @param orderBy Order field
     * @param orderDir Order direction
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param translations Request translations
     * @param withTrashed With trashed
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public artInstallationDefaultsList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ArtInstallationDefaultsList>;
    public artInstallationDefaultsList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ArtInstallationDefaultsList>>;
    public artInstallationDefaultsList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ArtInstallationDefaultsList>>;
    public artInstallationDefaultsList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (search !== undefined && search !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>search, 'search');
        }
        if (filters !== undefined && filters !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filters, 'filters');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>perPage, 'per_page');
        }
        if (orderBy !== undefined && orderBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderBy, 'order_by');
        }
        if (orderDir !== undefined && orderDir !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderDir, 'order_dir');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (translations !== undefined && translations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>translations, 'translations');
        }
        if (withTrashed !== undefined && withTrashed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>withTrashed, 'with_trashed');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ArtInstallationDefaultsList>(`${this.configuration.basePath}/api/art/installation_defaults`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
