/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RoomVariantCodeConversion = 'livingroom_kitchen' | 'kitchen' | 'bathroom' | 'toilet' | 'laundry' | 'bedroom' | 'master_bedroom' | 'dressing' | 'office' | 'living' | 'storage' | 'hall' | 'stairway' | 'sauna';

export const RoomVariantCodeConversion = {
    LivingroomKitchen: 'livingroom_kitchen' as RoomVariantCodeConversion,
    Kitchen: 'kitchen' as RoomVariantCodeConversion,
    Bathroom: 'bathroom' as RoomVariantCodeConversion,
    Toilet: 'toilet' as RoomVariantCodeConversion,
    Laundry: 'laundry' as RoomVariantCodeConversion,
    Bedroom: 'bedroom' as RoomVariantCodeConversion,
    MasterBedroom: 'master_bedroom' as RoomVariantCodeConversion,
    Dressing: 'dressing' as RoomVariantCodeConversion,
    Office: 'office' as RoomVariantCodeConversion,
    Living: 'living' as RoomVariantCodeConversion,
    Storage: 'storage' as RoomVariantCodeConversion,
    Hall: 'hall' as RoomVariantCodeConversion,
    Stairway: 'stairway' as RoomVariantCodeConversion,
    Sauna: 'sauna' as RoomVariantCodeConversion
};

