import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { CCarouselSelectComponent } from './c-carousel-select.component';

import { CTooltipModule } from '../c-tooltip/c-tooltip.module';

import { CInputNumberModule } from '../c-input-number/c-input-number.module';

import { CCarouselModule } from '../c-carousel/c-carousel.module';

import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  imports: [CommonModule, FormsModule, CTooltipModule, CInputNumberModule, DropdownModule, CCarouselModule],
  declarations: [CCarouselSelectComponent],
  exports: [CCarouselSelectComponent],
})
export class CCarouselSelectModule {}
