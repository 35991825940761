import { Component } from '@angular/core';

import { ConfigService, DescriptionService, I18nService } from '@core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Locale } from '@api';

@UntilDestroy()
@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  locales: Locale[] = [];

  constructor(
    public i18nService: I18nService,
    public configService: ConfigService,
    public descriptionService: DescriptionService,
  ) {
    this.configService.translationsConfig$
      .pipe(untilDestroyed(this))
      .subscribe((config) => (this.locales = (config.locales || []).filter((locale) => locale.is_available)));
  }

  get locale(): string {
    return this.i18nService.locale;
  }

  set locale(locale: string) {
    this.i18nService.locale = locale;
  }
}
