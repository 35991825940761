<div
  [cTooltip]="
    'ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.change-module-tooltip' | translate
  "
  class="module"
>
  <div *ngIf="!startLength" class="measure-module-start">
    <p>{{ startLength }}</p>
  </div>

  <div (click)="changeModule()" [ngClass]="{ 'cursor-pointer': !readonly }" class="module-main"></div>

  <div
    (click)="deleteModule()"
    *ngIf="!readonly"
    [cTooltip]="
      'ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.delete-module-tooltip' | translate
    "
    class="module-delete cursor-pointer"
  >
    <svg
      class="d-print-none"
      enable-background="new 0 0 512 512"
      height="512"
      id="Layer_1"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m424 64h-88v-16c0-26.51-21.49-48-48-48h-64c-26.51 0-48 21.49-48 48v16h-88c-22.091 0-40 17.909-40 40v32c0 8.837 7.163 16 16 16h384c8.837 0 16-7.163 16-16v-32c0-22.091-17.909-40-40-40zm-216-16c0-8.82 7.18-16 16-16h64c8.82 0 16 7.18 16 16v16h-96z"
        />
        <path
          d="m78.364 184c-2.855 0-5.13 2.386-4.994 5.238l13.2 277.042c1.22 25.64 22.28 45.72 47.94 45.72h242.98c25.66 0 46.72-20.08 47.94-45.72l13.2-277.042c.136-2.852-2.139-5.238-4.994-5.238zm241.636 40c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16z"
        />
      </g>
    </svg>
  </div>

  <ng-container *ngIf="module.first_main_connection">
    <div
      (click)="openMaintenanceModal('first')"
      *ngIf="!module.first_main_connection_type"
      [ngClass]="{ 'cursor-pointer': !readonly }"
      class="connection-blank-1 text-white blink"
      translate
    >
      ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.select
    </div>

    <div
      (click)="openMaintenanceModal('first')"
      *ngIf="module.first_main_connection_type === CcfPartMainConnectionType.Air"
      [ngClass]="{ 'cursor-pointer': !readonly }"
      class="connection-shaft-1"
    >
      <div translate>ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.a</div>
      <span class="mx-1">/</span>
      <div>{{ dimensions.mainConnection }}</div>
    </div>

    <div
      (click)="openMaintenanceModal('first')"
      *ngIf="module.first_main_connection_type === CcfPartMainConnectionType.Maintenance"
      [ngClass]="{ 'cursor-pointer': !readonly }"
      class="connection-maintenance-1"
    >
      <div translate>ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.r</div>
      <span class="mx-1">/</span>
      <div>{{ dimensions.mainConnection }}</div>
    </div>

    <div
      (click)="openMaintenanceModal('first')"
      *ngIf="module.first_main_connection_type === CcfPartMainConnectionType.Shaft"
      [ngClass]="{ 'cursor-pointer': !readonly }"
      class="connection-shaft-1"
    >
      <div translate>ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.s</div>
      <span class="mx-1">/</span>
      <div>{{ dimensions.mainConnection }}</div>
    </div>

    <p class="connection-bottom-first">
      {{ startLength + dimensions.size3FirstMainConnection }}
    </p>
  </ng-container>

  <ng-container *ngIf="module.second_main_connection">
    <div
      (click)="openMaintenanceModal('second')"
      *ngIf="!module.second_main_connection_type"
      [ngClass]="{ 'cursor-pointer': !readonly }"
      class="connection-blank-2 text-white blink"
      translate
    >
      ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.select
    </div>

    <div
      (click)="openMaintenanceModal('second')"
      *ngIf="module.second_main_connection_type === CcfPartMainConnectionType.Air"
      [ngClass]="{ 'cursor-pointer': !readonly }"
      class="connection-shaft-2"
    >
      <div translate>ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.a</div>
      <span class="mx-1">/</span>
      <div>{{ dimensions.mainConnection }}</div>
    </div>

    <div
      (click)="openMaintenanceModal('second')"
      *ngIf="module.second_main_connection_type === CcfPartMainConnectionType.Maintenance"
      [ngClass]="{ 'cursor-pointer': !readonly }"
      class="connection-maintenance-2"
    >
      <div translate>ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.r</div>
      <span class="mx-1">/</span>
      <div>{{ dimensions.mainConnection }}</div>
    </div>

    <div
      (click)="openMaintenanceModal('second')"
      *ngIf="module.second_main_connection_type === CcfPartMainConnectionType.Shaft"
      [ngClass]="{ 'cursor-pointer': !readonly }"
      class="connection-shaft-2"
    >
      <div translate>ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.s</div>
      <span class="mx-1">/</span>
      <div>{{ dimensions.mainConnection }}</div>
    </div>

    <p class="connection-bottom-second">
      {{ startLength + dimensions.size3SecondMainConnection }}
    </p>
  </ng-container>

  <div *ngIf="endLength" class="measure-module-end">
    <p>{{ endLength }}</p>
  </div>
</div>

<p-dialog
  [(visible)]="showMaintenanceModal"
  [closeOnEscape]="true"
  [dismissableMask]="false"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="header">
    <h4 *ngIf="side === 'first'" class="text-ellipsis" translate>
      ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.select-first-bottom-connection
    </h4>
    <h4 *ngIf="side === 'second'" class="text-ellipsis" translate>
      ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.select-second-bottom-connection
    </h4>
  </ng-template>

  <ng-template pTemplate="content">
    <div class="p-fluid p-pt-3">
      <div class="p-field-radiobutton">
        <p-radioButton
          [(ngModel)]="selection"
          [value]="CcfPartMainConnectionType.Air"
          inputId="main_connection_air"
          name="main_connection"
        ></p-radioButton>
        <label for="main_connection_air" translate>
          ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.air-a
        </label>
      </div>
      <div class="p-field-radiobutton">
        <p-radioButton
          [(ngModel)]="selection"
          [value]="CcfPartMainConnectionType.Maintenance"
          inputId="main_connection_maintenance"
          name="main_connection"
        ></p-radioButton>
        <label for="main_connection_maintenance" translate>
          ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.maintenance-r
        </label>
      </div>
      <div class="p-field-radiobutton">
        <p-radioButton
          [(ngModel)]="selection"
          [value]="CcfPartMainConnectionType.Shaft"
          inputId="main_connection_shaft"
          name="main_connection"
        ></p-radioButton>
        <label for="main_connection_shaft" translate>
          ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.shaft-s
        </label>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button
      (onClick)="showMaintenanceModal = null"
      [label]="'ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.cancel' | translate"
      icon="pi pi-times"
      styleClass="p-button-secondary"
    ></p-button>
    <p-button
      (onClick)="applyMaintenanceModalSelection(selection)"
      [label]="'ui.app.ccf.configurator.size-3-module-side.size-3-module-side-component-html.ok' | translate"
      icon="pi pi-check"
    ></p-button>
  </ng-template>
</p-dialog>
