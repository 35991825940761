/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ErrorConflict } from '../model/models';
import { ErrorForbidden } from '../model/models';
import { ErrorNotFound } from '../model/models';
import { ErrorUnauthorized } from '../model/models';
import { ErrorValidationResponse } from '../model/models';
import { InlineResponse2001 } from '../model/models';
import { InlineResponse20043 } from '../model/models';
import { InlineResponse20075 } from '../model/models';
import { NotificationEmailTemplateType } from '../model/models';
import { UserEmailBodyInput } from '../model/models';
import { UserInput } from '../model/models';
import { UserWithEmailBodyInput } from '../model/models';
import { UsersList } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class UsersService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * User authorize
     * User authorize
     * @param userId ID of user
     * @param userEmailBodyInput Pass email body
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userAuthorize(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public userAuthorize(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public userAuthorize(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public userAuthorize(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userAuthorize.');
        }
        if (userEmailBodyInput === null || userEmailBodyInput === undefined) {
            throw new Error('Required parameter userEmailBodyInput was null or undefined when calling userAuthorize.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}/authorize`,
            userEmailBodyInput,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User block
     * User block
     * @param userId ID of user
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userBlock(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public userBlock(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public userBlock(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public userBlock(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userBlock.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}/block`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User create
     * User create
     * @param userWithEmailBodyInput Pass user details
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param translations Request translations
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userCreate(userWithEmailBodyInput: UserWithEmailBodyInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20043>;
    public userCreate(userWithEmailBodyInput: UserWithEmailBodyInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20043>>;
    public userCreate(userWithEmailBodyInput: UserWithEmailBodyInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20043>>;
    public userCreate(userWithEmailBodyInput: UserWithEmailBodyInput, fields?: string, relations?: string, translations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userWithEmailBodyInput === null || userWithEmailBodyInput === undefined) {
            throw new Error('Required parameter userWithEmailBodyInput was null or undefined when calling userCreate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (translations !== undefined && translations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>translations, 'translations');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20043>(`${this.configuration.basePath}/api/users`,
            userWithEmailBodyInput,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User delete
     * User delete
     * @param userId ID of user
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userDelete(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public userDelete(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public userDelete(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public userDelete(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userDelete.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User dependencies
     * User dependencies
     * @param userId ID of user
     * @param recursive Recursive
     * @param flat Flat
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userDependencies(userId: number, recursive?: boolean, flat?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse2001>;
    public userDependencies(userId: number, recursive?: boolean, flat?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse2001>>;
    public userDependencies(userId: number, recursive?: boolean, flat?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse2001>>;
    public userDependencies(userId: number, recursive?: boolean, flat?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userDependencies.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (recursive !== undefined && recursive !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>recursive, 'recursive');
        }
        if (flat !== undefined && flat !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>flat, 'flat');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse2001>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}/dependencies`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User details
     * User details
     * @param userId ID of user
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param translations Request translations
     * @param withTrashed With trashed
     * @param forManagement For management
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userDetails(userId: number, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, forManagement?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20043>;
    public userDetails(userId: number, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, forManagement?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20043>>;
    public userDetails(userId: number, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, forManagement?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20043>>;
    public userDetails(userId: number, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, forManagement?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userDetails.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (translations !== undefined && translations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>translations, 'translations');
        }
        if (withTrashed !== undefined && withTrashed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>withTrashed, 'with_trashed');
        }
        if (forManagement !== undefined && forManagement !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>forManagement, 'for_management');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20043>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User forget
     * User forget
     * @param userId ID of user
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userForget(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public userForget(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public userForget(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public userForget(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userForget.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}/forget`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User notification email template
     * User notification email template
     * @param userId ID of user
     * @param type Notification email template type
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userNotificationEmailTemplate(userId: number, type?: NotificationEmailTemplateType, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20075>;
    public userNotificationEmailTemplate(userId: number, type?: NotificationEmailTemplateType, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20075>>;
    public userNotificationEmailTemplate(userId: number, type?: NotificationEmailTemplateType, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20075>>;
    public userNotificationEmailTemplate(userId: number, type?: NotificationEmailTemplateType, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userNotificationEmailTemplate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20075>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}/notification_email_template`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User profile picture
     * User profile picture
     * @param userId ID of user
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param translations Request translations
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param profilePicture 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userProfilePicture(userId: number, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, profilePicture?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20043>;
    public userProfilePicture(userId: number, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, profilePicture?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20043>>;
    public userProfilePicture(userId: number, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, profilePicture?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20043>>;
    public userProfilePicture(userId: number, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, profilePicture?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userProfilePicture.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (translations !== undefined && translations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>translations, 'translations');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (profilePicture !== undefined) {
            formParams = formParams.append('profile_picture', <any>profilePicture) as any || formParams;
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20043>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}/profile_picture`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User reject
     * User reject
     * @param userId ID of user
     * @param userEmailBodyInput Pass email body
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReject(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public userReject(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public userReject(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public userReject(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userReject.');
        }
        if (userEmailBodyInput === null || userEmailBodyInput === undefined) {
            throw new Error('Required parameter userEmailBodyInput was null or undefined when calling userReject.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}/reject`,
            userEmailBodyInput,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User password reset
     * User password reset
     * @param userId ID of user
     * @param userEmailBodyInput Pass email body
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userResetPassword(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20043>;
    public userResetPassword(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20043>>;
    public userResetPassword(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20043>>;
    public userResetPassword(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userResetPassword.');
        }
        if (userEmailBodyInput === null || userEmailBodyInput === undefined) {
            throw new Error('Required parameter userEmailBodyInput was null or undefined when calling userResetPassword.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20043>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}/reset_password`,
            userEmailBodyInput,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User restore
     * User restore
     * @param userId ID of user
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param translations Request translations
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userRestore(userId: number, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20043>;
    public userRestore(userId: number, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20043>>;
    public userRestore(userId: number, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20043>>;
    public userRestore(userId: number, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userRestore.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (translations !== undefined && translations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>translations, 'translations');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<InlineResponse20043>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}/restore`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User unblock
     * User unblock
     * @param userId ID of user
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userUnblock(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public userUnblock(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public userUnblock(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public userUnblock(userId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userUnblock.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}/unblock`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User update
     * User update
     * @param userId ID of user
     * @param userInput Pass user details
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param translations Request translations
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userUpdate(userId: number, userInput: UserInput, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20043>;
    public userUpdate(userId: number, userInput: UserInput, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20043>>;
    public userUpdate(userId: number, userInput: UserInput, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20043>>;
    public userUpdate(userId: number, userInput: UserInput, fields?: string, relations?: string, translations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userUpdate.');
        }
        if (userInput === null || userInput === undefined) {
            throw new Error('Required parameter userInput was null or undefined when calling userUpdate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (translations !== undefined && translations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>translations, 'translations');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<InlineResponse20043>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}`,
            userInput,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User verify email
     * User verify email
     * @param userId ID of user
     * @param userEmailBodyInput Pass email body
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userVerifyEmail(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public userVerifyEmail(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public userVerifyEmail(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public userVerifyEmail(userId: number, userEmailBodyInput: UserEmailBodyInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userVerifyEmail.');
        }
        if (userEmailBodyInput === null || userEmailBodyInput === undefined) {
            throw new Error('Required parameter userEmailBodyInput was null or undefined when calling userVerifyEmail.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/users/${encodeURIComponent(String(userId))}/verify_email`,
            userEmailBodyInput,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Users list
     * Users list
     * @param search Search string
     * @param filters Filters
     * @param page Page number; use 0 to skip page counting
     * @param perPage Rows per page; use 0 for no pagination
     * @param orderBy Order field
     * @param orderDir Order direction
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param translations Request translations
     * @param withTrashed With trashed
     * @param forManagement For management
     * @param areaManagersOnly Area managers only
     * @param forCurrentMbu For current mbu
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, forManagement?: boolean, areaManagersOnly?: boolean, forCurrentMbu?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UsersList>;
    public usersList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, forManagement?: boolean, areaManagersOnly?: boolean, forCurrentMbu?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UsersList>>;
    public usersList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, forManagement?: boolean, areaManagersOnly?: boolean, forCurrentMbu?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UsersList>>;
    public usersList(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, translations?: string, withTrashed?: boolean, forManagement?: boolean, areaManagersOnly?: boolean, forCurrentMbu?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (search !== undefined && search !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>search, 'search');
        }
        if (filters !== undefined && filters !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filters, 'filters');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>perPage, 'per_page');
        }
        if (orderBy !== undefined && orderBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderBy, 'order_by');
        }
        if (orderDir !== undefined && orderDir !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderDir, 'order_dir');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (translations !== undefined && translations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>translations, 'translations');
        }
        if (withTrashed !== undefined && withTrashed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>withTrashed, 'with_trashed');
        }
        if (forManagement !== undefined && forManagement !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>forManagement, 'for_management');
        }
        if (areaManagersOnly !== undefined && areaManagersOnly !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>areaManagersOnly, 'area_managers_only');
        }
        if (forCurrentMbu !== undefined && forCurrentMbu !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>forCurrentMbu, 'for_current_mbu');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<UsersList>(`${this.configuration.basePath}/api/users`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
