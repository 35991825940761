import { Component, EventEmitter, Output } from '@angular/core';
import { CcfPart, CcfPartMainConnectionType } from '@api';
import { Size2ModuleTopComponent } from '../size-2-module-top/size-2-module-top.component';

@Component({
  selector: 'app-ccf-configurator-size-2-module-side',
  templateUrl: './size-2-module-side.component.html',
  styleUrls: ['./size-2-module-side.component.scss'],
})
export class Size2ModuleSideComponent extends Size2ModuleTopComponent {
  @Output() selected: EventEmitter<CcfPart> = new EventEmitter<CcfPart>();

  showMaintenanceModal: boolean;
  side: 'first' | 'second';
  selection: CcfPartMainConnectionType;

  public get CcfPartMainConnectionType() {
    return CcfPartMainConnectionType;
  }

  select() {
    if (this.readonly) {
      return;
    }

    this.selected.emit(this.module);
  }

  openMaintenanceModal(side: 'first' | 'second') {
    if (this.readonly) {
      return;
    }

    this.side = side;

    switch (this.side) {
      case 'first':
        this.selection = this.module.first_main_connection_type;
        break;
      case 'second':
        this.selection = this.module.second_main_connection_type;
        break;
      default:
        this.selection = null;
    }

    this.showMaintenanceModal = true;
  }

  applyMaintenanceModalSelection(result: CcfPartMainConnectionType) {
    switch (this.side) {
      case 'first':
        this.module.first_main_connection_type = result;
        break;
      case 'second':
        this.module.second_main_connection_type = result;
        break;
    }

    this.select();

    this.showMaintenanceModal = null;
  }
}
