import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[responsiveImageSizes]',
})
export class ResponsiveImageSizesDirective {
  constructor(
    public _elRef: ElementRef,
    public _renderer: Renderer2,
  ) {}

  ngOnInit() {
    // https://spatie.be/docs/laravel-medialibrary/v9/responsive-images/getting-started-with-responsive-images#a-pragmatic-sizes-approach
    this._renderer.setAttribute(this._elRef.nativeElement, 'sizes', '1px');
  }

  @HostListener('load', ['$event']) onClick(event: Event) {
    const element: any = event.target || event.srcElement || event.currentTarget;

    window.requestAnimationFrame(() => {
      const size = element.getBoundingClientRect().width;

      if (!size) {
        return;
      }

      const sizes = Math.ceil((size / window.innerWidth) * 100) + 'vw';

      if (element.sizes === sizes) {
        return;
      }

      element.sizes = sizes;
    });
  }
}
