/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjManifoldType = 'standard' | 'comfotherm' | 'comfocubeflex' | 'metal';

export const PrjManifoldType = {
    Standard: 'standard' as PrjManifoldType,
    Comfotherm: 'comfotherm' as PrjManifoldType,
    Comfocubeflex: 'comfocubeflex' as PrjManifoldType,
    Metal: 'metal' as PrjManifoldType
};

