import { Injectable } from '@angular/core';
import { PermissionName, User } from '@api';
import { BehaviorSubject } from 'rxjs';

export interface Credentials {
  // Customize received credentials here
  token: string;
  username?: string;
  user?: User;
}

const credentialsKey = 'credentials';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  public credentials$ = new BehaviorSubject<Credentials>(null);

  constructor() {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this.credentials$.next(JSON.parse(savedCredentials));
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials$.getValue();
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions. Default true to match the API behavior.
   */
  setCredentials(credentials?: Credentials, remember = true) {
    // Avoid re-setting the observable values if not needed
    if (JSON.stringify(credentials) !== JSON.stringify(this.credentials$.getValue())) {
      this.credentials$.next(credentials);
    }

    const storage = remember ? localStorage : sessionStorage;
    if (credentials) {
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      storage.removeItem(credentialsKey);
    }
  }

  can(permission: PermissionName, user?: User) {
    if (!user) {
      const credentials = this.credentials$.getValue();
      user = credentials ? credentials.user : null;
    }
    return user && user.permission_names ? user.permission_names.indexOf(permission) !== -1 : false;
  }
}
