/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CcfPartCategory = 'module' | 'front_module' | 'front_module_adapter' | 'front_module_plug' | 'end_module' | 'end_module_adapter' | 'end_module_plug' | 'module_clamp' | 'module_bracket' | 'module_bracket_screw' | 'module_seal' | 'module_bottom_connection' | 'module_bottom_connection_seal' | 'module_bottom_connection_nail' | 'module_side_connection_part' | 'module_side_connection_adapter' | 'module_inside_insulation' | 'module_outside_insulation' | 'open_end_module_outside_insulation' | 'closed_end_module_outside_insulation';

export const CcfPartCategory = {
    Module: 'module' as CcfPartCategory,
    FrontModule: 'front_module' as CcfPartCategory,
    FrontModuleAdapter: 'front_module_adapter' as CcfPartCategory,
    FrontModulePlug: 'front_module_plug' as CcfPartCategory,
    EndModule: 'end_module' as CcfPartCategory,
    EndModuleAdapter: 'end_module_adapter' as CcfPartCategory,
    EndModulePlug: 'end_module_plug' as CcfPartCategory,
    ModuleClamp: 'module_clamp' as CcfPartCategory,
    ModuleBracket: 'module_bracket' as CcfPartCategory,
    ModuleBracketScrew: 'module_bracket_screw' as CcfPartCategory,
    ModuleSeal: 'module_seal' as CcfPartCategory,
    ModuleBottomConnection: 'module_bottom_connection' as CcfPartCategory,
    ModuleBottomConnectionSeal: 'module_bottom_connection_seal' as CcfPartCategory,
    ModuleBottomConnectionNail: 'module_bottom_connection_nail' as CcfPartCategory,
    ModuleSideConnectionPart: 'module_side_connection_part' as CcfPartCategory,
    ModuleSideConnectionAdapter: 'module_side_connection_adapter' as CcfPartCategory,
    ModuleInsideInsulation: 'module_inside_insulation' as CcfPartCategory,
    ModuleOutsideInsulation: 'module_outside_insulation' as CcfPartCategory,
    OpenEndModuleOutsideInsulation: 'open_end_module_outside_insulation' as CcfPartCategory,
    ClosedEndModuleOutsideInsulation: 'closed_end_module_outside_insulation' as CcfPartCategory
};

