/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjRoomCalculationMethod = 'din_en_16798' | 'workplace_policy' | 'air_exchange' | 'manual';

export const PrjRoomCalculationMethod = {
    DinEn16798: 'din_en_16798' as PrjRoomCalculationMethod,
    WorkplacePolicy: 'workplace_policy' as PrjRoomCalculationMethod,
    AirExchange: 'air_exchange' as PrjRoomCalculationMethod,
    Manual: 'manual' as PrjRoomCalculationMethod
};

