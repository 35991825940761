import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  PrjProjectNavigation,
  PrjProjectNavigationZoneSteps,
  PrjProjectsService as ApiPrjProjectsService,
  PrjProjectType,
} from '@api';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, skip, take } from 'rxjs/operators';

import { environment } from '@env/environment';

export interface NavigationState {
  projectId?: number;
  code?: string;
  buildingId?: number;
  apartmentId?: number;
  zone?: number;
}

export interface NavigationBase {
  name?: string;
  partiallyCompleted?: boolean;
  completed?: boolean;
  blocked?: boolean;
  available?: boolean;
  wip?: boolean;
  url?: string[];
  external?: boolean;
  collapsed?: boolean;
  tooltip?: string;
}

export interface NavigationStep extends NavigationBase {
  code?: string;
}

export interface NavigationZone extends NavigationBase {
  zone?: number;
  groupZone?: number;
  steps?: NavigationStep[];
}

export interface NavigationApartment extends NavigationBase {
  id?: number;
  zones?: NavigationZone[];
}

export interface NavigationBuilding extends NavigationBase {
  id?: number;
  apartments?: NavigationApartment[];
  stepsBeforeApartments?: NavigationStep[];
  stepsAfterApartments?: NavigationStep[];
}

export interface NavigationItem extends NavigationBase {
  code?: string;
  buildings?: NavigationBuilding[];
}

export interface NavigationFullItem extends NavigationBase {
  code?: string;
  buildingId?: number;
  buildingName?: string;
  apartmentId?: number;
  apartmentName?: string;
  zone?: number;
  groupZone?: number;
}

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class NavigationService {
  projectChanged$ = new BehaviorSubject<number>(null);
  stateChanged$ = new BehaviorSubject<NavigationState>(null);
  itemsChanged$ = new BehaviorSubject<NavigationItem[]>(null);

  items: NavigationItem[];
  state: NavigationState;
  readonly: boolean;
  title: string;
  visible: boolean;
  pinned: boolean;
  isLoading = 0;

  environment = environment;

  constructor(
    public apiPrjProjectsService: ApiPrjProjectsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public translateService: TranslateService,
  ) {
    this.projectChanged$.pipe(untilDestroyed(this)).subscribe((projectId) => {
      if (this.state && this.state.projectId && String(this.state.projectId) === String(projectId)) {
        this.loadProjectNavigation();
      }
    });
  }

  getFullItems(items: NavigationItem[]): NavigationFullItem[] {
    if (!items) {
      return [];
    }

    const fullItems: NavigationFullItem[] = [];

    const processor = (item: NavigationBase) => ({
      name: item.name,
      collapsed: item.collapsed,
      partiallyCompleted: item.partiallyCompleted,
      completed: item.completed,
      blocked: item.blocked,
      available: item.available,
      wip: item.wip,
      url: item.url,
      external: item.external,
      tooltip: item.tooltip,
    });

    items.forEach((item) => {
      if (item.url && item.url.length && !item.blocked && !item.wip) {
        fullItems.push({
          code: item.code,
          ...processor(item),
        });
      }

      if (item.buildings) {
        item.buildings.forEach((building) => {
          if (building.stepsBeforeApartments) {
            building.stepsBeforeApartments.forEach((step) => {
              if (step.url && step.url.length && !step.blocked && !step.wip) {
                fullItems.push({
                  code: step.code,
                  buildingId: building.id,
                  buildingName: building.name,
                  ...processor(step),
                });
              }
            });
          }

          if (building.apartments) {
            building.apartments.forEach((apartment) => {
              if (apartment.zones) {
                apartment.zones.forEach((zone) => {
                  if (zone.steps) {
                    zone.steps.forEach((step) => {
                      if (step.url && step.url.length && !step.blocked && !step.wip) {
                        fullItems.push({
                          code: step.code,
                          buildingId: building.id,
                          buildingName: building.name,
                          apartmentId: apartment.id,
                          apartmentName: apartment.name,
                          zone: zone.zone || 0,
                          groupZone: zone.groupZone,
                          ...processor(step),
                        });
                      }
                    });
                  }
                });
              }
            });
          }

          if (building.stepsAfterApartments) {
            building.stepsAfterApartments.forEach((step) => {
              if (step.url && step.url.length && !step.blocked && !step.wip) {
                fullItems.push({
                  code: step.code,
                  buildingId: building.id,
                  buildingName: building.name,
                  ...processor(step),
                });
              }
            });
          }
        });
      }
    });

    return fullItems;
  }

  isSameGroupZone(zone1: number, groupZone1: number, zone2: number, groupZone2: number) {
    return (
      // 1 is master and 2 is slave
      ([null, undefined].indexOf(groupZone1) !== -1 &&
        [null, undefined].indexOf(groupZone2) === -1 &&
        (zone1 || 0) === (groupZone2 || 0)) ||
      // 2 is master and 1 is slave
      ([null, undefined].indexOf(groupZone1) === -1 &&
        [null, undefined].indexOf(groupZone2) !== -1 &&
        (groupZone1 || 0) === (zone2 || 0)) ||
      // 1 and 2 are both slaves
      ([null, undefined].indexOf(groupZone1) === -1 &&
        [null, undefined].indexOf(groupZone2) === -1 &&
        (groupZone1 || 0) === (groupZone2 || 0))
    );
  }

  getSiblingFullItem(
    items: NavigationItem[],
    direction?: 'previous' | 'next',
    code?: string,
    buildingId?: number,
    apartmentId?: number,
    zone?: number,
    groupZone?: number,
  ) {
    if (!items) {
      return;
    }

    const fullItems = this.getFullItems(items);

    let index = fullItems.findIndex(
      (fullItem) =>
        !code ||
        (code === fullItem.code &&
          (!buildingId ||
            (buildingId === fullItem.buildingId &&
              (!apartmentId || (apartmentId === fullItem.apartmentId && (zone || 0) === (fullItem.zone || 0)))))),
    );

    if (index === -1) {
      return;
    }

    if (!direction) {
      return fullItems[index];
    }

    const isSameGroupAndCompleted = (fullItem: NavigationFullItem) =>
      // moving from device/accessories to device/accessories in a different zone but in the same group zone
      ['device', 'accessories'].indexOf(code) !== -1 &&
      ['device', 'accessories'].indexOf(fullItem.code) !== -1 &&
      this.isSameGroupZone(zone, groupZone, fullItem.zone, fullItem.groupZone) &&
      fullItem.completed;
    let fullItem: NavigationFullItem;
    let sameGroupAndCompleted: boolean;

    if (direction === 'previous') {
      do {
        fullItem = index > 0 ? fullItems[index - 1] : null;

        sameGroupAndCompleted = isSameGroupAndCompleted(fullItem);

        if (sameGroupAndCompleted) {
          index--;
        }
      } while (sameGroupAndCompleted && fullItem);

      return fullItem;
    }

    if (direction === 'next') {
      do {
        fullItem = index < fullItems.length - 1 ? fullItems[index + 1] : null;

        sameGroupAndCompleted = isSameGroupAndCompleted(fullItem);

        if (sameGroupAndCompleted) {
          index++;
        }
      } while (sameGroupAndCompleted && fullItem);

      return fullItem;
    }
  }

  async refreshItems(projectId?: number): Promise<NavigationItem[]> {
    if (!projectId) {
      const state = await this.stateChanged$.pipe(take(1)).toPromise();
      if (state) {
        projectId = state.projectId;
      }
    }

    this.projectChanged$.next(projectId); // trigger loadProjectNavigation

    return await this.itemsChanged$
      .pipe(
        skip(1), // the first emitted value is the current items and we are interested in the next, after loadProjectNavigation
        take(1), // we are only interested in one
      )
      .toPromise();
  }

  init() {
    const processActivatedRoute = () => {
      // TODO: Use e as NavigationEnd to parse the data but do not trigger loadProjectNavigation unless the route actually changed
      let state: NavigationState = null;

      let route = this.activatedRoute;
      do {
        const snapshot = route.snapshot;

        if (snapshot) {
          if (
            !state &&
            snapshot.url &&
            snapshot.url[0] &&
            snapshot.url[0].path === 'projects' &&
            snapshot.params &&
            snapshot.params.project_id &&
            snapshot.url.length >= 3 &&
            snapshot.params.project_id === snapshot.url[1].path
          ) {
            state = {
              projectId: parseInt(snapshot.params.project_id) || null,
              code: snapshot.url[2].path,
              buildingId: parseInt(snapshot.params.building_id) || null,
              apartmentId: parseInt(snapshot.params.apartment_id) || null,
              zone: parseInt(snapshot.params.zone || 0) || null,
            };
          } else if (state) {
            if (snapshot.params.building_id) {
              state.buildingId = parseInt(snapshot.params.building_id);
            }

            if (snapshot.params.apartment_id) {
              state.apartmentId = parseInt(snapshot.params.apartment_id);
            }

            if (snapshot.params.zone) {
              state.zone = parseInt(snapshot.params.zone);
            }
          }
        }

        route = route.firstChild;
      } while (route);

      // Using the history state to relay building and apartment to avoid placing them in the URL
      if (state) {
        const currentNavigation = this.router.getCurrentNavigation();
        if (currentNavigation && currentNavigation.extras && currentNavigation.extras.state) {
          if (!state.buildingId) {
            state.buildingId = parseInt(currentNavigation.extras.state.building_id) || null;
          }
          if (!state.apartmentId) {
            state.apartmentId = parseInt(currentNavigation.extras.state.apartment_id) || null;
          }
          if (!state.zone) {
            state.apartmentId = parseInt(currentNavigation.extras.state.zone || 0) || null;
          }
        }
      }

      return state;
    };

    const processNavigationState = (navigationState: NavigationState) => {
      if (JSON.stringify(this.state || null) === JSON.stringify(navigationState || null)) {
        return;
      }

      this.state = navigationState;
      this.stateChanged$.next(navigationState);

      this.title = null;

      if (this.state) {
        if (!this.items) {
          this.initItems().then();
        } else {
          this.checkTitle().then();
        }

        this.loadProjectNavigation();
      } else {
        this.items = null;
        this.itemsChanged$.next(this.items);
      }
    };

    // Run it once on init because redirecting into a project after login does not trigger a router event
    processNavigationState(processActivatedRoute());

    this.router.events.pipe(untilDestroyed(this), map(processActivatedRoute)).subscribe(processNavigationState);
  }

  async checkTitle() {
    this.title = null;

    if (this.state && this.items) {
      const item = this.items.find((item) => item.code === this.state.code);

      if (item) {
        this.title = item.name;
      } else if (this.state.buildingId) {
        for (let item of this.items) {
          {
            if (item.buildings) {
              const building = item.buildings.find((building) => building.id === this.state.buildingId);
              if (building) {
                if (this.state.apartmentId) {
                  if (building.apartments) {
                    const apartment = building.apartments.find((apartment) => apartment.id === this.state.apartmentId);
                    if (apartment && apartment.zones) {
                      const zone = apartment.zones.find((zone) => (zone.zone || 0) === (this.state.zone || 0));
                      if (zone) {
                        const step = zone.steps.find((step) => step.code === this.state.code);
                        if (step) {
                          this.title = [
                            step.name,
                            apartment.zones.length > 1
                              ? await this.translateService
                                  .get('ui.app.@shared.navigation.navigation-service-ts.zone-zone', {
                                    zone: zone.zone || 0,
                                  })
                                  .toPromise()
                              : null,
                            apartment.name,
                            building.name,
                          ]
                            .filter((part) => part)
                            .join(' - ');
                        }
                      }
                    }
                  }
                } else if (building.stepsBeforeApartments || building.stepsAfterApartments) {
                  const step = building.stepsBeforeApartments
                    .concat(building.stepsAfterApartments)
                    .find((step) => step.code === this.state.code);
                  if (step) {
                    this.title = [step.name, building.name].filter((part) => part).join(' - ');
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  loadProjectNavigation() {
    if (!this.state || !this.state.projectId) {
      return;
    }

    this.isLoading++;
    return this.apiPrjProjectsService
      .prjProjectNavigation(this.state.projectId)
      .pipe(untilDestroyed(this))
      .subscribe(
        async (res) => {
          await this.initItems(res.data);
          this.isLoading--;
        },
        () => this.isLoading--,
      );
  }

  async initItems(project?: PrjProjectNavigation) {
    this.readonly = project ? project.readonly : true;

    let items: NavigationItem[] = [
      {
        code: 'details',
        name: await this.translateService.get('ui.app.@shared.navigation.navigation-service-ts.details').toPromise(),
        completed: true, // always available
        available: true, // always available
        url:
          project && project.steps && project.steps.details && project.steps.details.url
            ? [project.steps.details.url]
            : null,
        external: project && project.steps && project.steps.details ? project.steps.details.external : null,
        tooltip: project && project.steps && project.steps.details ? project.steps.details.tooltip : null,
      },
    ];

    if (project) {
      if (project.type === PrjProjectType.QuotePlus) {
        items.push({
          code: 'planning',
          name: await this.translateService.get('ui.app.@shared.navigation.navigation-service-ts.planning').toPromise(),
          completed: true, // always available
          available: true, // always available
          url:
            project && project.steps && project.steps.planning && project.steps.planning.url
              ? [project.steps.planning.url]
              : null,
          external: project && project.steps && project.steps.planning ? project.steps.planning.external : null,
          tooltip: project && project.steps && project.steps.planning ? project.steps.planning.tooltip : null,
        });
      } else if (project.type === PrjProjectType.Plan) {
        const processSteps = async (
          steps: PrjProjectNavigationZoneSteps,
          position?: 'after' | 'before',
          allStepsBeforeAreCompletedOrBlocked?: boolean,
        ) =>
          !steps
            ? []
            : [
                !steps.device || position === 'after'
                  ? null
                  : {
                      code: 'device',
                      name: await this.translateService
                        .get('ui.app.@shared.navigation.navigation-service-ts.unit')
                        .toPromise(),
                      completed: steps.device.completed,
                      blocked: steps.device.blocked,
                      wip: steps.device.wip,
                      url: steps.device.url ? [steps.device.url] : null,
                      external: steps.device.external,
                      tooltip: steps.device.tooltip,
                    },
                !steps.accessories || position === 'after'
                  ? null
                  : {
                      code: 'accessories',
                      name: await this.translateService
                        .get('ui.app.@shared.navigation.navigation-service-ts.accessories')
                        .toPromise(),
                      completed: steps.accessories.completed,
                      blocked: steps.accessories.blocked,
                      wip: steps.accessories.wip,
                      url: steps.accessories.url ? [steps.accessories.url] : null,
                      external: steps.accessories.external,
                      tooltip: steps.accessories.tooltip,
                    },
                !steps.outlets || position
                  ? null
                  : {
                      code: 'outlets',
                      name: await this.translateService
                        .get('ui.app.@shared.navigation.navigation-service-ts.outlets')
                        .toPromise(),
                      completed: steps.outlets.completed,
                      blocked: steps.outlets.blocked,
                      wip: steps.outlets.wip,
                      url: steps.outlets.url ? [steps.outlets.url] : null,
                      external: steps.outlets.external,
                      tooltip: steps.outlets.tooltip,
                    },
                !steps.manifolds || position
                  ? null
                  : {
                      code: 'manifolds',
                      name: await this.translateService
                        .get('ui.app.@shared.navigation.navigation-service-ts.manifolds')
                        .toPromise(),
                      completed: steps.manifolds.completed,
                      blocked: steps.manifolds.blocked,
                      wip: steps.manifolds.wip,
                      url: steps.manifolds.url ? [steps.manifolds.url] : null,
                      external: steps.manifolds.external,
                      tooltip: steps.manifolds.tooltip,
                    },
                !steps.cpd || position
                  ? null
                  : {
                      code: 'cpd',
                      name: await this.translateService
                        .get('ui.app.@shared.navigation.navigation-service-ts.cpd')
                        .toPromise(),
                      completed: steps.cpd.completed,
                      blocked: steps.cpd.blocked,
                      wip: steps.cpd.wip,
                      url: steps.cpd.url ? [steps.cpd.url] : null,
                      external: steps.cpd.external,
                      tooltip: steps.cpd.tooltip,
                    },
                !steps.inside_installations || position === 'before'
                  ? null
                  : {
                      code: 'inside_installations',
                      name: await this.translateService
                        .get('ui.app.@shared.navigation.navigation-service-ts.inside-installations')
                        .toPromise(),
                      completed: steps.inside_installations.completed,
                      blocked: steps.inside_installations.blocked,
                      wip: steps.inside_installations.wip,
                      url: steps.inside_installations.url ? [steps.inside_installations.url] : null,
                      external: steps.inside_installations.external,
                      tooltip: steps.inside_installations.tooltip,
                    },
                !steps.building_installations || position === 'before'
                  ? null
                  : {
                      code: 'building_installations',
                      name: await this.translateService
                        .get('ui.app.@shared.navigation.navigation-service-ts.building-installations')
                        .toPromise(),
                      completed: steps.building_installations.completed,
                      blocked: steps.building_installations.blocked,
                      wip: steps.building_installations.wip,
                      url: steps.building_installations.url ? [steps.building_installations.url] : null,
                      external: steps.building_installations.external,
                      tooltip: steps.building_installations.tooltip,
                    },
                !steps.outside_installations || position === 'before'
                  ? null
                  : {
                      code: 'outside_installations',
                      name: await this.translateService
                        .get('ui.app.@shared.navigation.navigation-service-ts.outside-installations')
                        .toPromise(),
                      completed: steps.outside_installations.completed,
                      blocked: steps.outside_installations.blocked,
                      wip: steps.outside_installations.wip,
                      url: steps.outside_installations.url ? [steps.outside_installations.url] : null,
                      external: steps.outside_installations.external,
                      tooltip: steps.outside_installations.tooltip,
                    },
              ]
                .filter((step) => step)
                .map((step, index, steps) => ({
                  ...step,
                  available:
                    (step.completed ||
                      (allStepsBeforeAreCompletedOrBlocked &&
                        !steps.find(
                          (previousStep, previousIndex) =>
                            previousIndex < index && !previousStep.completed && !previousStep.blocked,
                        ))) &&
                    !step.blocked,
                }));

        items.push({
          code: 'planning',
          name: await this.translateService.get('ui.app.@shared.navigation.navigation-service-ts.planning').toPromise(),
          completed: true, // always available
          available: true, // always available
          url:
            project && project.steps && project.steps.planning && project.steps.planning.url
              ? [project.steps.planning.url]
              : null,
          external: project && project.steps && project.steps.planning ? project.steps.planning.external : null,
          tooltip: project && project.steps && project.steps.planning ? project.steps.planning.tooltip : null,
          buildings: await Promise.all(
            (project.buildings || []).map(async (building) => {
              const stepsBeforeApartments = await processSteps(
                building.steps,
                'before',
                project.steps.planning.completed,
              );

              let allStepsBeforeAreCompletedOrBlocked: boolean = !stepsBeforeApartments.find(
                (step) => !step.completed && !step.blocked,
              );

              const apartments = await Promise.all(
                (building.apartments || []).map(async (apartment) => ({
                  id: apartment.id,
                  name: apartment.name,
                  collapsed: apartment.collapsed,
                  tooltip: apartment.tooltip,
                  zones: await Promise.all(
                    (apartment.zones || []).map(async (zone) => ({
                      zone: zone.zone,
                      groupZone: zone.group_zone,
                      collapsed: zone.collapsed,
                      name: await this.translateService
                        .get('ui.app.@shared.navigation.navigation-service-ts.zone-zone', {
                          zone: zone.zone,
                        })
                        .toPromise(),
                      steps: await processSteps(zone.steps, null, allStepsBeforeAreCompletedOrBlocked),
                      tooltip: zone.tooltip,
                    })),
                  ),
                })),
              );

              allStepsBeforeAreCompletedOrBlocked =
                allStepsBeforeAreCompletedOrBlocked &&
                !apartments.find(
                  (apartment) =>
                    !apartment.zones.find((zone) => !zone.steps.find((step) => !step.completed && !step.blocked)),
                );

              return {
                id: building.id,
                name: building.name,
                collapsed: building.collapsed,
                tooltip: building.tooltip,
                stepsBeforeApartments,
                apartments,
                stepsAfterApartments: await processSteps(building.steps, 'after', allStepsBeforeAreCompletedOrBlocked),
              };
            }),
          ),
        });
      }
    }

    items = items.concat([
      {
        code: 'materials',
        name: await this.translateService.get('ui.app.@shared.navigation.navigation-service-ts.materials').toPromise(),
        completed: true, // always available
        available: true, // always available
        url:
          project && project.steps && project.steps.materials && project.steps.materials.url
            ? [project.steps.materials.url]
            : null,
        external: project && project.steps && project.steps.materials ? project.steps.materials.external : null,
        tooltip: project && project.steps && project.steps.materials ? project.steps.materials.tooltip : null,
      },
      {
        code: 'documents',
        name: await this.translateService.get('ui.app.@shared.navigation.navigation-service-ts.documents').toPromise(),
        completed: true, // always available
        available: true, // always available
        url:
          project && project.steps && project.steps.documents && project.steps.documents.url
            ? [project.steps.documents.url]
            : null,
        external: project && project.steps && project.steps.documents ? project.steps.documents.external : null,
        tooltip: project && project.steps && project.steps.documents ? project.steps.documents.tooltip : null,
      },
    ]);

    // Determine partial completion and urls
    items.forEach((item) => {
      item.partiallyCompleted = this.itemIsPartiallyCompleted(item);
      item.available = item.partiallyCompleted;
      item.url = item.external && item.url ? item.url : this.getLink({ code: item.code });
      (item.buildings || []).forEach((building) => {
        building.partiallyCompleted = true; // this.buildingIsPartiallyCompleted(building); // always available
        building.available = building.partiallyCompleted;
        building.url =
          item.external && item.url
            ? item.url
            : this.getLink({
                code: item.code,
                // buildingId: building.id, // cannot use due to changes in the planning screen
              });

        (building.stepsBeforeApartments || []).forEach((step) => {
          step.url =
            step.external && step.url
              ? step.url
              : this.getLink({
                  code: step.code,
                  buildingId: building.id,
                });
        });

        (building.apartments || []).forEach((apartment) => {
          apartment.partiallyCompleted = true; // this.apartmentIsPartiallyCompleted(apartment); // always available
          apartment.available = apartment.partiallyCompleted;
          apartment.url =
            item.external && item.url
              ? item.url
              : this.getLink({
                  code: item.code,
                  // buildingId: building.id, // cannot use due to changes in the planning screen
                  // apartmentId: apartment.id, // cannot use due to changes in the planning screen
                });

          (apartment.zones || []).forEach((zone) => {
            zone.partiallyCompleted = true; // this.zoneIsPartiallyCompleted(zone); // always available
            zone.available = zone.partiallyCompleted;
            zone.url =
              item.external && item.url
                ? item.url
                : this.getLink({
                    code: item.code,
                    // buildingId: building.id, // cannot use due to changes in the planning screen
                    // apartmentId: apartment.id, // cannot use due to changes in the planning screen
                    // zone: zone.zone ? zone.zone : null, // cannot use due to changes in the planning screen
                  });

            (zone.steps || []).forEach((step) => {
              step.url =
                step.external && step.url
                  ? step.url
                  : this.getLink({
                      code: step.code,
                      buildingId: building.id,
                      apartmentId: apartment.id,
                      zone: zone.zone,
                    });
            });
          });
        });

        (building.stepsAfterApartments || []).forEach((step) => {
          step.url =
            step.external && step.url
              ? step.url
              : this.getLink({
                  code: step.code,
                  buildingId: building.id,
                });
        });
      });
    });

    this.items = items;
    this.itemsChanged$.next(this.items);

    await this.checkTitle();

    return this.items;
  }

  getLink(state: NavigationState) {
    state = {
      projectId: this.state && this.state.projectId ? this.state.projectId : null,
      ...state,
    };

    const parts: string[] = ['/projects'];

    if (state && state.code && state.projectId) {
      parts.push(String(state.projectId));

      parts.push(state.code);

      if (state.buildingId) {
        parts.push(String(state.buildingId));
        if (state.apartmentId) {
          parts.push(String(state.apartmentId));
          if (state.zone) {
            parts.push(String(state.zone));
          }
        }
      }
    }

    return parts.length ? parts : null;
  }

  zoneIsPartiallyCompleted(zone: NavigationZone) {
    return zone.completed || (zone.steps && zone.steps.length && zone.steps[0].completed);
  }

  apartmentIsPartiallyCompleted(apartment: NavigationApartment) {
    return (
      apartment.completed ||
      (apartment.zones && apartment.zones.length && this.zoneIsPartiallyCompleted(apartment.zones[0]))
    );
  }

  buildingIsPartiallyCompleted(building: NavigationBuilding) {
    return (
      building.completed ||
      (building.apartments && building.apartments.length && this.apartmentIsPartiallyCompleted(building.apartments[0]))
    );
  }

  itemIsPartiallyCompleted(item: NavigationItem) {
    return (
      item.completed ||
      (item.buildings && item.buildings.length && this.buildingIsPartiallyCompleted(item.buildings[0]))
    );
  }

  collapseNavigationItem(collapsed: boolean, buildingId: number, apartmentId?: number, zone?: number) {
    if (!this.state || !this.state.projectId || this.readonly) {
      return;
    }

    // No loader
    return this.apiPrjProjectsService
      .prjProjectCollapse(this.state.projectId, {
        building_id: buildingId,
        apartment_id: apartmentId,
        zone: zone,
        collapsed: collapsed,
      })
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
