/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArtDistributionType = 'I' | 'O' | 'M' | 'F' | 'C' | 'N' | 'E' | 'D';

export const ArtDistributionType = {
    I: 'I' as ArtDistributionType,
    O: 'O' as ArtDistributionType,
    M: 'M' as ArtDistributionType,
    F: 'F' as ArtDistributionType,
    C: 'C' as ArtDistributionType,
    N: 'N' as ArtDistributionType,
    E: 'E' as ArtDistributionType,
    D: 'D' as ArtDistributionType
};

