import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';

import { CButtonComponent } from './c-button.component';

import { RippleModule } from 'primeng/ripple';
import { SpinnerIcon } from 'primeng/icons/spinner';

@NgModule({
  imports: [CommonModule, ButtonModule, RippleModule, SpinnerIcon],
  declarations: [CButtonComponent],
  exports: [CButtonComponent],
})
export class CButtonModule {}
