/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RoomVariantTypeBalancing = 'kitchen' | 'balanced' | 'reducer' | 'living' | 'hall' | 'entry';

export const RoomVariantTypeBalancing = {
    Kitchen: 'kitchen' as RoomVariantTypeBalancing,
    Balanced: 'balanced' as RoomVariantTypeBalancing,
    Reducer: 'reducer' as RoomVariantTypeBalancing,
    Living: 'living' as RoomVariantTypeBalancing,
    Hall: 'hall' as RoomVariantTypeBalancing,
    Entry: 'entry' as RoomVariantTypeBalancing
};

