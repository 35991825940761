import { Component } from '@angular/core';
import { FrontModuleSideComponent } from '../front-module-side/front-module-side.component';
import { CcfPartEndModuleAdapter, CcfPartEndModuleType } from '@api';

@Component({
  selector: 'app-ccf-configurator-end-module-side',
  templateUrl: './end-module-side.component.html',
  styleUrls: ['./end-module-side.component.scss'],
})
export class EndModuleSideComponent extends FrontModuleSideComponent {
  get CcfPartEndModuleType() {
    return CcfPartEndModuleType;
  }

  get CcfPartEndModuleAdapter() {
    return CcfPartEndModuleAdapter;
  }
}
