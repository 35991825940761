/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CcfPartMainConnectionType = 'air' | 'maintenance' | 'shaft';

export const CcfPartMainConnectionType = {
    Air: 'air' as CcfPartMainConnectionType,
    Maintenance: 'maintenance' as CcfPartMainConnectionType,
    Shaft: 'shaft' as CcfPartMainConnectionType
};

