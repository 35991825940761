/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArtDeviceGroupType = 'heat_exchanger' | 'decentralized_single' | 'decentralized_twin' | 'decentralized_near_room' | 'extraction_only' | 'xl_heat_exchanger' | 'comfobox';

export const ArtDeviceGroupType = {
    HeatExchanger: 'heat_exchanger' as ArtDeviceGroupType,
    DecentralizedSingle: 'decentralized_single' as ArtDeviceGroupType,
    DecentralizedTwin: 'decentralized_twin' as ArtDeviceGroupType,
    DecentralizedNearRoom: 'decentralized_near_room' as ArtDeviceGroupType,
    ExtractionOnly: 'extraction_only' as ArtDeviceGroupType,
    XlHeatExchanger: 'xl_heat_exchanger' as ArtDeviceGroupType,
    Comfobox: 'comfobox' as ArtDeviceGroupType
};

