<form *ngIf="!isLoading && (userId !== null || duplicateUserId)" class="p-d-flex p-flex-column h-100">
  <div class="p-pb-3">
    <h4 *ngIf="!userId && !forProfile" translate>ui.app.user-form.user-form-component-html.new-user</h4>
    <h4 *ngIf="userId" translate>ui.app.user-form.user-form-component-html.edit-user</h4>
    <h4 *ngIf="forProfile" translate>ui.app.user-form.user-form-component-html.edit-profile</h4>
  </div>

  <div class="p-fluid p-grid h-100 overflow-auto">
    <div [ngClass]="{ 'p-col-3': !forProfile, 'p-col-6': forProfile }">
      <h5 translate>ui.app.user-form.user-form-component-html.user-information</h5>

      <!-- TODO: Style this without the calculated height -->
      <div (file)="fileChosen($event)" appDndFile class="p-d-flex p-jc-center avatar-container">
        <label class="w-100 cursor-pointer p-text-center">
          <div class="avatar">
            <img
              *ngIf="profilePictureSrc || profilePictureSrcSet"
              [attr.srcset]="profilePictureSrcSet"
              [src]="profilePictureSrc"
              responsiveImageSizes
            />
          </div>

          <div class="browse">
            <i class="pi pi-plus"></i>
            <span translate>ui.app.user-form.user-form-component-html.browse</span>
          </div>

          <input #input (change)="fileChosen($event)" accept="image/*" class="p-d-none" type="file" />
        </label>
      </div>

      <div class="p-text-center">
        <div>{{ profilePictureFile?.name }}</div>

        <div *ngIf="profilePictureWidth || profilePictureHeight">
          {{ profilePictureWidth || 0 }} x {{ profilePictureHeight || 0 }} px
        </div>

        <div
          *ngIf="
            (profilePictureWidth && profilePictureWidth > 300) || (profilePictureHeight && profilePictureHeight > 300)
          "
          class="warning p-p-1 p-m-1"
        >
          <i class="pi pi-exclamation-triangle"></i>
          {{
            'ui.app.user-form.user-form-component-html.large-images-slow-down-the-application-try-to-resize-within-300-x-300-px'
              | translate
          }}
        </div>
      </div>

      <ng-container *ngIf="validation?.errors && validation?.errors['profile_picture']">
        <small *ngFor="let error of validation?.errors['profile_picture']" class="p-error">{{ error }}</small>
      </ng-container>
      <ng-container *ngIf="validation?.warnings && validation?.warnings['profile_picture']">
        <small *ngFor="let warning of validation?.warnings['profile_picture']" class="c-warning">{{ warning }}</small>
      </ng-container>

      <div class="p-field">
        <label for="first_name" translate>ui.app.user-form.user-form-component-html.first-name</label>
        <ng-container *ngIf="forProfile">*</ng-container>
        <input
          (keydown)="resetErrorsAndWarnings('first_name')"
          [(ngModel)]="userInput.first_name"
          [autofocus]="true"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['first_name'] }"
          id="first_name"
          name="first_name"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['first_name']">
          <small *ngFor="let error of validation?.errors['first_name']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['first_name']">
          <small *ngFor="let warning of validation?.warnings['first_name']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="last_name" translate>ui.app.user-form.user-form-component-html.last-name</label>
        <ng-container *ngIf="forProfile">*</ng-container>
        <input
          (keydown)="resetErrorsAndWarnings('last_name')"
          [(ngModel)]="userInput.last_name"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['last_name'] }"
          id="last_name"
          name="last_name"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['last_name']">
          <small *ngFor="let error of validation?.errors['last_name']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['last_name']">
          <small *ngFor="let warning of validation?.warnings['last_name']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="middle_name" translate>ui.app.user-form.user-form-component-html.middle-name</label>
        <input
          (keydown)="resetErrorsAndWarnings('middle_name')"
          [(ngModel)]="userInput.middle_name"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['middle_name'] }"
          id="middle_name"
          name="middle_name"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['middle_name']">
          <small *ngFor="let error of validation?.errors['middle_name']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['middle_name']">
          <small *ngFor="let warning of validation?.warnings['middle_name']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="gender" translate>ui.app.user-form.user-form-component-html.gender</label>
        <p-dropdown
          (ngModelChange)="resetErrorsAndWarnings('gender')"
          [(ngModel)]="userInput.gender"
          [filterFields]="['code', 'name']"
          [filter]="true"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['gender'] }"
          [optionLabel]="descriptionService.name"
          [options]="genders"
          [resetFilterOnHide]="true"
          [showClear]="true"
          appendTo="body"
          inputId="gender"
          name="gender"
          optionValue="code"
          placeholder="&nbsp;"
        ></p-dropdown>
        <ng-container *ngIf="validation?.errors && validation?.errors['gender']">
          <small *ngFor="let error of validation?.errors['gender']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['gender']">
          <small *ngFor="let warning of validation?.warnings['gender']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="phone" translate>ui.app.user-form.user-form-component-html.phone</label>
        <input
          (keydown)="resetErrorsAndWarnings('phone')"
          [(ngModel)]="userInput.phone"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['phone'] }"
          id="phone"
          name="phone"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['phone']">
          <small *ngFor="let error of validation?.errors['phone']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['phone']">
          <small *ngFor="let warning of validation?.warnings['phone']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="mobile" translate>ui.app.user-form.user-form-component-html.mobile</label>
        <input
          (keydown)="resetErrorsAndWarnings('mobile')"
          [(ngModel)]="userInput.mobile"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['mobile'] }"
          id="mobile"
          name="mobile"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['mobile']">
          <small *ngFor="let error of validation?.errors['mobile']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['mobile']">
          <small *ngFor="let warning of validation?.warnings['mobile']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="fax" translate>ui.app.user-form.user-form-component-html.fax</label>
        <input
          (keydown)="resetErrorsAndWarnings('fax')"
          [(ngModel)]="userInput.fax"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['fax'] }"
          id="fax"
          name="fax"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['fax']">
          <small *ngFor="let error of validation?.errors['fax']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['fax']">
          <small *ngFor="let warning of validation?.warnings['fax']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="email" translate>ui.app.user-form.user-form-component-html.email</label>
        <ng-container *ngIf="forProfile">*</ng-container>
        <input
          (keydown)="resetErrorsAndWarnings('email')"
          [(ngModel)]="userInput.email"
          [disabled]="!canChangeUserEmail"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['email'] }"
          id="email"
          name="email"
          pInputText
          type="email"
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['email']">
          <small *ngFor="let error of validation?.errors['email']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['email']">
          <small *ngFor="let warning of validation?.warnings['email']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="locale" translate>ui.app.user-form.user-form-component-html.locale</label>
        <ng-container *ngIf="forProfile">*</ng-container>
        <p-dropdown
          (ngModelChange)="resetErrorsAndWarnings('locale')"
          [(ngModel)]="userInput.locale"
          [filterFields]="['code', 'name']"
          [filter]="true"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['locale'] }"
          [optionLabel]="descriptionService.name"
          [options]="locales"
          [resetFilterOnHide]="true"
          [showClear]="true"
          appendTo="body"
          inputId="locale"
          name="locale"
          optionValue="code"
          placeholder="&nbsp;"
        ></p-dropdown>
        <ng-container *ngIf="validation?.errors && validation?.errors['locale']">
          <small *ngFor="let error of validation?.errors['locale']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['locale']">
          <small *ngFor="let warning of validation?.warnings['locale']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="remarks" translate>ui.app.user-form.user-form-component-html.remarks</label>
        <textarea
          (keydown)="resetErrorsAndWarnings('remarks')"
          [(ngModel)]="userInput.remarks"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['remarks'] }"
          id="remarks"
          name="remarks"
          pInputTextarea
        ></textarea>
        <ng-container *ngIf="validation?.errors && validation?.errors['remarks']">
          <small *ngFor="let error of validation?.errors['remarks']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['remarks']">
          <small *ngFor="let warning of validation?.warnings['remarks']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div *ngIf="!forProfile" class="p-field">
        <label for="comment_text" translate>ui.app.user-form.user-form-component-html.comment</label>
        <input
          (keydown)="resetErrorsAndWarnings('comment_text')"
          [(ngModel)]="userInput.comment_text"
          [autofocus]="true"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['comment_text'] }"
          id="comment_text"
          name="comment_text"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['comment_text']">
          <small *ngFor="let error of validation?.errors['comment_text']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['comment_text']">
          <small *ngFor="let warning of validation?.warnings['comment_text']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>
    </div>

    <div [ngClass]="{ 'p-col-3': !forProfile, 'p-col-6': forProfile }">
      <h5 translate>ui.app.user-form.user-form-component-html.company-information</h5>

      <div class="p-field">
        <label for="company_name" translate>ui.app.user-form.user-form-component-html.name</label>
        <ng-container *ngIf="forProfile">*</ng-container>
        <input
          (keydown)="resetErrorsAndWarnings('company_name')"
          [(ngModel)]="userInput.company_name"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['company_name'] }"
          id="company_name"
          name="company_name"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['company_name']">
          <small *ngFor="let error of validation?.errors['company_name']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['company_name']">
          <small *ngFor="let warning of validation?.warnings['company_name']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="company_function" translate>ui.app.user-form.user-form-component-html.function</label>
        <input
          (keydown)="resetErrorsAndWarnings('company_function')"
          [(ngModel)]="userInput.company_function"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['company_function'] }"
          id="company_function"
          name="company_function"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['company_function']">
          <small *ngFor="let error of validation?.errors['company_function']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['company_function']">
          <small *ngFor="let warning of validation?.warnings['company_function']" class="c-warning">
            {{ warning }}
          </small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="street" translate>ui.app.user-form.user-form-component-html.street</label>
        <ng-container *ngIf="forProfile">*</ng-container>
        <input
          (keydown)="resetErrorsAndWarnings('street')"
          [(ngModel)]="userInput.street"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['street'] }"
          id="street"
          name="street"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['street']">
          <small *ngFor="let error of validation?.errors['street']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['street']">
          <small *ngFor="let warning of validation?.warnings['street']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-grid">
        <div class="p-col-6 p-pb-0 p-field">
          <label for="zip_code" translate>ui.app.user-form.user-form-component-html.zip-code</label>
          <ng-container *ngIf="forProfile">*</ng-container>
          <input
            (keydown)="resetErrorsAndWarnings('zip_code')"
            [(ngModel)]="userInput.zip_code"
            [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['zip_code'] }"
            id="zip_code"
            name="zip_code"
            pInputText
          />
          <ng-container *ngIf="validation?.errors && validation?.errors['zip_code']">
            <small *ngFor="let error of validation?.errors['zip_code']" class="p-error">{{ error }}</small>
          </ng-container>
          <ng-container *ngIf="validation?.warnings && validation?.warnings['zip_code']">
            <small *ngFor="let warning of validation?.warnings['zip_code']" class="c-warning">{{ warning }}</small>
          </ng-container>
        </div>

        <div class="p-col-6 p-pb-0 p-field">
          <label for="city" translate>ui.app.user-form.user-form-component-html.city</label>
          <ng-container *ngIf="forProfile">*</ng-container>
          <input
            (keydown)="resetErrorsAndWarnings('city')"
            [(ngModel)]="userInput.city"
            [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['city'] }"
            id="city"
            name="city"
            pInputText
          />
          <ng-container *ngIf="validation?.errors && validation?.errors['city']">
            <small *ngFor="let error of validation?.errors['city']" class="p-error">{{ error }}</small>
          </ng-container>
          <ng-container *ngIf="validation?.warnings && validation?.warnings['city']">
            <small *ngFor="let warning of validation?.warnings['city']" class="c-warning">{{ warning }}</small>
          </ng-container>
        </div>
      </div>

      <div class="p-field">
        <label for="country" translate>ui.app.user-form.user-form-component-html.country</label>
        <ng-container *ngIf="forProfile">*</ng-container>
        <p-dropdown
          (ngModelChange)="resetErrorsAndWarnings('country')"
          [(ngModel)]="userInput.country"
          [filterFields]="['code', 'name']"
          [filter]="true"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['country'] }"
          [optionLabel]="descriptionService.name"
          [options]="countries"
          [resetFilterOnHide]="true"
          [showClear]="true"
          appendTo="body"
          inputId="country"
          name="country"
          optionValue="code"
          placeholder="&nbsp;"
        ></p-dropdown>
        <ng-container *ngIf="validation?.errors && validation?.errors['country']">
          <small *ngFor="let error of validation?.errors['country']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['country']">
          <small *ngFor="let warning of validation?.warnings['country']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="url" translate>ui.app.user-form.user-form-component-html.url</label>
        <input
          (keydown)="resetErrorsAndWarnings('url')"
          [(ngModel)]="userInput.url"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['url'] }"
          id="url"
          name="url"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['url']">
          <small *ngFor="let error of validation?.errors['url']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['url']">
          <small *ngFor="let warning of validation?.warnings['url']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="vat_type" translate>ui.app.user-form.user-form-component-html.vat-number</label>
        <input
          (keydown)="resetErrorsAndWarnings('vat_type')"
          [(ngModel)]="userInput.vat_type"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['vat_type'] }"
          id="vat_type"
          name="vat_type"
          pInputText
        />
        <ng-container *ngIf="validation?.errors && validation?.errors['vat_type']">
          <small *ngFor="let error of validation?.errors['vat_type']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['vat_type']">
          <small *ngFor="let warning of validation?.warnings['vat_type']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="extra_info" translate>ui.app.user-form.user-form-component-html.extra-info</label>
        <textarea
          (keydown)="resetErrorsAndWarnings('extra_info')"
          [(ngModel)]="userInput.extra_info"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['extra_info'] }"
          id="extra_info"
          name="extra_info"
          pInputTextarea
        ></textarea>
        <ng-container *ngIf="validation?.errors && validation?.errors['extra_info']">
          <small *ngFor="let error of validation?.errors['extra_info']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['extra_info']">
          <small *ngFor="let warning of validation?.warnings['extra_info']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div *ngIf="!forProfile" class="p-pt-3">
        <div *ngIf="userInput.training" class="p-pt-1">
          <i class="pi pi-info-circle p-pr-1"></i>
          <span translate>ui.app.user-form.user-form-component-html.requested-training</span>
        </div>
        <div *ngIf="userInput.contact" class="p-pt-1">
          <i class="pi pi-info-circle p-pr-1"></i>
          <span translate>ui.app.user-form.user-form-component-html.requested-contact</span>
        </div>
        <div *ngIf="userInput.newsletter" class="p-pt-1">
          <i class="pi pi-info-circle p-pr-1"></i>
          <span translate>ui.app.user-form.user-form-component-html.requested-newsletter</span>
        </div>
      </div>

      <div *ngIf="user?.last_activity_at" class="p-field">
        <label translate>ui.app.user-form.user-form-component-html.last-activity-at</label>
        <div>{{ user.last_activity_at | longDateLocale }}</div>
      </div>
    </div>

    <div *ngIf="!forProfile" class="p-col-6">
      <h5 translate>ui.app.user-form.user-form-component-html.access</h5>

      <!-- Only new users can be authorized upfront -->
      <div *ngIf="!userId" class="p-field-checkbox">
        <p-checkbox
          (ngModelChange)="resetErrorsAndWarnings('authorized')"
          [(ngModel)]="userInput.authorized"
          [binary]="true"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['authorized'] }"
          inputId="authorized"
          name="authorized"
        ></p-checkbox>
        <label class="form-check-label" for="authorized" translate>
          ui.app.user-form.user-form-component-html.authorized
        </label>
        <ng-container *ngIf="validation?.errors && validation?.errors['authorized']">
          <small *ngFor="let error of validation?.errors['authorized']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['authorized']">
          <small *ngFor="let warning of validation?.warnings['authorized']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-field">
        <label for="manager_user" translate>ui.app.user-form.user-form-component-html.manager</label>
        <p-autoComplete
          (completeMethod)="usersQuery$.next($event.query)"
          (ngModelChange)="managerUserSelectionChanged($event)"
          [(ngModel)]="managerUserSelection"
          [dropdown]="true"
          [field]="descriptionService.user"
          [forceSelection]="true"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['manager_user_id'] }"
          [suggestions]="users"
          appendTo="body"
          dataKey="id"
          inputId="manager_user"
          name="manager_user"
          panelStyleClass="w-100"
          type="search"
        >
          <!-- Single select autoComplete selectedItem template does not work https://github.com/primefaces/primeng/issues/2242 -->
          <ng-template let-user pTemplate="selectedItem">
            <div class="p-d-flex p-ai-center">
              <div class="p-mr-1">
                <p-avatar
                  *ngIf="user?.profile_picture_url"
                  [image]="user?.profile_picture_url"
                  class="float-left"
                  shape="circle"
                ></p-avatar>
              </div>
              <div class="p-pb-2 text-ellipsis">
                {{ user | description: 'user' }}
              </div>
            </div>
          </ng-template>
          <ng-template let-user pTemplate="item">
            <div class="p-d-flex p-ai-center">
              <div class="p-mr-1">
                <p-avatar
                  *ngIf="user?.profile_picture_url"
                  [image]="user?.profile_picture_url"
                  class="float-left"
                  shape="circle"
                ></p-avatar>
              </div>
              <div class="p-pb-2 text-ellipsis">
                {{ user | description: 'user' }}
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="footer">
            <div *ngIf="usersCount !== null" class="p-text-center text-ellipsis p-text-bold">
              {{ 'ui.app.user-form.user-form-component-html.count-results' | translate: { count: usersCount } }}
            </div>
          </ng-template>
        </p-autoComplete>
        <ng-container *ngIf="validation?.errors && validation?.errors['manager_user_id']">
          <small *ngFor="let error of validation?.errors['manager_user_id']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['manager_user_id']">
          <small *ngFor="let warning of validation?.warnings['manager_user_id']" class="c-warning">{{ warning }}</small>
        </ng-container>
      </div>

      <div class="p-grid">
        <div class="p-col-6 p-pb-0 p-field">
          <label for="code" translate>ui.app.user-form.user-form-component-html.code</label>
          <input
            (keydown)="resetErrorsAndWarnings('code')"
            [(ngModel)]="userInput.code"
            [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['code'] }"
            id="code"
            name="code"
            pInputText
          />
          <ng-container *ngIf="validation?.errors && validation?.errors['code']">
            <small *ngFor="let error of validation?.errors['code']" class="p-error">{{ error }}</small>
          </ng-container>
          <ng-container *ngIf="validation?.warnings && validation?.warnings['code']">
            <small *ngFor="let warning of validation?.warnings['code']" class="c-warning">{{ warning }}</small>
          </ng-container>
        </div>

        <div class="p-col-6 p-pb-0 p-field">
          <label for="employee_number" translate>ui.app.user-form.user-form-component-html.employee-number</label>
          <input
            (keydown)="resetErrorsAndWarnings('employee_number')"
            [(ngModel)]="userInput.employee_number"
            [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['employee_number'] }"
            id="employee_number"
            name="employee_number"
            pInputText
            type="search"
          />
          <ng-container *ngIf="validation?.errors && validation?.errors['employee_number']">
            <small *ngFor="let error of validation?.errors['employee_number']" class="p-error">{{ error }}</small>
          </ng-container>
          <ng-container *ngIf="validation?.warnings && validation?.warnings['employee_number']">
            <small *ngFor="let warning of validation?.warnings['employee_number']" class="c-warning">
              {{ warning }}
            </small>
          </ng-container>
        </div>
      </div>

      <div class="p-grid">
        <div class="p-col-6 p-pb-0 p-field">
          <label for="area_number" translate>ui.app.user-form.user-form-component-html.area-number</label>
          <input
            (keydown)="resetErrorsAndWarnings('area_number')"
            [(ngModel)]="userInput.area_number"
            [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['area_number'] }"
            id="area_number"
            name="area_number"
            pInputText
          />
          <ng-container *ngIf="validation?.errors && validation?.errors['area_number']">
            <small *ngFor="let error of validation?.errors['area_number']" class="p-error">{{ error }}</small>
          </ng-container>
          <ng-container *ngIf="validation?.warnings && validation?.warnings['area_number']">
            <small *ngFor="let warning of validation?.warnings['area_number']" class="c-warning">{{ warning }}</small>
          </ng-container>
        </div>

        <div class="p-col-6 p-pb-0 p-field">
          <label for="crm_sales_org" translate>ui.app.user-form.user-form-component-html.crm-salesorganization</label>
          <p-dropdown
            (ngModelChange)="resetErrorsAndWarnings('crm_sales_org')"
            [(ngModel)]="userInput.crm_sales_org"
            [filter]="true"
            [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['crm_sales_org'] }"
            [options]="availableCrmSalesOrgs"
            [resetFilterOnHide]="true"
            [showClear]="true"
            appendTo="body"
            inputId="crm_sales_org"
            name="crm_sales_org"
            placeholder="&nbsp;"
          ></p-dropdown>
          <ng-container *ngIf="validation?.errors && validation?.errors['crm_sales_org']">
            <small *ngFor="let error of validation?.errors['crm_sales_org']" class="p-error">{{ error }}</small>
          </ng-container>
          <ng-container *ngIf="validation?.warnings && validation?.warnings['crm_sales_org']">
            <small *ngFor="let warning of validation?.warnings['crm_sales_org']" class="c-warning">{{ warning }}</small>
          </ng-container>
        </div>
      </div>

      <div class="p-field">
        <label for="blocked_project_types" translate>
          ui.app.user-form.user-form-component-html.blocked-project-types
        </label>
        <p-multiSelect
          (ngModelChange)="resetErrorsAndWarnings('blocked_project_types')"
          [(ngModel)]="userInput.blocked_project_types"
          [filterFields]="['code', 'name']"
          [filter]="true"
          [maxSelectedLabels]="999"
          [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['blocked_project_types'] }"
          [optionLabel]="descriptionService.name"
          [options]="projectTypes"
          [resetFilterOnHide]="true"
          appendTo="body"
          display="chip"
          inputId="blocked_project_types"
          name="blocked_project_types"
          optionValue="code"
          placeholder="&nbsp;"
        ></p-multiSelect>

        <ng-container *ngIf="validation?.errors && validation?.errors['blocked_project_types']">
          <small *ngFor="let error of validation?.errors['blocked_project_types']" class="p-error">{{ error }}</small>
        </ng-container>
        <ng-container *ngIf="validation?.warnings && validation?.warnings['blocked_project_types']">
          <small *ngFor="let warning of validation?.warnings['blocked_project_types']" class="c-warning">
            {{ warning }}
          </small>
        </ng-container>
      </div>

      <!-- MBUs -->
      <div class="p-d-flex p-ai-center p-jc-between p-mb-1">
        <strong translate>ui.app.user-form.user-form-component-html.mbus</strong>

        <strong *ngIf="canAccessAllMbus">
          (
          <span translate>ui.app.user-form.user-form-component-html.user-has-access-to-all-mbus</span>
          )
        </strong>

        <div>
          <p-button
            (onClick)="openUserMbuModal()"
            [label]="'ui.app.user-form.user-form-component-html.add-mbu' | translate"
            icon="pi pi-plus"
            size="small"
          ></p-button>
        </div>
      </div>
      <p-table
        #mbusTable
        (contextMenuSelectionChange)="prepareRelationMenuItems('mbu', $event).then()"
        [contextMenu]="relationContextMenu"
        [resizableColumns]="true"
        [rowHover]="true"
        [value]="userInput?.user_mbus"
        columnResizeMode="expand"
        stateKeyColumnWidths="app.user-form.user-form-component-html.mbus"
        stateStorage="local"
        stickyTableHeader
        styleClass="p-datatable-striped p-datatable-gridlines p-datatable-sm"
      >
        <ng-template pTemplate="header">
          <tr>
            <th cResizableColumn class="p-text-left text-ellipsis" translate>
              ui.app.user-form.user-form-component-html.mbu
            </th>
            <th cResizableColumn class="p-text-left text-ellipsis" translate>
              ui.app.user-form.user-form-component-html.area-manager
            </th>
            <th cResizableColumn class="p-text-left text-ellipsis" translate>
              ui.app.user-form.user-form-component-html.customer-number
            </th>
            <th cResizableColumn class="p-text-left text-ellipsis" translate>
              ui.app.user-form.user-form-component-html.default
            </th>
            <th cResizableColumn class="p-text-right text-ellipsis" style="width: 3rem" translate>
              ui.app.user-form.user-form-component-html.actions
            </th>
          </tr>
        </ng-template>

        <ng-template let-index="rowIndex" let-userMbu pTemplate="body">
          <tr
            (dblclick)="dblClickRelation('mbu', userMbu)"
            [ngClass]="{
              'ng-invalid': validation?.errors && validation?.errors['user_mbus.' + index]
            }"
            [pContextMenuRow]="userMbu"
            class="cursor-pointer"
          >
            <td
              [ngClass]="{
                'ng-invalid': validation?.errors && validation?.errors['user_mbus.' + index + '.mbu_code']
              }"
              class="p-text-left"
            >
              <i
                *ngIf="validation?.errors && validation?.errors['user_mbus.' + index + '.mbu_code']"
                [cTooltip]="validation?.errors['user_mbus.' + index + '.mbu_code']?.join('\n')"
                class="pi pi-exclamation-triangle p-error p-mr-1"
              ></i>
              <i
                *ngIf="validation?.warnings && validation?.warnings['user_mbus.' + index + '.mbu_code']"
                [cTooltip]="validation?.warnings['user_mbus.' + index + '.mbu_code']?.join('\n')"
                class="pi pi-info-circle c-warning p-mr-1"
              ></i>
              <span class="text-ellipsis">{{ userMbu?.mbu?.name }}</span>
            </td>
            <td
              [ngClass]="{
                'ng-invalid': validation?.errors && validation?.errors['user_mbus.' + index + '.area_manager_user_id']
              }"
              class="p-text-left"
            >
              <div class="p-d-flex p-ai-center">
                <div class="p-mr-1">
                  <i
                    *ngIf="validation?.errors && validation?.errors['user_mbus.' + index + '.area_manager_user_id']"
                    [cTooltip]="validation?.errors['user_mbus.' + index + '.area_manager_user_id']?.join('\n')"
                    class="pi pi-exclamation-triangle p-error p-mr-1"
                  ></i>
                  <i
                    *ngIf="validation?.warnings && validation?.warnings['user_mbus.' + index + '.area_manager_user_id']"
                    [cTooltip]="validation?.warnings['user_mbus.' + index + '.area_manager_user_id']?.join('\n')"
                    class="pi pi-info-circle c-warning p-mr-1"
                  ></i>
                </div>
                <div class="p-mr-1">
                  <p-avatar
                    *ngIf="userMbu?.area_manager_user"
                    [image]="userMbu?.area_manager_user?.profile_picture_url"
                    shape="circle"
                  ></p-avatar>
                </div>
                <div class="p-pb-2 text-ellipsis">
                  {{ userMbu?.area_manager_user | description: 'areaManagerUser' }}
                </div>
              </div>
            </td>
            <td class="p-text-left text-ellipsis">{{ userMbu?.customer_number }}</td>
            <td
              [ngClass]="{
                'ng-invalid': validation?.errors && validation?.errors['user_mbus.' + index + '.is_default']
              }"
            >
              <div class="form-check text-center">
                <i
                  *ngIf="validation?.errors && validation?.errors['user_mbus.' + index + '.is_default']"
                  [cTooltip]="validation?.errors['user_mbus.' + index + '.is_default']?.join('\n')"
                  class="pi pi-exclamation-triangle p-error p-mr-1"
                ></i>
                <i
                  *ngIf="validation?.warnings && validation?.warnings['user_mbus.' + index + '.is_default']"
                  [cTooltip]="validation?.warnings['user_mbus.' + index + '.is_default']?.join('\n')"
                  class="pi pi-info-circle c-warning p-mr-1"
                ></i>
                <!-- Using <label> to make the entire area clickable. Using &nbsp; for the layout. -->
                <label class="w-100">
                  &nbsp;
                  <p-radioButton
                    (ngModelChange)="defaultUserMbuSelectionChanged($event)"
                    [(ngModel)]="defaultUserMbuSelectionCode"
                    [value]="userMbu?.mbu_code"
                    class="form-check-input"
                    name="user_mbus_default"
                    type="radio"
                  ></p-radioButton>
                </label>
              </div>
            </td>
            <td class="p-text-right text-ellipsis">
              <i
                (click)="prepareRelationMenuItems('mbu', userMbu).then(); relationMenu.toggle($event)"
                class="pi pi-ellipsis-v w-100 cursor-pointer"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <!-- Roles -->
      <div class="p-d-flex p-ai-center p-jc-between p-mb-1 p-mt-3">
        <strong translate>ui.app.user-form.user-form-component-html.roles</strong>

        <div>
          <p-button
            (onClick)="openUserRoleModal()"
            [label]="'ui.app.user-form.user-form-component-html.add-role' | translate"
            icon="pi pi-plus"
            size="small"
          ></p-button>
        </div>
      </div>
      <p-table
        #rolesTable
        (contextMenuSelectionChange)="prepareRelationMenuItems('role', $event).then()"
        [contextMenu]="relationContextMenu"
        [resizableColumns]="true"
        [rowHover]="true"
        [value]="userInput?.user_roles"
        columnResizeMode="expand"
        stateKeyColumnWidths="app.user-form.user-form-component-html.roles"
        stateStorage="local"
        stickyTableHeader
        styleClass="p-datatable-striped p-datatable-gridlines p-datatable-sm"
      >
        <ng-template pTemplate="header">
          <tr>
            <th cResizableColumn class="p-text-left text-ellipsis" translate>
              ui.app.user-form.user-form-component-html.role
            </th>
            <!-- No longer assigning roles and groups per MBU -->
            <!--<th class="p-text-left text-ellipsis" cResizableColumn translate>ui.app.user-form.user-form-component-html.mbu</th>-->
            <th cResizableColumn class="p-text-right text-ellipsis" style="width: 3rem" translate>
              ui.app.user-form.user-form-component-html.actions
            </th>
          </tr>
        </ng-template>

        <ng-template let-index="rowIndex" let-userRole pTemplate="body">
          <tr
            (dblclick)="dblClickRelation('role', userRole)"
            [ngClass]="{
              'ng-invalid': validation?.errors && validation?.errors['user_roles.' + index]
            }"
            [pContextMenuRow]="userRole"
            class="cursor-pointer"
          >
            <td
              [ngClass]="{
                'ng-invalid': validation?.errors && validation?.errors['user_roles.' + index + '.role_id']
              }"
              class="p-text-left"
            >
              <i
                *ngIf="validation?.errors && validation?.errors['user_roles.' + index + '.role_id']"
                [cTooltip]="validation?.errors['user_roles.' + index + '.role_id']?.join('\n')"
                class="pi pi-exclamation-triangle p-error p-mr-1"
              ></i>
              <i
                *ngIf="validation?.warnings && validation?.warnings['user_roles.' + index + '.role_id']"
                [cTooltip]="validation?.warnings['user_roles.' + index + '.role_id']?.join('\n')"
                class="pi pi-info-circle c-warning p-mr-1"
              ></i>
              <span class="text-ellipsis">{{ userRole?.role?.name }}</span>
            </td>
            <!-- No longer assigning roles and groups per MBU -->
            <!--
            <td
              [ngClass]="{
                'ng-invalid': validation?.errors && validation?.errors['user_roles.' + index + '.mbu_code']
              }"
              class="p-text-left"
            >
              <i
                  *ngIf="validation?.errors && validation?.errors['user_roles.' + index + '.mbu_code']"
                  [cTooltip]="validation?.errors['user_roles.' + index + '.mbu_code']?.join('\n')"
                  class="pi pi-exclamation-triangle p-error p-mr-1"
              ></i>
              <i
                  *ngIf="validation?.warnings && validation?.warnings['user_roles.' + index + '.mbu_code']"
                  [cTooltip]="validation?.warnings['user_roles.' + index + '.mbu_code']?.join('\n')"
                  class="pi pi-info-circle c-warning p-mr-1"
              ></i>
              <span class="text-ellipsis">{{ userRole?.mbu?.name }}</span>
            </td>
            -->
            <td class="p-text-right text-ellipsis">
              <i
                (click)="prepareRelationMenuItems('role', userRole).then(); relationMenu.toggle($event)"
                class="pi pi-ellipsis-v w-100 cursor-pointer"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <!-- Groups -->
      <div class="p-d-flex p-ai-center p-jc-between p-mb-1 p-mt-3">
        <strong translate>ui.app.user-form.user-form-component-html.groups</strong>

        <div>
          <p-button
            (onClick)="openUserUserGroupModal()"
            [label]="'ui.app.user-form.user-form-component-html.add-group' | translate"
            icon="pi pi-plus"
            size="small"
          ></p-button>
        </div>
      </div>
      <p-table
        #userGroupsTable
        (contextMenuSelectionChange)="prepareRelationMenuItems('user_group', $event).then()"
        [contextMenu]="relationContextMenu"
        [resizableColumns]="true"
        [rowHover]="true"
        [value]="userInput?.user_user_groups"
        columnResizeMode="expand"
        stateKeyColumnWidths="app.user-form.user-form-component-html.user-groups"
        stateStorage="local"
        stickyTableHeader
        styleClass="p-datatable-striped p-datatable-gridlines p-datatable-sm"
      >
        <ng-template pTemplate="header">
          <tr>
            <th cResizableColumn class="p-text-left text-ellipsis" translate>
              ui.app.user-form.user-form-component-html.group
            </th>
            <!-- No longer assigning roles and groups per MBU -->
            <!--<th class="p-text-left text-ellipsis" cResizableColumn translate>ui.app.user-form.user-form-component-html.mbu</th>-->
            <th cResizableColumn class="p-text-right text-ellipsis" style="width: 3rem" translate>
              ui.app.user-form.user-form-component-html.actions
            </th>
          </tr>
        </ng-template>

        <ng-template let-index="rowIndex" let-userUserGroup pTemplate="body">
          <tr
            (dblclick)="dblClickRelation('user_group', userUserGroup)"
            [ngClass]="{
              'ng-invalid': validation?.errors && validation?.errors['user_user_groups.' + index]
            }"
            [pContextMenuRow]="userUserGroup"
            class="cursor-pointer"
          >
            <td
              [ngClass]="{
                'ng-invalid': validation?.errors && validation?.errors['user_user_groups.' + index + '.user_group_id']
              }"
              class="p-text-left"
            >
              <i
                *ngIf="validation?.errors && validation?.errors['user_user_groups.' + index + '.user_group_id']"
                [cTooltip]="validation?.errors['user_user_groups.' + index + '.user_group_id']?.join('\n')"
                class="pi pi-exclamation-triangle p-error p-mr-1"
              ></i>
              <i
                *ngIf="validation?.warnings && validation?.warnings['user_user_groups.' + index + '.user_group_id']"
                [cTooltip]="validation?.warnings['user_user_groups.' + index + '.user_group_id']?.join('\n')"
                class="pi pi-info-circle c-warning p-mr-1"
              ></i>
              <span class="text-ellipsis">{{ userUserGroup?.user_group?.name }}</span>
            </td>
            <!-- No longer assigning roles and groups per MBU -->
            <!--
            <td
              [ngClass]="{
                'ng-invalid': validation?.errors && validation?.errors['user_user_groups.' + index + '.mbu_code']
              }"
              class="p-text-left"
            >
              <i
                  *ngIf="validation?.errors && validation?.errors['user_user_groups.' + index + '.mbu_code']"
                  [cTooltip]="validation?.errors['user_user_groups.' + index + '.mbu_code']?.join('\n')"
                  class="pi pi-exclamation-triangle p-error p-mr-1"
              ></i>
              <i
                  *ngIf="validation?.warnings && validation?.warnings['user_user_groups.' + index + '.mbu_code']"
                  [cTooltip]="validation?.warnings['user_user_groups.' + index + '.mbu_code']?.join('\n')"
                  class="pi pi-info-circle c-warning p-mr-1"
              ></i>
              <span class="text-ellipsis">{{ userUserGroup?.mbu?.name }}</span>
            </td>
            -->
            <td class="p-text-right text-ellipsis">
              <i
                (click)="prepareRelationMenuItems('user_group', userUserGroup).then(); relationMenu.toggle($event)"
                class="pi pi-ellipsis-v w-100 cursor-pointer"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <p-menu #relationMenu [model]="relationMenuItems" [popup]="true" appendTo="body"></p-menu>

      <p-contextMenu #relationContextMenu [model]="relationMenuItems" appendTo="body"></p-contextMenu>
    </div>
  </div>

  <div class="p-d-flex justify-content-between p-pt-3">
    <div>
      <div *ngIf="validation?.message" class="p-error p-m-1">{{ validation?.message }}</div>

      <div>
        <p-button
          (onClick)="cancel().then()"
          [label]="'ui.app.user-form.user-form-component-html.cancel' | translate"
          icon="pi pi-times"
          styleClass="p-button-secondary"
        ></p-button>
        <p-button
          (onClick)="save()"
          [disabled]="isSaving"
          [label]="'ui.app.user-form.user-form-component-html.save' | translate"
          [loading]="isSaving"
          icon="pi pi-check"
        ></p-button>
      </div>
    </div>

    <div *ngIf="!isLoading && forProfile">
      <p-button
        (onClick)="forget().then()"
        [disabled]="isSaving"
        [label]="'ui.app.user-form.user-form-component-html.forget' | translate"
        icon="pi pi-trash"
        styleClass="p-button-danger"
      ></p-button>
    </div>
  </div>
</form>

<!-- This modal is used by all the relations by showing/hiding the inputs -->
<p-dialog
  (keyup.enter)="saveUserRelation()"
  (onHide)="cancelUserRelation()"
  [(visible)]="showRelationsModal"
  [closeOnEscape]="true"
  [dismissableMask]="false"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="header">
    <h4 class="text-ellipsis">
      <ng-container *ngIf="currentUserMbu">
        <span *ngIf="!currentUserMbu?.mbu_code" translate>
          ui.app.user-form.user-form-component-html.connect-to-mbu
        </span>
        <span *ngIf="currentUserMbu?.mbu_code" translate>
          ui.app.user-form.user-form-component-html.change-mbu-connection
        </span>
      </ng-container>
      <ng-container *ngIf="currentUserUserGroup">
        <span *ngIf="!currentUserUserGroup?.user_group_id" translate>
          ui.app.user-form.user-form-component-html.connect-to-group
        </span>
        <span *ngIf="currentUserUserGroup?.user_group_id" translate>
          ui.app.user-form.user-form-component-html.change-group-connection
        </span>
      </ng-container>
      <ng-container *ngIf="currentUserRole">
        <span *ngIf="!currentUserRole?.role_id" translate>ui.app.user-form.user-form-component-html.connect-role</span>
        <span *ngIf="currentUserRole?.role_id" translate>
          ui.app.user-form.user-form-component-html.edit-role-connection
        </span>
      </ng-container>
    </h4>
  </ng-template>

  <ng-template pTemplate="content">
    <div *ngIf="currentUserUserGroup" class="p-field">
      <label for="user_group" translate>ui.app.user-form.user-form-component-html.group</label>
      <ng-container *ngIf="forProfile">*</ng-container>
      <p-dropdown
        (ngModelChange)="resetErrorsAndWarnings('user_group')"
        [(ngModel)]="userGroupSelection"
        [autofocus]="currentUserUserGroup"
        [filterFields]="['name']"
        [filter]="true"
        [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['user_group'] }"
        [optionLabel]="descriptionService.codeName"
        [options]="userGroups"
        [resetFilterOnHide]="true"
        [showClear]="true"
        appendTo="body"
        inputId="user_group"
        name="user_group"
        placeholder="&nbsp;"
      ></p-dropdown>
      <ng-container *ngIf="validation?.errors && validation?.errors['user_group']">
        <small *ngFor="let error of validation?.errors['user_group']" class="p-error">{{ error }}</small>
      </ng-container>
      <ng-container *ngIf="validation?.warnings && validation?.warnings['user_group']">
        <small *ngFor="let warning of validation?.warnings['user_group']" class="c-warning">{{ warning }}</small>
      </ng-container>
    </div>

    <div *ngIf="currentUserRole" class="p-field">
      <label for="role" translate>ui.app.user-form.user-form-component-html.role</label>
      <ng-container *ngIf="forProfile">*</ng-container>
      <p-dropdown
        (ngModelChange)="resetErrorsAndWarnings('role')"
        [(ngModel)]="roleSelection"
        [autofocus]="currentUserRole"
        [filterFields]="['name']"
        [filter]="true"
        [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['role'] }"
        [optionLabel]="descriptionService.name"
        [options]="roles"
        [resetFilterOnHide]="true"
        [showClear]="true"
        appendTo="body"
        inputId="role"
        name="role"
        placeholder="&nbsp;"
      ></p-dropdown>
      <ng-container *ngIf="validation?.errors && validation?.errors['role']">
        <small *ngFor="let error of validation?.errors['role']" class="p-error">{{ error }}</small>
      </ng-container>
      <ng-container *ngIf="validation?.warnings && validation?.warnings['role']">
        <small *ngFor="let warning of validation?.warnings['role']" class="c-warning">{{ warning }}</small>
      </ng-container>
    </div>

    <!-- No longer assigning roles and groups per MBU -->
    <div *ngIf="currentUserMbu" class="p-field">
      <label for="mbu" translate>ui.app.user-form.user-form-component-html.mbu</label>
      <ng-container *ngIf="currentUserMbu">*</ng-container>
      <p-dropdown
        (ngModelChange)="resetErrorsAndWarnings('mbu')"
        [(ngModel)]="mbuSelection"
        [autofocus]="currentUserMbu"
        [filterFields]="['code', 'name']"
        [filter]="true"
        [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['mbu'] }"
        [optionLabel]="descriptionService.codeName"
        [options]="mbus"
        [resetFilterOnHide]="true"
        [showClear]="true"
        appendTo="body"
        inputId="mbu"
        name="mbu"
        placeholder="&nbsp;"
      ></p-dropdown>
      <ng-container *ngIf="validation?.errors && validation?.errors['mbu']">
        <small *ngFor="let error of validation?.errors['mbu']" class="p-error">{{ error }}</small>
      </ng-container>
      <ng-container *ngIf="validation?.warnings && validation?.warnings['mbu']">
        <small *ngFor="let warning of validation?.warnings['mbu']" class="c-warning">{{ warning }}</small>
      </ng-container>
    </div>

    <div *ngIf="currentUserMbu" class="p-field">
      <label for="area_manager_user" translate>ui.app.user-form.user-form-component-html.area-manager</label>
      <p-dropdown
        (ngModelChange)="resetErrorsAndWarnings('area_manager_user')"
        [(ngModel)]="areaManagerUserSelection"
        [filterFields]="['area_number', 'inverted_name']"
        [filter]="true"
        [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['area_manager_user_id'] }"
        [optionLabel]="descriptionService.areaManagerUser"
        [options]="areaManagerSuggestions"
        [resetFilterOnHide]="true"
        [showClear]="true"
        appendTo="body"
        inputId="area_manager_user_id"
        name="area_manager_user_id"
        placeholder="&nbsp;"
      >
        <ng-template let-user pTemplate="selectedItem">
          <div class="p-d-flex p-ai-center">
            <div class="p-mr-1">
              <p-avatar
                *ngIf="user?.profile_picture_url"
                [image]="user?.profile_picture_url"
                class="float-left"
                shape="circle"
              ></p-avatar>
            </div>
            <div class="p-pb-2 text-ellipsis">
              {{ user | description: 'areaManagerUser' }}
            </div>
          </div>
        </ng-template>
        <ng-template let-user pTemplate="item">
          <div class="p-d-flex p-ai-center">
            <div class="p-mr-1">
              <p-avatar
                *ngIf="user?.profile_picture_url"
                [image]="user?.profile_picture_url"
                class="float-left"
                shape="circle"
              ></p-avatar>
            </div>
            <div class="p-pb-2 text-ellipsis">
              {{ user | description: 'areaManagerUser' }}
            </div>
          </div>
        </ng-template>
      </p-dropdown>
      <ng-container *ngIf="validation?.errors && validation?.errors['area_manager_user']">
        <small *ngFor="let error of validation?.errors['area_manager_user']" class="p-error">{{ error }}</small>
      </ng-container>
      <ng-container *ngIf="validation?.warnings && validation?.warnings['area_manager_user']">
        <small *ngFor="let warning of validation?.warnings['area_manager_user']" class="c-warning">{{ warning }}</small>
      </ng-container>
    </div>

    <div *ngIf="currentUserMbu" class="p-field">
      <label for="customer_number" translate>ui.app.user-form.user-form-component-html.customer-number</label>
      <input
        (keydown)="resetErrorsAndWarnings('customer_number')"
        [(ngModel)]="customerNumber"
        [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['customer_number'] }"
        id="customer_number"
        name="customer_number"
        pInputText
      />
      <ng-container *ngIf="validation?.errors && validation?.errors['customer_number']">
        <small *ngFor="let error of validation?.errors['customer_number']" class="p-error">{{ error }}</small>
      </ng-container>
      <ng-container *ngIf="validation?.warnings && validation?.warnings['customer_number']">
        <small *ngFor="let warning of validation?.warnings['customer_number']" class="c-warning">{{ warning }}</small>
      </ng-container>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button
      (onClick)="cancelUserRelation()"
      [label]="'ui.app.user-form.user-form-component-html.cancel' | translate"
      icon="pi pi-times"
      styleClass="p-button-secondary"
    ></p-button>
    <p-button
      (onClick)="saveUserRelation()"
      [label]="'ui.app.user-form.user-form-component-html.ok' | translate"
      icon="pi pi-check"
    ></p-button>
  </ng-template>
</p-dialog>

<app-loader *ngIf="isLoading || isSaving" [ngClass]="{ 'loader-overlay': isSaving }"></app-loader>
