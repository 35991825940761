/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjOutletMediaTag = 'OUTLET_TYPE_GRILL' | 'OUTLET_TYPE_VALVE' | 'INSTALLATION_TYPE_W' | 'INSTALLATION_TYPE_F' | 'INSTALLATION_TYPE_C' | 'INSTALLATION_TYPE_T' | 'INSTALLATION_TYPE_A';

export const PrjOutletMediaTag = {
    OutletTypeGrill: 'OUTLET_TYPE_GRILL' as PrjOutletMediaTag,
    OutletTypeValve: 'OUTLET_TYPE_VALVE' as PrjOutletMediaTag,
    InstallationTypeW: 'INSTALLATION_TYPE_W' as PrjOutletMediaTag,
    InstallationTypeF: 'INSTALLATION_TYPE_F' as PrjOutletMediaTag,
    InstallationTypeC: 'INSTALLATION_TYPE_C' as PrjOutletMediaTag,
    InstallationTypeT: 'INSTALLATION_TYPE_T' as PrjOutletMediaTag,
    InstallationTypeA: 'INSTALLATION_TYPE_A' as PrjOutletMediaTag
};

