import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared/shared.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ConfiguratorComponent } from './configurator.component';
import { BlankModuleTopComponent } from './blank-module-top/blank-module-top.component';
import { BlankModuleSideComponent } from './blank-module-side/blank-module-side.component';
import { FrontModuleTopComponent } from './front-module-top/front-module-top.component';
import { FrontModuleSideComponent } from './front-module-side/front-module-side.component';
import { EndModuleTopComponent } from './end-module-top/end-module-top.component';
import { EndModuleSideComponent } from './end-module-side/end-module-side.component';
import { Size2ModuleTopComponent } from './size-2-module-top/size-2-module-top.component';
import { Size2ModuleSideComponent } from './size-2-module-side/size-2-module-side.component';
import { Size3ModuleTopComponent } from './size-3-module-top/size-3-module-top.component';
import { Size3ModuleSideComponent } from './size-3-module-side/size-3-module-side.component';
import { ModuleSelectionComponent } from './module-selection/module-selection.component';
import { ModuleTileComponent } from './module-selection/module-tile/module-tile.component';

import { CInputNumberModule, CTooltipModule } from '@components';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    ButtonModule,
    DialogModule,
    SharedModule,
    DropdownModule,
    CInputNumberModule,
    InputTextModule,
    RadioButtonModule,
    CTooltipModule,
  ],
  declarations: [
    ConfiguratorComponent,
    BlankModuleTopComponent,
    BlankModuleSideComponent,
    FrontModuleSideComponent,
    FrontModuleTopComponent,
    EndModuleSideComponent,
    EndModuleTopComponent,
    Size2ModuleSideComponent,
    Size2ModuleTopComponent,
    Size3ModuleSideComponent,
    Size3ModuleTopComponent,
    ModuleSelectionComponent,
    ModuleTileComponent,
  ],
  exports: [ConfiguratorComponent],
})
export class ConfiguratorModule {}
