import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { ListComponent } from './list.component';

import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  // Module is main, see app.module.ts
  Shell.childRoutes([
    { path: '', redirectTo: '/projects', pathMatch: 'full' },
    {
      path: 'projects',
      component: ListComponent,
      data: { title: marker('ui.app.projects.list.list-routing-module-ts.projects') },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ListRoutingModule {}
