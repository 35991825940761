/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ErrorForbidden } from '../model/models';
import { ErrorNotFound } from '../model/models';
import { ErrorUnauthorized } from '../model/models';
import { ErrorValidationResponse } from '../model/models';
import { InlineResponse20078 } from '../model/models';
import { InlineResponse20088 } from '../model/models';
import { InlineResponse20089 } from '../model/models';
import { InlineResponse20090 } from '../model/models';
import { InlineResponse20091 } from '../model/models';
import { InlineResponse20092 } from '../model/models';
import { InlineResponse20093 } from '../model/models';
import { PrjDocumentEmailInput } from '../model/models';
import { PrjDocumentInput } from '../model/models';
import { PrjDocumentsList } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PrjDocumentsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Document create
     * Document create
     * @param projectId ID of project
     * @param prjDocumentInput Pass document details
     * @param state Only save state
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentCreate(projectId: number, prjDocumentInput: PrjDocumentInput, state?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20088>;
    public prjDocumentCreate(projectId: number, prjDocumentInput: PrjDocumentInput, state?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20088>>;
    public prjDocumentCreate(projectId: number, prjDocumentInput: PrjDocumentInput, state?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20088>>;
    public prjDocumentCreate(projectId: number, prjDocumentInput: PrjDocumentInput, state?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjDocumentCreate.');
        }
        if (prjDocumentInput === null || prjDocumentInput === undefined) {
            throw new Error('Required parameter prjDocumentInput was null or undefined when calling prjDocumentCreate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (state !== undefined && state !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>state, 'state');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20088>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/documents`,
            prjDocumentInput,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Document delete
     * Document delete
     * @param projectId ID of project
     * @param documentId ID of document
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentDelete(projectId: number, documentId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public prjDocumentDelete(projectId: number, documentId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public prjDocumentDelete(projectId: number, documentId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public prjDocumentDelete(projectId: number, documentId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjDocumentDelete.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling prjDocumentDelete.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/documents/${encodeURIComponent(String(documentId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Document details
     * Document details
     * @param projectId ID of project
     * @param documentId ID of document
     * @param includePrices Include prices
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param withTrashed With trashed
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentDetails(projectId: number, documentId: number, includePrices?: boolean, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20088>;
    public prjDocumentDetails(projectId: number, documentId: number, includePrices?: boolean, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20088>>;
    public prjDocumentDetails(projectId: number, documentId: number, includePrices?: boolean, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20088>>;
    public prjDocumentDetails(projectId: number, documentId: number, includePrices?: boolean, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjDocumentDetails.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling prjDocumentDetails.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (includePrices !== undefined && includePrices !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includePrices, 'include_prices');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (withTrashed !== undefined && withTrashed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>withTrashed, 'with_trashed');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20088>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/documents/${encodeURIComponent(String(documentId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Discount presets
     * Discount presets
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentDiscountPresetsList(xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20090>;
    public prjDocumentDiscountPresetsList(xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20090>>;
    public prjDocumentDiscountPresetsList(xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20090>>;
    public prjDocumentDiscountPresetsList(xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20090>(`${this.configuration.basePath}/api/prj/discount_presets`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Pdf additional PDFs list
     * Pdf additional PDFs list
     * @param country 
     * @param locale 
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentPdfAdditionalPdfsList(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20092>;
    public prjDocumentPdfAdditionalPdfsList(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20092>>;
    public prjDocumentPdfAdditionalPdfsList(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20092>>;
    public prjDocumentPdfAdditionalPdfsList(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (country !== undefined && country !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>country, 'country');
        }
        if (locale !== undefined && locale !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>locale, 'locale');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20092>(`${this.configuration.basePath}/api/prj/documents/pdf_additional_pdfs`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Pdf additional texts list
     * Pdf additional texts list
     * @param country 
     * @param locale 
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentPdfAdditionalTextsList(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20091>;
    public prjDocumentPdfAdditionalTextsList(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20091>>;
    public prjDocumentPdfAdditionalTextsList(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20091>>;
    public prjDocumentPdfAdditionalTextsList(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (country !== undefined && country !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>country, 'country');
        }
        if (locale !== undefined && locale !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>locale, 'locale');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20091>(`${this.configuration.basePath}/api/prj/documents/pdf_additional_texts`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Pdf email template details
     * Pdf email template details
     * @param country 
     * @param locale 
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentPdfEmailTemplateDetails(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20078>;
    public prjDocumentPdfEmailTemplateDetails(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20078>>;
    public prjDocumentPdfEmailTemplateDetails(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20078>>;
    public prjDocumentPdfEmailTemplateDetails(country?: string, locale?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (country !== undefined && country !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>country, 'country');
        }
        if (locale !== undefined && locale !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>locale, 'locale');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20078>(`${this.configuration.basePath}/api/prj/documents/pdf_email_template`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Pdf sections list
     * Pdf sections list
     * @param projectId ID of project
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentPdfSectionsList(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20093>;
    public prjDocumentPdfSectionsList(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20093>>;
    public prjDocumentPdfSectionsList(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20093>>;
    public prjDocumentPdfSectionsList(projectId: number, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjDocumentPdfSectionsList.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20093>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/pdf_sections`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Document restore
     * Document restore
     * @param projectId ID of project
     * @param documentId ID of document
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentRestore(projectId: number, documentId: number, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20088>;
    public prjDocumentRestore(projectId: number, documentId: number, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20088>>;
    public prjDocumentRestore(projectId: number, documentId: number, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20088>>;
    public prjDocumentRestore(projectId: number, documentId: number, fields?: string, relations?: string, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjDocumentRestore.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling prjDocumentRestore.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<InlineResponse20088>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/documents/${encodeURIComponent(String(documentId))}/restore`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Project documents email
     * Project documents email
     * @param projectId ID of project
     * @param prjDocumentEmailInput Pass documents email details
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentsEmail(projectId: number, prjDocumentEmailInput: PrjDocumentEmailInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public prjDocumentsEmail(projectId: number, prjDocumentEmailInput: PrjDocumentEmailInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public prjDocumentsEmail(projectId: number, prjDocumentEmailInput: PrjDocumentEmailInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public prjDocumentsEmail(projectId: number, prjDocumentEmailInput: PrjDocumentEmailInput, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjDocumentsEmail.');
        }
        if (prjDocumentEmailInput === null || prjDocumentEmailInput === undefined) {
            throw new Error('Required parameter prjDocumentEmailInput was null or undefined when calling prjDocumentsEmail.');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/email`,
            prjDocumentEmailInput,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Documents list
     * Documents list
     * @param projectId ID of project
     * @param search Search string
     * @param filters Filters
     * @param page Page number; use 0 to skip page counting
     * @param perPage Rows per page; use 0 for no pagination
     * @param orderBy Order field
     * @param orderDir Order direction
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param withTrashed With trashed
     * @param excludeText Exclude text
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentsList(projectId: number, search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PrjDocumentsList>;
    public prjDocumentsList(projectId: number, search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PrjDocumentsList>>;
    public prjDocumentsList(projectId: number, search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PrjDocumentsList>>;
    public prjDocumentsList(projectId: number, search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjDocumentsList.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (search !== undefined && search !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>search, 'search');
        }
        if (filters !== undefined && filters !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filters, 'filters');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>perPage, 'per_page');
        }
        if (orderBy !== undefined && orderBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderBy, 'order_by');
        }
        if (orderDir !== undefined && orderDir !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderDir, 'order_dir');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (withTrashed !== undefined && withTrashed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>withTrashed, 'with_trashed');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PrjDocumentsList>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/documents`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Documents list all
     * Documents list all
     * @param search Search string
     * @param filters Filters
     * @param page Page number; use 0 to skip page counting
     * @param perPage Rows per page; use 0 for no pagination
     * @param orderBy Order field
     * @param orderDir Order direction
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param withTrashed With trashed
     * @param excludeText Exclude text
     * @param includePrices Include prices
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDocumentsListAll(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, includePrices?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PrjDocumentsList>;
    public prjDocumentsListAll(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, includePrices?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PrjDocumentsList>>;
    public prjDocumentsListAll(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, includePrices?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PrjDocumentsList>>;
    public prjDocumentsListAll(search?: string, filters?: string, page?: number, perPage?: number, orderBy?: string, orderDir?: string, fields?: string, relations?: string, withTrashed?: boolean, excludeText?: boolean, includePrices?: boolean, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (search !== undefined && search !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>search, 'search');
        }
        if (filters !== undefined && filters !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filters, 'filters');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (perPage !== undefined && perPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>perPage, 'per_page');
        }
        if (orderBy !== undefined && orderBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderBy, 'order_by');
        }
        if (orderDir !== undefined && orderDir !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderDir, 'order_dir');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }
        if (withTrashed !== undefined && withTrashed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>withTrashed, 'with_trashed');
        }
        if (excludeText !== undefined && excludeText !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>excludeText, 'exclude_text');
        }
        if (includePrices !== undefined && includePrices !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includePrices, 'include_prices');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PrjDocumentsList>(`${this.configuration.basePath}/api/prj/documents`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Base discounts
     * Base discounts
     * @param projectId ID of project
     * @param customerNumber 
     * @param customerGroup 
     * @param customerBranch 
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjProjectBaseDiscountsList(projectId: number, customerNumber?: string, customerGroup?: string, customerBranch?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20089>;
    public prjProjectBaseDiscountsList(projectId: number, customerNumber?: string, customerGroup?: string, customerBranch?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20089>>;
    public prjProjectBaseDiscountsList(projectId: number, customerNumber?: string, customerGroup?: string, customerBranch?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20089>>;
    public prjProjectBaseDiscountsList(projectId: number, customerNumber?: string, customerGroup?: string, customerBranch?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling prjProjectBaseDiscountsList.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (customerNumber !== undefined && customerNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerNumber, 'customer_number');
        }
        if (customerGroup !== undefined && customerGroup !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerGroup, 'customer_group');
        }
        if (customerBranch !== undefined && customerBranch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerBranch, 'customer_branch');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20089>(`${this.configuration.basePath}/api/prj/projects/${encodeURIComponent(String(projectId))}/base_discounts`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
