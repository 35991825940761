import {
  AfterViewInit,
  Component,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomHandler } from 'primeng/dom';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UnitConversionAirflow, UnitConversionTemperature, UnitConversionType } from '@api';
import { ConversionService, UnitConversionAirflowM3H, UnitConversionTemperatureCelsius } from './conversion.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { InputNumber } from 'primeng/inputnumber';

@UntilDestroy()
@Component({
  selector: 'c-inputNumber',
  providers: [
    DomHandler,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CInputNumberComponent),
      multi: true,
    },
  ],
  templateUrl: './c-input-number.component.html',
  styleUrls: ['./c-input-number.component.scss'],
})
export class CInputNumberComponent implements ControlValueAccessor, OnChanges, AfterViewInit {
  @Input() inputId: string;

  @Input() styleClass: string;

  @Input() style: any;

  @Input() name: string;

  @Input() ngClass: string | string[] | Set<string> | { [klass: string]: any };

  @Input() min: number;

  @Input() max: number;

  @Input() step: number;

  @Input() inputStyleClass: string;

  @Input() locale: string;

  @Input() minFractionDigits: number;

  @Input() maxFractionDigits: number;

  @Input() disabled: boolean;

  @Input() conversion:
    | UnitConversionAirflow
    | UnitConversionAirflowM3H
    | UnitConversionTemperature
    | UnitConversionTemperatureCelsius
    | UnitConversionType;

  value: number;
  minValue: number;
  maxValue: number;
  stepValue: number;

  @ViewChild('inputNumber') inputNumber: InputNumber;

  constructor(public conversionService: ConversionService) {}

  ngAfterViewInit() {
    // Ugly hack to prevent child component calling spin on keydown.arrowup and keydown.arrowdown and use the host handlers
    if (this.inputNumber && this.inputNumber.onInputKeyDown) {
      const onInputKeyDownOld = this.inputNumber.onInputKeyDown;
      this.inputNumber.onInputKeyDown = (event: KeyboardEvent) => {
        if (!event.shiftKey && !event.altKey && [38, 40].indexOf(event.which) !== -1) {
          return;
        }
        onInputKeyDownOld.call(this.inputNumber, event);
      };
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.minValue = this.min ? this.conversionService.convert(this.min, this.conversion) : this.min;
    this.maxValue = this.max ? this.conversionService.convert(this.max, this.conversion) : this.max;
    this.stepValue = this.step;
    if (!this.stepValue) {
      const decimals = this.computeDecimals();
      this.stepValue = Math.pow(10, -decimals);
    }
  }

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  writeValue(value: any): void {
    this.value = this.conversionService.convert(value, this.conversion);
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
  }

  onChildModelChange($event: number) {
    const revertedValue = this.conversionService.revert($event, this.conversion);

    // compensating for numeric precision on conversion
    const reconvertedValue = this.conversionService.convert(revertedValue, this.conversion);

    if (this.value !== reconvertedValue) {
      this.value = reconvertedValue; // the UI will render the converted value
      this.onModelChange(revertedValue); // the model will store the reverted value
      this.onModelTouched();
    }
  }

  computeDecimals() {
    let decimals = 0;
    if (this.conversion) {
      decimals = this.conversionService.decimals[this.conversion] || 0;
    }
    if (this.minFractionDigits && (!decimals || this.minFractionDigits > decimals)) {
      decimals = this.minFractionDigits;
    }
    if (this.maxFractionDigits && (!decimals || this.maxFractionDigits < decimals)) {
      decimals = this.maxFractionDigits;
    }
    return decimals;
  }

  spin(dir = 1) {
    let value = this.value || this.minValue || 0;
    const initialValue = value;

    const decimals = this.computeDecimals();
    let stepMultiplier = 1;
    do {
      value += dir * stepMultiplier * this.stepValue;

      // compensating for numeric precision
      const factor = Math.pow(10, decimals);
      value = Math.round(value * factor) / factor;

      // compensating for numeric precision on conversion
      const revertedValue = this.conversionService.revert(value, this.conversion);
      const reconvertedValue = this.conversionService.convert(revertedValue, this.conversion);
      value = reconvertedValue;
      if (value === initialValue) {
        // The conversion cancelled the increment, so we need to multiply it
        stepMultiplier += 1;
      } else {
        stepMultiplier = 0;
      }
    } while (stepMultiplier && stepMultiplier < 1000); // avoid possible infinite loop

    if ([null, undefined].indexOf(this.minValue) === -1 && value < this.minValue) {
      value = this.minValue;
    }

    if ([null, undefined].indexOf(this.maxValue) === -1 && value > this.maxValue) {
      value = this.maxValue;
    }

    // do not set this.value = value; it is handled inside onChildModelChange; setting it will not trigger onModelChange and onModelTouched
    this.onChildModelChange(value);
  }

  @HostListener('mousewheel', ['$event'])
  onScroll(event: WheelEvent) {
    if (!this.disabled && event && event.deltaY) {
      event.preventDefault();
      this.spin(event.deltaY > 0 ? -1 : 1); // event.deltaY > 0 means wheel down so decrement
    }
  }

  @HostListener('keydown.arrowup', ['$event'])
  onArrowUpKeyDown(event: KeyboardEvent) {
    if (!this.disabled) {
      event.preventDefault();
      this.spin();
    }
  }

  @HostListener('keydown.arrowdown', ['$event'])
  onArrowDownKeyDown(event: KeyboardEvent) {
    if (!this.disabled) {
      event.preventDefault();
      this.spin(-1);
    }
  }
}
