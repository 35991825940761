<div
  [cTooltip]="'ui.app.ccf.configurator.end-module-top.end-module-top-component-html.end-module-tooltip' | translate"
  class="module"
>
  <ng-container *ngIf="configuration?.end_module_type !== CcfPartEndModuleType.End">
    <div class="d-flex align-items-center position-relative">
      <div
        (click)="changeModule()"
        [ngClass]="{ 'cursor-pointer': !readonly }"
        class="open-main d-flex align-items-center justify-content-center"
        translate
      >
        ui.app.ccf.configurator.end-module-top.end-module-top-component-html.a
      </div>

      <div
        *ngIf="configuration?.end_module_type === CcfPartEndModuleType.D125"
        (click)="changeModule()"
        [ngClass]="{ 'cursor-pointer': !readonly }"
        class="open-inlet inlet-d125 text-center text-small text-truncate rotate"
      >
        <span translate>ui.app.ccf.configurator.end-module-top.end-module-top-component-html.125</span>
      </div>

      <div
        *ngIf="configuration?.end_module_type !== CcfPartEndModuleType.D125"
        (click)="changeAdapter()"
        [ngClass]="{
          'cursor-pointer': !readonly,
          'blank text-white blink': !ccf125 && !configuration?.end_module_adapter
        }"
        class="open-inlet text-center text-small text-truncate rotate"
      >
        <span *ngIf="!ccf125 && !configuration?.end_module_adapter" translate>
          ui.app.ccf.configurator.end-module-top.end-module-top-component-html.select
        </span>
      </div>

      <span *ngIf="dimensions.edgeModuleLength" class="measure-end">
        {{ dimensions.edgeModuleLength }}
      </span>
    </div>

    <div
      *ngIf="configuration?.end_module_type !== CcfPartEndModuleType.D125"
      class="d-flex flex-column position-relative"
    >
      <span
        (click)="changeAdapter()"
        *ngIf="configuration?.end_module_adapter && configuration?.end_module_adapter !== module?.size"
        [ngClass]="{
          'cursor-pointer': !readonly,
          'adapter-125': configuration?.end_module_adapter === CcfPartEndModuleAdapter.NUMBER_125
        }"
        class="adapter text-center text-small text-truncate rotate"
      >
        {{ configuration?.end_module_adapter }}
      </span>

      <span
        *ngIf="configuration?.end_module_adapter && configuration?.end_module_adapter !== module?.size"
        [ngClass]="
          configuration?.end_module_adapter === CcfPartEndModuleAdapter.NUMBER_125
            ? 'measure-end-adapter-125'
            : 'measure-end-adapter'
        "
      >
        {{ dimensions.adapterLength }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="configuration?.end_module_type === CcfPartEndModuleType.End">
    <div class="d-flex flex-column position-relative">
      <div (click)="changeModule()" [ngClass]="{ 'cursor-pointer': !readonly }" class="closed-main"></div>

      <span class="measure-end-3">
        {{ dimensions.endModuleLength }}
      </span>
    </div>
  </ng-container>

  <div class="end-top-module d-flex align-items-center">200</div>
</div>
