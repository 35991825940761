import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditorModule } from 'primeng/editor';

import Quill from 'quill';

import 'quill-paste-smart';

// Inline styles
[
  'attributors/style/align',
  'attributors/style/background',
  'attributors/style/color',
  'attributors/style/direction',
  'attributors/style/font',
  'attributors/style/size',
].forEach((style) => Quill.register(Quill.import(style), true));

@NgModule({
  imports: [CommonModule, EditorModule],
  exports: [EditorModule],
})
export class CEditorModule {}
