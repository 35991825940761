/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FilterOperator = 'eq' | 'neq' | 'lt' | 'lte' | 'gt' | 'gte' | 'contains' | 'starts_with' | 'ends_with' | 'like' | 'not_like' | 'rlike' | 'not_rlike' | 'regexp' | 'not_regexp' | 'in' | 'not_in' | 'null' | 'not_null' | 'has' | 'doesnt_have';

export const FilterOperator = {
    Eq: 'eq' as FilterOperator,
    Neq: 'neq' as FilterOperator,
    Lt: 'lt' as FilterOperator,
    Lte: 'lte' as FilterOperator,
    Gt: 'gt' as FilterOperator,
    Gte: 'gte' as FilterOperator,
    Contains: 'contains' as FilterOperator,
    StartsWith: 'starts_with' as FilterOperator,
    EndsWith: 'ends_with' as FilterOperator,
    Like: 'like' as FilterOperator,
    NotLike: 'not_like' as FilterOperator,
    Rlike: 'rlike' as FilterOperator,
    NotRlike: 'not_rlike' as FilterOperator,
    Regexp: 'regexp' as FilterOperator,
    NotRegexp: 'not_regexp' as FilterOperator,
    In: 'in' as FilterOperator,
    NotIn: 'not_in' as FilterOperator,
    Null: 'null' as FilterOperator,
    NotNull: 'not_null' as FilterOperator,
    Has: 'has' as FilterOperator,
    DoesntHave: 'doesnt_have' as FilterOperator
};

