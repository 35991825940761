import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appDndFile]',
})
export class DndFileDirective {
  @Input() appDndFile: boolean;
  @Output() file: EventEmitter<FileList> = new EventEmitter();
  @HostBinding('style.background') private background: string;

  constructor() {}

  public isEnabled() {
    // Considering empty string for basic use of the directive
    return this.appDndFile || String(this.appDndFile) === '';
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: DragEvent) {
    if (!this.isEnabled()) {
      return;
    }

    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#999';
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: DragEvent) {
    if (!this.isEnabled()) {
      return;
    }

    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt: DragEvent) {
    if (!this.isEnabled()) {
      return;
    }

    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';

    if (evt.dataTransfer.files.length) {
      this.file.emit(evt.dataTransfer.files);
    }
  }
}
