/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ArtDeviceRuleSide } from '../model/models';
import { ErrorForbidden } from '../model/models';
import { ErrorNotFound } from '../model/models';
import { ErrorUnauthorized } from '../model/models';
import { ErrorValidationResponse } from '../model/models';
import { InlineResponse200105 } from '../model/models';
import { InlineResponse20087 } from '../model/models';
import { PrjBuildingCalculationMethod } from '../model/models';
import { PrjBuildingUnitDistribution } from '../model/models';
import { PrjOutsideInstallationsDeviceInput } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PrjOutsideInstallationsService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Device details with outside installations
     * Device details with outside installations
     * @param id ID of device
     * @param buildingId ID of building
     * @param apartmentId ID of apartment
     * @param zone Zone
     * @param fields Request only a subset of the fields
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDeviceDetailsWithOutsideInstallations(id?: number, buildingId?: number, apartmentId?: number, zone?: number, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20087>;
    public prjDeviceDetailsWithOutsideInstallations(id?: number, buildingId?: number, apartmentId?: number, zone?: number, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20087>>;
    public prjDeviceDetailsWithOutsideInstallations(id?: number, buildingId?: number, apartmentId?: number, zone?: number, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20087>>;
    public prjDeviceDetailsWithOutsideInstallations(id?: number, buildingId?: number, apartmentId?: number, zone?: number, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (buildingId !== undefined && buildingId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>buildingId, 'building_id');
        }
        if (apartmentId !== undefined && apartmentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>apartmentId, 'apartment_id');
        }
        if (zone !== undefined && zone !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>zone, 'zone');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse20087>(`${this.configuration.basePath}/api/prj/outside_installations`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Device outside installations save
     * Outlet outside installations save
     * @param prjOutsideInstallationsDeviceInput Pass device details
     * @param forceReset Force reset
     * @param fields Request only a subset of the fields
     * @param relations Request relations
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjDeviceWithOutsideInstallationsSave(prjOutsideInstallationsDeviceInput: PrjOutsideInstallationsDeviceInput, forceReset?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse20087>;
    public prjDeviceWithOutsideInstallationsSave(prjOutsideInstallationsDeviceInput: PrjOutsideInstallationsDeviceInput, forceReset?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse20087>>;
    public prjDeviceWithOutsideInstallationsSave(prjOutsideInstallationsDeviceInput: PrjOutsideInstallationsDeviceInput, forceReset?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse20087>>;
    public prjDeviceWithOutsideInstallationsSave(prjOutsideInstallationsDeviceInput: PrjOutsideInstallationsDeviceInput, forceReset?: boolean, fields?: string, relations?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (prjOutsideInstallationsDeviceInput === null || prjOutsideInstallationsDeviceInput === undefined) {
            throw new Error('Required parameter prjOutsideInstallationsDeviceInput was null or undefined when calling prjDeviceWithOutsideInstallationsSave.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (forceReset !== undefined && forceReset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>forceReset, 'force_reset');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }
        if (relations !== undefined && relations !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>relations, 'relations');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<InlineResponse20087>(`${this.configuration.basePath}/api/prj/outside_installations`,
            prjOutsideInstallationsDeviceInput,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Outside installations basics
     * Outside installations basics
     * @param priceDate 
     * @param calculationMethod 
     * @param unitDistribution 
     * @param capacity 
     * @param deviceGroupCode 
     * @param deviceSide 
     * @param distributionCodes 
     * @param fields Request only a subset of the fields
     * @param xReferer Standard HTTP referer header to determine the MBU based on domain or code; fallback to Referer, Origin, Host
     * @param acceptLanguage Standard HTTP language header to determine the locale
     * @param accept Standard HTTP accept header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prjOutsideInstallationsBasics(priceDate?: string, calculationMethod?: PrjBuildingCalculationMethod, unitDistribution?: PrjBuildingUnitDistribution, capacity?: number, deviceGroupCode?: string, deviceSide?: ArtDeviceRuleSide, distributionCodes?: string | Array<string>, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse200105>;
    public prjOutsideInstallationsBasics(priceDate?: string, calculationMethod?: PrjBuildingCalculationMethod, unitDistribution?: PrjBuildingUnitDistribution, capacity?: number, deviceGroupCode?: string, deviceSide?: ArtDeviceRuleSide, distributionCodes?: string | Array<string>, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse200105>>;
    public prjOutsideInstallationsBasics(priceDate?: string, calculationMethod?: PrjBuildingCalculationMethod, unitDistribution?: PrjBuildingUnitDistribution, capacity?: number, deviceGroupCode?: string, deviceSide?: ArtDeviceRuleSide, distributionCodes?: string | Array<string>, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse200105>>;
    public prjOutsideInstallationsBasics(priceDate?: string, calculationMethod?: PrjBuildingCalculationMethod, unitDistribution?: PrjBuildingUnitDistribution, capacity?: number, deviceGroupCode?: string, deviceSide?: ArtDeviceRuleSide, distributionCodes?: string | Array<string>, fields?: string, xReferer?: string, acceptLanguage?: string, accept?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (priceDate !== undefined && priceDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>priceDate, 'price_date');
        }
        if (calculationMethod !== undefined && calculationMethod !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>calculationMethod, 'calculation_method');
        }
        if (unitDistribution !== undefined && unitDistribution !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>unitDistribution, 'unit_distribution');
        }
        if (capacity !== undefined && capacity !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>capacity, 'capacity');
        }
        if (deviceGroupCode !== undefined && deviceGroupCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deviceGroupCode, 'device_group_code');
        }
        if (deviceSide !== undefined && deviceSide !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deviceSide, 'device_side');
        }
        if (distributionCodes !== undefined && distributionCodes !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>distributionCodes, 'distribution_codes');
        }
        if (fields !== undefined && fields !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fields, 'fields');
        }

        let headers = this.defaultHeaders;
        if (xReferer !== undefined && xReferer !== null) {
            headers = headers.set('X-Referer', String(xReferer));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            headers = headers.set('Accept-Language', String(acceptLanguage));
        }
        if (accept !== undefined && accept !== null) {
            headers = headers.set('Accept', String(accept));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<InlineResponse200105>(`${this.configuration.basePath}/api/prj/outside_installations/basics`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
