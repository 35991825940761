import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from './navigation.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @Input() header: boolean;
  @Input() sidebar: boolean;

  constructor(public navigationService: NavigationService) {}

  ngOnInit() {
    this.navigationService.init();
  }
}
