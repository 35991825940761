/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NotificationEmailTemplateType = 'invite_user' | 'reset_password' | 'user_authorized' | 'user_rejected' | 'verify_email';

export const NotificationEmailTemplateType = {
    InviteUser: 'invite_user' as NotificationEmailTemplateType,
    ResetPassword: 'reset_password' as NotificationEmailTemplateType,
    UserAuthorized: 'user_authorized' as NotificationEmailTemplateType,
    UserRejected: 'user_rejected' as NotificationEmailTemplateType,
    VerifyEmail: 'verify_email' as NotificationEmailTemplateType
};

