import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormattingService, I18nService } from '@core';

@Pipe({
  name: 'longDateLocale',
})
export class LongDateLocalePipe extends DatePipe implements PipeTransform {
  constructor(
    public i18nService: I18nService,
    public formattingService: FormattingService,
  ) {
    super(i18nService.locale);
  }

  // @ts-ignore
  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string) {
    return super.transform(value, format || this.formattingService.longDateFormat, timezone, locale);
  }
}
