import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { CoreModule } from '@core';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthModule } from '@app/auth/auth.module';
import { UserFormModule } from '@app/user-form/user-form.module';

import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { NewsComponent } from './news/news.component';

import { CMenubarModule, CTableModule } from '@components';

import { MenubarModule } from 'primeng/menubar';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { AvatarModule } from 'primeng/avatar';
import { SidebarModule } from 'primeng/sidebar'; // https://stackoverflow.com/questions/67457676/primeng-menubar-ng-template-start-and-end-or-not-showing-even-after-doing-the-sa/67458447#67458447
import { BadgeModule } from 'primeng/badge';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ContextMenuModule } from 'primeng/contextmenu';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,

    AuthModule,
    SharedModule,

    UserFormModule,

    CMenubarModule,

    MenubarModule,
    TabViewModule,
    TieredMenuModule,
    AvatarModule,
    SidebarModule,
    BadgeModule,

    InputTextModule,
    CTableModule,
    DialogModule,
    ButtonModule,
    MenuModule,
    ContextMenuModule,
  ],
  declarations: [HeaderComponent, NewsComponent, ShellComponent],
})
export class ShellModule {}
