/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArtDeviceRuleConnectionType = 'SUP' | 'EXT' | 'ODA' | 'EHA';

export const ArtDeviceRuleConnectionType = {
    Sup: 'SUP' as ArtDeviceRuleConnectionType,
    Ext: 'EXT' as ArtDeviceRuleConnectionType,
    Oda: 'ODA' as ArtDeviceRuleConnectionType,
    Eha: 'EHA' as ArtDeviceRuleConnectionType
};

