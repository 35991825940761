/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArtAccessoryGroupLocation = 'individual' | 'centralized' | 'with_device' | 'without_device';

export const ArtAccessoryGroupLocation = {
    Individual: 'individual' as ArtAccessoryGroupLocation,
    Centralized: 'centralized' as ArtAccessoryGroupLocation,
    WithDevice: 'with_device' as ArtAccessoryGroupLocation,
    WithoutDevice: 'without_device' as ArtAccessoryGroupLocation
};

