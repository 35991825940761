import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RecordsList, RecordsService as BaseRecordsService, State as BaseState } from '@core';

import { PrjProject, PrjProjectsListCategory, PrjProjectsService as ApiPrjProjectsService } from '@api';

export interface State extends BaseState {
  category?: PrjProjectsListCategory;
  include_variants?: boolean;
}

@Injectable({ providedIn: 'root' })
export class RecordsService extends BaseRecordsService<PrjProject> {
  public categoryBase: PrjProjectsListCategory; // some users have a different default
  public excludeText: boolean;

  constructor(public apiPrjProjectsService: ApiPrjProjectsService) {
    super('app.projects.list.records-service-ts');
  }

  protected _category: PrjProjectsListCategory;
  protected _includeVariants: boolean;

  get category() {
    return this._category;
  }

  set category(category: PrjProjectsListCategory) {
    if ((this._category || null) !== (category || null)) {
      this._category = category;

      // Reset page when category changes
      if (this._state.page !== 1) {
        this._set({ page: 1 });
      } else {
        this._load$.next();
        this._state$.next(this._state);
      }
    }
  }

  get includeVariants() {
    return this._includeVariants;
  }

  set includeVariants(includeVariants: boolean) {
    if ((this._includeVariants || null) !== (includeVariants || null)) {
      this._includeVariants = includeVariants;

      // Reset page when includeVariants changes
      if (this._state.page !== 1) {
        this._set({ page: 1 });
      } else {
        this._load$.next();
        this._state$.next(this._state);
      }
    }
  }

  protected getBaseState(): State {
    return {
      ...super.getBaseState(),
      category: null,
      include_variants: null,
    };
  }

  protected _loadStateParams(changeState = true) {
    let stateChanged = super._loadStateParams(false); // do not change state yet
    if (stateChanged === null) {
      return null;
    }

    let queryParamValue: string;

    // Cannot use _paramValueNotEmpty because the empty string is acceptable for "all" the projects when this.categoryBase !== null
    if ([null, undefined].indexOf((queryParamValue = this._getParam('category'))) === -1) {
      // The empty string is acceptable for "all" the projects when this.categoryBase !== null
      if (queryParamValue === '') {
        queryParamValue = null;
      }
      if (this._category !== queryParamValue) {
        this._category = queryParamValue as PrjProjectsListCategory;
        stateChanged = true;
      }
    } else if ((this._category || null) !== (this.categoryBase || null)) {
      this._category = this.categoryBase;
      stateChanged = true;
    }

    if (this._paramValueNotEmpty(this._getParam('include_variants'))) {
      if (this._includeVariants !== true) {
        this._includeVariants = true;
        stateChanged = true;
      }
    } else if (this._includeVariants) {
      this._includeVariants = false;
      stateChanged = true;
    }

    // change the state now
    if (changeState && stateChanged) {
      this._load$.next();
      this._state$.next(this._state);
    }

    return stateChanged;
  }

  protected _getParamsFromState() {
    const queryParams: { [key: string]: string } = {};

    this._addParam(
      queryParams,
      'category',
      (this._category || null) !== (this.categoryBase || null)
        ? // The empty string is acceptable for "all" the projects when this.categoryBase !== null
          !this._category && this.categoryBase
          ? ''
          : this._category
        : null,
    );

    this._addParam(queryParams, 'include_variants', this._includeVariants ? 'true' : null);

    return {
      ...queryParams,
      ...super._getParamsFromState(),
    };
  }

  protected _search(): Observable<RecordsList<PrjProject>> {
    const { searchTerm, filters, pageSize, page, sortColumn, sortDirection, withTrashed, fields, relations } =
      this._state;

    return this.apiPrjProjectsService.prjProjectsList(
      searchTerm || null,
      filters,
      page || null,
      pageSize,
      sortColumn || null,
      sortDirection || null,
      fields,
      relations,
      withTrashed || null,
      this._category || null,
      this.excludeText || null,
      this._includeVariants || null,
    );
  }
}
