/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MbuMediaTag = 'APP_HEADER_LOGO' | 'APP_NAME_LOGO' | 'APP_LOGIN_BACKDROP';

export const MbuMediaTag = {
    HeaderLogo: 'APP_HEADER_LOGO' as MbuMediaTag,
    NameLogo: 'APP_NAME_LOGO' as MbuMediaTag,
    LoginBackdrop: 'APP_LOGIN_BACKDROP' as MbuMediaTag
};

